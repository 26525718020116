import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Optional } from '../../../../lib/types';
import { IStory } from '@storyverseco/svs-story-suite';

interface StoryState {
  data?: IStory;
  storyId: string;
  walletAddress: string;
}

const initialState: StoryState = {
  storyId: '',
  walletAddress: '',
};

// const resume: CaseReducer<StoryState> = (state) => {
//   state.loading = false;
// };

const setStory: CaseReducer<StoryState, PayloadAction<Optional<IStory>>> = (
  state,
  { payload }
) => {
  state.data = payload;
};

const setWalletAddress: CaseReducer<StoryState, PayloadAction<string>> = (
  state,
  { payload }
) => {
  state.walletAddress = payload;
};

const setStoryId: CaseReducer<StoryState, PayloadAction<string>> = (
  state,
  { payload }
) => {
  state.storyId = payload;
};

export const storySlice = createSlice({
  name: 'story',

  initialState,

  reducers: {
    setStory,
    setWalletAddress,
    setStoryId,
  },
});
