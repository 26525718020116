import { Route, Routes, useLocation } from 'react-router-dom'

// Pages
import { AdminPage } from '../pages/admin/AdminPage';
import { LoginPage } from '../pages/login/LoginPage';
import { TemplatesPage } from '../pages/templates/TemplatesPage';
import { ToolsPage } from '../pages/tools/ToolsPage';

import { Routes as AppRoutes } from './useNavigation';
import { SuperUserPage } from '../pages/superuser/SuperUserPage';
import { StoryPage } from '../pages/story/StoryPage';
import {AppLoader} from '../pages/AppLoader/AppLoader';
import { ReactNode } from 'react';
import { ViewStories } from '../pages/story/components/ViewStories';
import { CreateStoryNFT } from '../pages/story/components/CreateStoryNFT';
import { EditStoryNFTACL } from '../pages/story/components/EditStoryNFTACL';
import { UserPage } from '../pages/user/UserPage';
import { RevealSchedule } from '../pages/admin/components/RevealSchedule/RevealSchedule';
import { PromoteStory } from '../pages/admin/components/PromoteStory';
import { MintInput } from '../pages/admin/components/MintInput';
import { ValidateInputStory } from '../pages/tools/components/ValidateInputStory';
import { TwitterDMTool } from '../pages/tools/components/TwitterDMTool';
import { Users } from '../pages/superuser/components/Users';
import { Groups } from '../pages/superuser/components/Groups';
import { Permissions } from '../pages/superuser/components/Permissions';
import { FileEditor } from '../pages/superuser/components/FileEditor';
import { Postman } from '../pages/sudo/postman/PostmanPage';
import { SaleDataPage } from '../pages/config/SaleDataEditor';
import { MainSitePage } from '../pages/media/MainSitePage';
import { RafflePageEditor } from '../pages/config/RafflePageEditor';
import { RafflePageList } from '../pages/config/RafflePageList';
import { StoryWriters } from '../pages/story/components/StoryWriters';
import { ViewPublishedStories } from '../pages/story/components/ViewPublishedStories';
import { LoadingConfigEditor } from '../pages/config/LoadingEditor';
import { GetDefaultStoryNFTACL } from '../pages/config/DefaultStoryACL';
import { AttributeMapping } from '../pages/story/components/AttributeMapping';

const withLoader = (component: ReactNode): ReactNode => <AppLoader>{component}</AppLoader>;

export const AppNavigationRoutes = () => { 
  return (
    <Routes>       
      <Route path={AppRoutes.HOME} element={withLoader(<LoginPage />)}/>
      <Route path={AppRoutes.LOGIN} element={withLoader(<LoginPage />)}/>      
      <Route path={AppRoutes.STORY_USER} element={withLoader(<UserPage />)}/>      

      {/* Collectible Stories */}
      <Route path={AppRoutes.STORY} element={withLoader(<ViewStories />)}/>
      <Route path={AppRoutes.STORY_PUBLISHED} element={withLoader(<ViewPublishedStories />)}/>
      <Route path={AppRoutes.STORY_CREATE} element={withLoader(<CreateStoryNFT />)}/>
      <Route path={AppRoutes.STORY_ACL} element={withLoader(<EditStoryNFTACL />)}/>
      <Route path={AppRoutes.STORY_REVEAL_SCHEDULE} element={withLoader(<RevealSchedule />)}/>
      <Route path={AppRoutes.STORY_PROMOTE} element={withLoader(<PromoteStory />)}/>
      <Route path={AppRoutes.STORY_WRITERS} element={withLoader(<StoryWriters />)}/>
      <Route path={AppRoutes.STORY_ATTRIBUTE_MAPPING} element={withLoader(<AttributeMapping />)}/>
      
      {/* ------------------- */}
      {/* Character Pass */}
      <Route path={AppRoutes.CP_MINT} element={withLoader(<MintInput />)}/>
      {/* ------------------- */}
      {/* Tools */}
      <Route path={AppRoutes.TOOL_VALIDATE_STORY} element={withLoader(<ValidateInputStory />)}/>
      {/* ------------------- */}
      {/* Twitter */}
      <Route path={AppRoutes.TWIITER_DM} element={withLoader(<TwitterDMTool />)}/>
      {/* ------------------- */}
      {/* Cognito */}
      <Route path={AppRoutes.COGNITO_USERS} element={withLoader(<Users />)}/>
      <Route path={AppRoutes.COGNITO_GROUPS} element={withLoader(<Groups />)}/>
      <Route path={AppRoutes.COGNITO_PERMISSIONS} element={withLoader(<Permissions />)}/>
      {/* ------------------- */}
      {/* Engineering (sudo) */}
      <Route path={AppRoutes.SUDO_FILE_EDITOR} element={withLoader(<FileEditor />)}/>
      <Route path={AppRoutes.SUDO_POSTMAN} element={withLoader(<Postman />)}/>
      {/* ------------------- */}
      {/* Config */}
      <Route path={AppRoutes.CONFIG_SALE_DATA} element={withLoader(<SaleDataPage />)}/>
      <Route path={AppRoutes.CONFIG_RAFFLE_PAGE} element={withLoader(<RafflePageList />)}/>
      <Route path={`${AppRoutes.CONFIG_RAFFLE_PAGE_UPDATE}/:saleId?`} element={withLoader(<RafflePageEditor />)}/>
      <Route path={AppRoutes.CONFIG_LOADING} element={withLoader(<LoadingConfigEditor />)}/>
      <Route path={AppRoutes.CONFIG_DEFAULT_STORIES_ACL} element={withLoader(<GetDefaultStoryNFTACL />)}/>

      
      
      
      {/* ------------------- */}
      {/* Media */}
      <Route path={AppRoutes.MEDIA_MAINSITE} element={withLoader(<MainSitePage />)}/>
      {/* ------------------- */}

      {/* 404 (Must always be the last root, as it works in waterfall) */}
      {/* @TODO: Create 404 page */}
      <Route path="*" element={withLoader(<LoginPage />)} />
      {/* ------------------- */}
      
      
      
      
      <Route path={AppRoutes.ADMIN} element={withLoader(<AdminPage />)}/>
      <Route path={AppRoutes.TOOLS} element={withLoader(<ToolsPage />)}/>
      <Route path={AppRoutes.ROOT} element={withLoader(<SuperUserPage />)}/>
      {/* <Route path={AppRoutes.TEMPLATES} element={withLoader(<TemplatesPage />}/> )*/}


      
    </Routes>
  );
}