import { ChangeEvent, useCallback, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../lib/styles';
import { FilterFunction } from '../lib/types';
import { Col, Row, Wrapper } from './Layout';


const Container = styled(Col)<{
  fullWidth?: boolean;
}>`
  max-width: ${({fullWidth}) => fullWidth ? 100 : 50}%;
  width: 100%;
  min-height: 100%;
  border-radius: 8px;
  border: 1px solid ${colors.grey};
  position: relative;
`;

const ItemsContainer = styled(Col)`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const Header = styled(Row)`
  border-bottom: 1px solid ${colors.grey};
  max-height: 40px;
  padding: 8px;
  font-weight: bold;
`;

const Item = styled(Wrapper)<{
  disabled?: boolean;
}>`
  cursor: pointer;
  flex-direction: row;
  padding: 8px;
  &:hover {
    background: ${colors.lightGrey};
  }
  border-bottom: 1px solid ${colors.grey};
  ${
    ({disabled}) => disabled && `
      background: ${colors.grey};
      &:hover {
        background: ${colors.grey};
      }
    `
  }
`;

const SearchInput = styled.input`
  border-radius: 4px;
  border: 1px solid ${colors.grey};
  margin-left: 4px;
  font-size: 15px;
  &:focus,
  &:focus-visible {
    outline: none;
    border: 1px solid ${colors.black};
  }
  &:hover {
    background: ${colors.lighterGrey};
  }
  width: 100%;
`;

const filterById = (item: PickerItem, filter: string) => item.id.toLowerCase().includes(filter.toLowerCase());

export interface PickerItem {
  id: string;
  value: React.ReactNode;
}

interface Props {
  title: string;
  items: PickerItem[];
  onItemClick: (id: string) => void;
  disabled?: boolean;
  filterBy?: FilterFunction;
  fullWidth?: boolean;
}

export const ListPicker = ({
  title,
  items,
  onItemClick,
  disabled,
  filterBy = filterById,
  fullWidth,
}: Props) => {

  const [search, setSearch] = useState('');

  const onClick = (id: string) => () => {
    onItemClick(id);
  }

  const onSearchChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    setSearch(evt.target.value);
  }, []);

  const filteredItems = search === '' ? items : items.filter((item) => filterBy(item, search));

  return (
    <Container fullWidth>
      <Header>
        <Row>
          {title} 
        </Row>
        <Row flex={.6}>
          Filter
          <SearchInput
            value={search}
            onChange={onSearchChange}
          />
        </Row>
      </Header>
      <ItemsContainer>
        {
          filteredItems.map((item, i) => (
            <Item 
              key={i} 
              onClick={onClick(item.id)}
              disabled={disabled}
            >
              {item.value}
            </Item>
          ))
        }
      </ItemsContainer>
    </Container>
  );
}