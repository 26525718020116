import { useSelector, useDispatch } from 'react-redux';
import { appSlice } from '../../../../state/appSlice';
import { getCharacterItems, getUserAssets, } from '../../../../state/selectors';
import { PickerData, PickerItem } from '../../../../../lib/types';
import { AssemblerInput } from './AssemblerInput';
import { Col, VGap, } from '../../../../../components/Layout';
import { Picker } from '../../../../../components/Picker';
import { Title } from '../../../../../components/Text';

const filterByValue = (item: PickerItem, filter: string) => (item.value as string).toLowerCase().includes(filter.toLowerCase());

export const CharacterPicker = () => {
  const dispatch = useDispatch();

  const { user, exclusive } = useSelector(getCharacterItems);

  const onPickerUpdate = ({to}: PickerData) => {
    dispatch(appSlice.actions.setUserChars(to.map(i => i.id)));
  }
  
  return (
    <Col>
      <Title>Character Picker</Title>
      <Picker 
        fromTitle='Available exclusive Characters'
        from={exclusive}
        toTitle='User Characters'
        to={user}
        onPickerUpdate={onPickerUpdate}
        filterFromBy={filterByValue}
        filterToBy={filterByValue}
      />
      <VGap size={8} />
      <AssemblerInput />
    </Col>
  )
}
