import { SvsBridge } from "@storyverseco/svs-navbar"
import { useEffect, useState } from "react";
import { getNavbar, loadNavbar } from "../../lib/navbar";
import { getEnv } from "../../lib/getEnv";

interface NavbarState {
  navbar: SvsBridge;
  loadingNavbar: boolean;
  navbarReady: boolean;
}
const state = {
  navbar: undefined,
  loadingNavbar: false,
  navbarReady: false,
} as unknown as NavbarState
export const useNavbar = (caller: string) => {

  const [_next, _setNext] = useState(0);

  const next = () => {
    _setNext(_next+1);
  }

  useEffect(() => {
    if (state.navbar || state.loadingNavbar) {
      return;
    }
    state.loadingNavbar = true;
    const init = async() => {
      await loadNavbar(getEnv());
      state.navbar = getNavbar();
      state.loadingNavbar = false;
      state.navbarReady = true;
      next();
    }
    init();
  }, []);

  // console.log('useNavbar', caller, state);

  return state;
}
