import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { ToastContainer } from 'react-bootstrap';
import { Alert, AlertType } from './Alert';

type ToastContextType = {
  message: string;
  key: number;
  time: number;
  type: AlertType;
  isOpen: boolean;
  set: (d: Partial<ToastContextType>) => void;
};

const defaultContext: ToastContextType = {
  message: '',
  key: 0,
  time: 0,
  type: 'success',
  isOpen: false,
  set: () => {},
};

const ToastContext = createContext<ToastContextType>(defaultContext);

/*
Usage (Inside a react functional component only):
```
const { success, info, warning, error } = useToast();
...
success('Success message', 3000)
info('Info message', 8000)
warning('Warning message', 5000)
error('Error message', 2000)
...
```
*/
export const useToast = (): Record<
  AlertType,
  (m: ToastContextType['message'], time?: ToastContextType['time']) => void
> => {
  const { set } = useContext(ToastContext);
  const toastMaker = useMemo(
    () => ({
      success: (message: string, time = 1000) => {
        set({ message, time, type: 'success', key: Date.now(), isOpen: true });
      },
      // info: (message: string, time = 5000) => {
      //   set({ message, time, type: 'info', key: Date.now(), isOpen: true });
      // },
      warning: (message: string, time = 5000) => {
        set({ message, time, type: 'warning', key: Date.now(), isOpen: true });
      },
      error: (message: string, time = 5000) => {
        set({ message, time, type: 'error', key: Date.now(), isOpen: true });
      },
    }),
    [set],
  );

  return toastMaker;
};

export const ToastContextProvider = ({
  children,
  value,
}: { value?: ToastContextType; children: React.ReactNode }) => {
  const [data, setData] = useState<ToastContextType>(value || defaultContext);
  const _setData = useCallback((newData: any) => {
    setData((oldData) => ({
      ...oldData,
      ...newData,
    }));
  }, []);

  return (
    <ToastContext.Provider
      value={{
        ...data,
        set: _setData,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export const Toast = () => {
  const { message, time, type, isOpen, key, set } = useContext(ToastContext);
  const close = useCallback(() => {
    set({ isOpen: false });
  }, [set]);

  return (
    <ToastContainer position="top-end" className="p-3">
      {isOpen && (
        <Alert onClose={close} type={type}>
          {message}
        </Alert>
      )}
    </ToastContainer>
  );
};
