import styled from "styled-components";
import { Main } from "../../layout/Main";
import { Col, HGap, Row, VGap } from "../../../components/Layout";
import { Accordion } from "../../../components/Accordion";
import { TextInput } from "../../../components/TextInput";
import { useMemo, useEffect, useState, useCallback, ChangeEvent } from "react";
import { api } from "../../api/PMToolApi";
import { useDispatch } from "react-redux";
import { appSlice } from "../../state/appSlice";
// import {JsonViewer} from '@textea/json-viewer';
import JsonEditor from 'react-json-editor-ui'
import 'react-json-editor-ui/dist/react-json-editor-ui.cjs.development.css'
import { useToast } from "../../../components/Toast";
import { isEqual } from "../../../lib/utils";
import { Button, Form } from "react-bootstrap";
import { useSVSCognito } from "../../hooks/useSVSCognito";
import { useLogin } from "../../hooks/useLogin";
import { WalletAddress } from "@storyverseco/svs-types";
import { getConfig } from "../../../Config";

const ControlsContainer = styled(Col)`
  margin-bottom: 16px;
`;

const AccordionRow = styled(Row)`
  justify-content: space-between;
  padding: 0px 12px;
`;

const AccordionContainer = styled(Row)`
  overflow-y: scroll;
  padding: 0px 8px;
  .accordion {
    width: 100%;
  }
`;

const RawJson = styled.textarea`
  width: 100%;
  height: 100%;
`;

const HintText = styled(Form.Text)`
  align-self: end;
`;

enum Views {
  RAW,
  PRETTY,
}

export const SaleDataPage = () => {

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  const [saleDataName, setSaleDataName] = useState('');

  const [originalSaleData, setOriginalSaleData] = useState<any>();
  const [saleData, setSaleData] = useState<any>({});

  const [_, _setNext] = useState(0);

  const { success, error } = useToast();

  const { getUser } = useSVSCognito();
  const { wallet } = useLogin('saleData');

  const [saleDataJson, setSaleDataJson] = useState<string>();

  const [viewMode, setViewMode] = useState(Views.PRETTY);

  const fetchSaleData = useCallback(async() => {
    dispatch(appSlice.actions.suspend());
    try {
      const data = (await getConfig()).saleData;
      setSaleData(data);
      setOriginalSaleData(data);
      success(`Sale data fetched!`);
    } catch(e: any) {
      error(e.message);
    }
    dispatch(appSlice.actions.resume());
  }, []);

  useEffect(() => {
    fetchSaleData();
  }, []);

  const filteredItems = useMemo(() => Object.keys(saleData).filter(k => k.toLowerCase().includes(search.toLowerCase())), [saleData, search]);

  const accordionItems = useMemo(() => filteredItems.map((key) => {
    const data = saleData[key];
    return {
      title: <AccordionRow centerV>{key}<Button variant='danger' onClick={() => {
        setSaleData({
          ...Object.keys(saleData).reduce((res, cur) => {
            if (cur === key) {
              return res;
            }
            return {...res, [cur]: saleData[cur]}
          }, {})
        });
      }}>Remove</Button></AccordionRow>,
      content: (
        <JsonEditor 
          key={key}
          data={data} 
          onChange={(newData) => {
            setSaleData({
              ...saleData,
              [key]: newData,
            })
          }}
        />
      ) 
    }
  }), [filteredItems]);
  
  const onSave = useCallback(async() => {
    if (!wallet) {
      return;
    }
    dispatch(appSlice.actions.suspend());
    try {
      await api.updateSaleData({ saleData });
      // on success, current data = origina data
      setOriginalSaleData(saleData);
      success(`Sale data fetched!`);
    } catch(e: any) {
      error(e.message);
    }
    dispatch(appSlice.actions.resume());
  }, [saleData, wallet?.address]);

  const onReset = useCallback(() => {
    setSearch('');
    fetchSaleData();
  }, []);

  const disableButtons = useMemo(() => !originalSaleData || isEqual(saleData, originalSaleData), [saleData, originalSaleData]);

  const onAdd = useCallback(() => {
    if (!originalSaleData) {
      return;
    }

    const fezlove = originalSaleData.svsfl;

    setSaleData({
      ...saleData,
      [saleDataName]: {
        ...fezlove,
        saleId: saleDataName,
        tokenType: saleDataName,
        marketingCampaign: saleDataName,
      }
    });

    success(`New property added to saleData`);
    setSearch(saleDataName);
    setSaleDataName('');
  }, [originalSaleData, saleData, saleDataName]);

  const updateView = (mode: Views) => () => {
    if (mode === Views.RAW) {
      setSaleDataJson(JSON.stringify(saleData, null, 2));
    } else {
      if (saleDataJson) {
        try {
          const newSaleData = JSON.parse(saleDataJson);
          setSaleData(newSaleData);
        } catch {
          error(`Invalid JSON input. Fix and try again`);
          return;
        }
      }
    }
    setViewMode(mode);
  }

  const onSaleDataJsonChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    setSaleDataJson(evt.target.value);
  }

  return (
    <Main
      title='Update config'
      subtitle='Edit Sale Data config'
    >
      <ControlsContainer clear>
        <Row>
          <TextInput 
            label='New Sale ID'
            value={saleDataName}
            onChange={setSaleDataName} 
          />
          <HGap size={8} />
          <Button variant='primary' onClick={onAdd} disabled={!saleDataName || !originalSaleData}>Add</Button>  
          <HGap size={8} />
          <TextInput 
            label='Search'
            value={search}
            onChange={setSearch} 
          />
          <HGap size={8} />
          <Row clear>
            <Button variant='danger' onClick={onReset} disabled={disableButtons}>Reset</Button>  
            <HGap size={8} />
            <Button variant='success' onClick={onSave} disabled={disableButtons}>Save</Button>  
          </Row>
        </Row>
        <VGap size={8} />
        <Row clear>
          <Button variant='info' onClick={updateView(Views.RAW)} disabled={viewMode === Views.RAW}>Raw</Button>  
          <Button variant='info' onClick={updateView(Views.PRETTY)} disabled={viewMode === Views.PRETTY}>Pretty</Button>  
          <HGap size={8} />
          <HintText muted>You must be in `Pretty` mode to save</HintText>
        </Row>
      </ControlsContainer>
      <AccordionContainer>
        {
          viewMode === Views.RAW && (
            <RawJson
              value={saleDataJson}
              onChange={onSaleDataJsonChange}
            />
          )
        }
          {
          viewMode === Views.PRETTY && (
            <Accordion
              items={accordionItems}
            />    
          )
        }
      </AccordionContainer>
    </Main>
  );
}