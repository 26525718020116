import styled from 'styled-components';

interface WrapperProps {
  hide?: boolean;
  center?: boolean;
  flex?: number;
  centerV?: boolean;
  centerH?: boolean;
  clear?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ hide }: WrapperProps) => (hide ? 'display: none' : 'display: flex')};
  ${({ flex }: WrapperProps) => (flex ? `flex: ${flex}` : '')};
  ${({ center }: WrapperProps) =>
    center
      ? `
      align-items: center;
      justify-content: center;
  `
      : ''};
`;

export const Box = styled(Wrapper)`
  ${({ flex, clear }) => `flex: ${flex || (clear ? 'initial' : 1)}`};
`;

export const Col = styled(Box)`
  flex-direction: column;
  ${({ centerV }) => (centerV ? 'justify-content: center;' : '')}
  ${({ centerH }) => (centerH ? 'align-items: center;' : '')}
`;

export const Row = styled(Box)`
  flex-direction: row;
  ${({ centerV }) => (centerV ? 'align-items: center;' : '')}
  ${({ centerH }) => (centerH ? 'justify-content: center;' : '')}
`;

const Gap = styled.span<{
  line?: boolean;
  size: number;
}>`
  ${({line, size}) => line && `
    margin-left: ${size}px;
    border-left: 1px solid black;
    height: 100%;
  `}
`

export const VGap = styled(Gap)`
  min-height: ${({ size }) => size}px;
`;

export const HGap = styled(Gap)`
  min-width: ${({ size }) => size}px;
`;
