import { Env } from "@storyverseco/svs-types";

// env localStorage key
const ENV_KEY = 'PMT_ENV:1.0.0';
// QueryParam to set env
const ENV_QP = 'env';

export const getEnv = (forceEnv: string | null = new URLSearchParams(window.location.search).get(ENV_QP)): Env => {
  let env = localStorage.getItem(ENV_KEY) || 'dev';

  if (forceEnv) {
    env = forceEnv;
  }

  localStorage.setItem(ENV_KEY, env);
  return env as Env;
}