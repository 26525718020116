import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Accordion } from '../../../components/Accordion';
import { Col, VGap } from '../../../components/Layout';
import { Page } from "../../../components/Page";
import { StoryWalletAddressInput } from './components/StoryWalletAddressInput';
// import { StoryList } from '../../components/StoryList';
import { Title } from '../../../components/Text';
import { useToast } from '../../../components/Toast';
import { api } from '../../api/PMToolApi';
import { appSlice } from '../../state/appSlice';
import { getIsStoryPageReady, getShowAccordion } from '../../state/selectors';
import { UserSettings } from './components/userSettings/UserSettings';
import { StoryCast } from './components/userStories/StoryCast';
import { Main } from '../../layout/Main';
 
const Container = styled(Col)`
  padding: 8px;
`;

const Content = styled(Col)`
  // width: 1200px;
`;

export const UserPage = () => {

  const dispatch = useDispatch();

  const showAccordion = useSelector(getShowAccordion);

  const isReady = useSelector(getIsStoryPageReady);
  
  const { success, error } = useToast();

  // On Load if page not ready, fetch assets
  useEffect(() => {
    if (isReady) {
      return;
    }
    
    const fetchExclusiveItemsOnce = async() => {
      await dispatch(appSlice.actions.suspend());
      try {
        const exclusiveItems = await api.getExclusiveAssets();
        await dispatch(appSlice.actions.setExclusiveAssets(exclusiveItems));
        success(`App setup complete!`);
      } catch(e: any) {
        error(e.message);
      }
      
      await dispatch(appSlice.actions.resume());
    }

    fetchExclusiveItemsOnce();
  }, [isReady]);

  // @TODO: Whenever we have time, use thunk instead of this
  const onSubmitWalletAddress = async() => {
    // clear previous user
    await dispatch(appSlice.actions.setUser(undefined));
    await dispatch(appSlice.actions.suspend());
    try {
      const user = await api.getUser();
      await dispatch(appSlice.actions.setUser(user));
      await dispatch(appSlice.actions.setUserBgs(user.assets.backgrounds));
      await dispatch(appSlice.actions.setUserChars(user.assets.characters));
      success(`User data retrieved successfully!`);
    } catch (e: any) {
      error(e.message);
    }    
    await dispatch(appSlice.actions.resume());
  }

  return (
    <Main
      title='Story user settings'
      subtitle='Assign characters and backgrounds to users'
    >
      <Content>
        <VGap size={32} />
        <StoryWalletAddressInput onSubmit={onSubmitWalletAddress} />
        <VGap size={32} />
        {
          showAccordion ? (
            <Accordion
              items={[
                {
                  title: 'User settings',
                  content: <UserSettings />,
                },
                {
                  title: 'User stories',
                  content: <StoryCast />,
                }
                // {
                //   title: 'User stories',
                //   content: <StoryList />,
                // }
              ]}
            />        
          ) : <Title center>No data to display. Search by wallet address</Title>
        }
      </Content>
    </Main>
  );
}