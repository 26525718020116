import { SvsBridge, SvsNavbarConfig } from "@storyverseco/svs-navbar";
import { Env } from "@storyverseco/svs-types";
import { getConfig } from "../Config";

interface ConstConfig {
  foundersPassContract: string;
  chainIds: number[];
  walletConnectProjectId: string;
  mbaasBaseUrl: string;
  mbaasApiKey: string;
}
// @TODO: This should all come from svs-config-client
const constConfigs: Record<Env | 'staging', ConstConfig> = {
  dev: {
    foundersPassContract: '0x8c2220db0CCB009Da045744e40e1aCF54A51a478',
    chainIds: [5],
    walletConnectProjectId: '6f681e95dc58090ddf9765d63a1409a1',
    mbaasBaseUrl: 'https://amgpm7yoizg27p4gm7qb3ehsiu.multibaas.com',
    mbaasApiKey:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3MjY2YjNlOC05MmY0LTQyOTQtYWIyYi00OGY5YWZmYmJlNzAiLCJpYXQiOjE2NzQ3NzA5MDAsInN1YiI6IjIifQ.xb5HPSHR_Ah0whN21EwT4a2L_iXEYrDrrRjYYHB3xzA',
  },
  staging: {
    foundersPassContract: '0x8c2220db0CCB009Da045744e40e1aCF54A51a478',
    chainIds: [5],
    walletConnectProjectId: '6f681e95dc58090ddf9765d63a1409a1',
    mbaasBaseUrl: 'https://amgpm7yoizg27p4gm7qb3ehsiu.multibaas.com',
    mbaasApiKey:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3MjY2YjNlOC05MmY0LTQyOTQtYWIyYi00OGY5YWZmYmJlNzAiLCJpYXQiOjE2NzQ3NzA5MDAsInN1YiI6IjIifQ.xb5HPSHR_Ah0whN21EwT4a2L_iXEYrDrrRjYYHB3xzA',
  },
  prod: {
    foundersPassContract: '0x8F1Bc587FF2d87CfC0c5Db69a04D094c523008D4',
    chainIds: [1],
    walletConnectProjectId: 'bb914d7426b72f3f4a595f80cc8330e9',
    mbaasBaseUrl: 'https://mt65tblyozhhlid4zgswhly6qi.multibaas.com',
    mbaasApiKey:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIzYjg3Nzk5Ni0zYWRjLTQxMTUtYTEyZC04MTlkNGU0NTg1ZDIiLCJpYXQiOjE2NzQ4NTI3MzksInN1YiI6IjM1In0.wQscun1GPvcz3WFxl--8WJN5YzRZnbz9EZckFH3B7Do',
  },
};

const getNavbarConfig = async(env: Env): Promise<SvsNavbarConfig>=> {

  const { globals } = await getConfig({env});

  const navbarConfig = {
    persistStoryServerPrefix: `${globals.urls.publish}/story/`,
    serviceProxyUrl: globals.urls.publish,
    plotsBaseURI: `${globals.urls.metadata}/a`,
    persistPlotServerPrefix: `${globals.urls.publish}/plot/`,
    templateApiPrefix: `${globals.urls.publish}/templates/visible/`,
    assetsApiPrefix: `${globals.urls.publish}/assets/`,
    walletConnectConnectorVersion: 2,
    pipelineBaseUrl: globals.urls.publish,
    authBaseUrl: globals.urls.auth,
    mediaServiceBaseUrl: globals.urls.mediaService,
    ...constConfigs[env],
  } as SvsNavbarConfig;

  return navbarConfig;
}

const navbarCache: Partial<Record<Env, SvsBridge>> = {};

let currentEnv: Env = 'dev';
/**
 * Should be called once in the loading stage
 */
export const loadNavbar = async(env: Env): Promise<void> => {
  const cache = navbarCache[env];
  if (cache) {
    return;
  }

  currentEnv = env;
  const navbarConfig = await getNavbarConfig(env);
  const navbar = new SvsBridge(navbarConfig);
  navbarCache[env] = navbar;
}

/**
 * Should only be called after the loading stage
 */
export const getNavbar = (env?: Env): SvsBridge => {
  const navbar = navbarCache[env || currentEnv];
  if (!navbar) {
    throw new Error(`Error (getNavbar): navbar not found. Did you forget to 'await loadNavbar()'`);
  }
  return navbar;
}