import { useSelector, useDispatch } from 'react-redux';
import { getWalletAddress } from '../../../state/selectors';
import { appSlice } from '../../../state/appSlice';
import { WalletAddressInput } from '../../../../components/WalletAddressInput';

interface Props {
  onSubmit: () => void;
}

export const StoryWalletAddressInput = ({ onSubmit }: Props) => {

  const dispatch = useDispatch();

  const walletAddress = useSelector(getWalletAddress);

  const onInputChange = (value: string) => {
    dispatch(appSlice.actions.setWalletAddress(value));
  }

  return (
    <WalletAddressInput
      onSubmit={onSubmit}
      value={walletAddress}
      onChange={onInputChange}
      submitText='Search'
    />
  )
}