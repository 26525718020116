import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Col, HGap, Row, VGap } from "../../../components/Layout";
import { TextInput } from "../../../components/TextInput";
import { Button } from "react-bootstrap";
import { useToast } from "../../../components/Toast";
import { useDispatch } from "react-redux";
import { appSlice } from "../../state/appSlice";
import { api } from "../../api/PMToolApi";
import { useSVSCognito } from "../../hooks/useSVSCognito";
import { Picker } from "../../../components/Picker";
import { PickerData, PickerItem } from "../../../lib/types";
import { Main } from "../../layout/Main";

const Container = styled(Col)`

`;

const InputsContainer = styled(Row)`

`;

const filterByValue = (item: PickerItem, filter: string) => (item.value as string).toLowerCase().includes(filter.toLowerCase());

export const GetDefaultStoryNFTACL = () => {

  const [aclFile, setAclFile] = useState<Record<string, any>>();

  const { success, error } = useToast();

  const dispatch = useDispatch()

  const { getUsers } = useSVSCognito();

  useEffect(() => {
    const fetchDefaultAclFile = async() => {
      dispatch(appSlice.actions.suspend());
      try {
        const acl = await api.getDefaultNFTStoryACL();
        setAclFile(acl);
        success('ACL file retrieved.');
      } catch (e) {
        error(`Failed to load ACL file`);
      }
      dispatch(appSlice.actions.resume());
    }

    fetchDefaultAclFile();
  }, []);

  const assignedItems = useMemo(() => {
    if (!aclFile) {
      return [];
    }

    return Object.keys(aclFile).map(walletAddress => ({
      id: walletAddress,
      value: aclFile[walletAddress].entity,
    }))
  }, [aclFile]);

  const onSave = async() => {
    dispatch(appSlice.actions.suspend());
    try {
      await api.updateDefaultNFTStoryACL(aclFile);
      success(`Acl file updated.`);
    } catch (e: any) {
      error(e.message);
    }
    dispatch(appSlice.actions.resume());
  }

  const disablePicker = useMemo(() => !Boolean(aclFile), [aclFile]);

  const onPickerUpdate = ({to, from}: PickerData) => {
    console.log('onPickerUpdate', {to, from});

    const newAcl = to.reduce((res, cur) => {
      return {
        ...res,
        [cur.id]: {
          entity: cur.value,
          read: true,
        }
      }
    }, {});

    setAclFile(newAcl);
  }

  const availableItems = getUsers().map(user => ({
    id: user.wallets[0],
    value: user.name,
  })).filter(item => !assignedItems.find(ai => ai.id === item.id));

  return (
    <Main
      title='Collectible Stories'
      subtitle='Change who can view a NFT story'
    >
      <VGap size={8} />
      <Picker 
        fromTitle='Available users'
        from={availableItems}
        toTitle='Assigned users'
        to={assignedItems}
        onPickerUpdate={onPickerUpdate}
        filterFromBy={filterByValue}
        filterToBy={filterByValue}
        disabled={disablePicker}
      />
      <br />
      <Button variant='success' onClick={onSave} disabled={disablePicker}>Save ACL</Button>
    </Main>
  );
}