import { createSelector } from '@reduxjs/toolkit';
import { Template } from '@storyverseco/svs-types';
import { RootState } from '../../../state';

// Base
const getInitialTemplates = ({ templates }: RootState) =>
  templates.initialTemplates;

export const getTemplateProjects = ({ templates }: RootState) =>
  templates.projects;

export const getSelectedProject = ({ templates }: RootState) =>
  templates.selectedProject;

export const getTemplates = ({ templates }: RootState) => templates.templates;

export const getShouldFetchProjects = ({ templates }: RootState) =>
  templates.refetchProjects;

// Composed
export const getHasTemplateData = ({ templates }: RootState) =>
  Boolean(templates.initialTemplates);

export const getHasTemplateChanges = createSelector(
  getTemplates,
  getInitialTemplates,
  (templates: Template[], initialTemplates?: Template[]) =>
    templates !== (initialTemplates || [])
);
