import styled from 'styled-components';
import { Row, Col } from './Layout';
import { Title } from './Text';
import { colors } from '../lib/styles';
import { Button } from 'react-bootstrap';
import { ChangeEvent } from 'react';

const Container = styled(Col)`
  max-height: 80px;
`;  

const TextInput = styled.input`
  width: 100%;
  height: 44px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid ${colors.grey};
`;

const InputContainer = styled(Row)`
  width: 100%;
`;

const SubmitBtn = styled(Button)`
  height: 100%;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
`;

const validateCharId = (id: string) => {
  const match = id.match(/^0x\w{40}/);

  if (!match) {
    return false;
  }

  return match[0] === id;
}

interface Props {
  onSubmit: () => void;
  value: string;
  onChange: (value: string) => void;
  submitText?: string;
  submitVariant?: string;
}

export const WalletAddressInput = ({ 
  onChange,
  onSubmit,
  value,
  submitText = 'Submit',
  submitVariant
}: Props) => {
  const onWalletAddressChange = (evt: ChangeEvent<HTMLInputElement>) => {
    onChange(evt.target.value);
  }

  return (
    <Container center>
      <Title bold center>Wallet Address</Title>
      <InputContainer>
        <Row>
          <TextInput 
            value={value}
            onChange={onWalletAddressChange}
          />
        </Row>
        <Row flex={.1} center>
          <SubmitBtn 
            disabled={!validateCharId(value)} 
            onClick={onSubmit}
            variant={submitVariant}
          >
            {submitText}
          </SubmitBtn>
        </Row>
      </InputContainer>
    </Container>
  )
}