/**
 * Just a wrapper for the API which takes care of grabbing data from state, etc.
 * Keep api.ts pure and app should only consume from this.
 */
import { IRevealSchedule, IStory } from '@storyverseco/svs-story-suite';
import {
  UserData,
  PMToolEndpoints,
  ProjectTemplateData,
  Template,
  WalletAddress,
} from '@storyverseco/svs-types';
import { ethers } from 'ethers';
import { getConfig } from '../../Config';
import { assemblerProjects } from '../../lib/consts';
import { Group, Permission, User } from '../../lib/SvsAcl';
import { CharData, ExclusiveAssets, BaseParams, NFTStoriesMetadata, ConfigTarget, LoadingConfig, LoadingConfigs } from '../../lib/types';
import {
  getCoverImage,
  getCreateAtProject,
  getSelectedTemplate,
  getStoryTitle,
} from '../CreateTemplateForm/state/selectors';
import {
  getTemplateAddress,
  getTemplateName,
} from '../pages/admin/state/selectors';
import {
  getSelectedProject,
  getTemplates as getTemplatesSelector,
} from '../pages/templates/state/selectors';
import { store } from '../state';
import {
  getUserAssets,
  getWalletAddress,
  getAuth,
  getAdminWalletAddress,
  getAssemblerProject,
  getAssemblerTokenId,
  getSelectedStory,
  getWallet,
} from '../state/selectors';
import { getJson, request } from './api';
import { MbaasEndpoint, mbaasRequest } from './MbaasApi';
import { SvsBridge } from '@storyverseco/svs-navbar';
import { getEnv } from '../../lib/getEnv';

export const getParams = (
  extraParams: Record<string, any> = {}
): BaseParams & Record<string, any> => {
  const state = store.getState();

  const signature = getAuth(state);
  const env = getEnv();
  const walletAddress = getWallet(state)?.address?.toLowerCase() as WalletAddress;

  if (!signature) {
    // Token expired or state out of sync, refresh page
    // window.top?.location.reload();
    throw new Error(`Error: Cannot make API requests without 'signature'.`);
  }

  if (!env) {
    throw new Error(`Error: Cannot make API requests without 'env'.`);
  }

  const params = {
    auth: {
      signature,
      walletAddress,
    },
    env,
    ...extraParams,
  };

  return params;
};

const getTemplates = async (): Promise<Template[]> => {
  const selectedProject = getSelectedProject(store.getState());
  if (!selectedProject) {
    throw new Error(`Error: Cannot 'getTemplates' without 'selectedProject'`);
  }
  const response = await request(
    PMToolEndpoints.TEMPLATES_GET,
    getParams({
      walletAddress: selectedProject.walletAddress,
    })
  );
  return response.json();
};

const updateTemplates = async (): Promise<void> => {
  const state = store.getState();
  const selectedProject = getSelectedProject(store.getState());
  const templates = getTemplatesSelector(state);
  if (templates.length === 0 || !selectedProject) {
    throw new Error(
      `Error: Cannot 'updateTemplates' without 'templates' or 'selectedProject'`
    );
  }
  const response = await request(
    PMToolEndpoints.TEMPLATES_UPDATE,
    getParams({
      templates,
      walletAddress: selectedProject.walletAddress,
    })
  );
};

const getExclusiveAssets = async (): Promise<ExclusiveAssets> => {
  const response = await request(
    PMToolEndpoints.ASSETS_EXCLUSIVE_GET,
    getParams()
  );
  return response.json();
};

const getUser = async (): Promise<UserData> => {
  const walletAddress = getWalletAddress(store.getState());
  if (!walletAddress) {
    throw new Error(`Error: Cannot 'getTemplates' without 'walletAddress'`);
  }
  const response = await request(
    PMToolEndpoints.USER_GET,
    getParams({
      walletAddress,
    })
  );
  return response.json();
};

const updateUserAssets = async (): Promise<void> => {
  const state = store.getState();
  const walletAddress = getWalletAddress(state);
  const assets = getUserAssets(state);
  if (!assets || !walletAddress) {
    throw new Error(
      `Error: Cannot 'updateUser' without 'assets' or 'walletAddress'`
    );
  }
  await request(
    PMToolEndpoints.USER_UPDATE,
    getParams({
      assets,
      walletAddress,
    })
  );
  return Promise.resolve();
};

// admin
const createUser = async (): Promise<void> => {
  const state = store.getState();
  const walletAddress = getAdminWalletAddress(state);
  console.log(state);
  if (!walletAddress) {
    throw new Error(`Error: Cannot 'createUser' without 'walletAddress'`);
  }
  await request(
    PMToolEndpoints.USER_CREATE,
    getParams({
      walletAddress,
    })
  );
  return Promise.resolve();
};

// admin
const createTemplateProject = async (): Promise<void> => {
  const state = store.getState();
  const walletAddress = getTemplateAddress(state);
  const name = getTemplateName(state);
  if (!walletAddress || !name) {
    throw new Error(
      `Error: Cannot 'createTemplateProject' without 'templateAddress' or 'templateName'`
    );
  }
  await request(
    PMToolEndpoints.PROJECT_TEMPLATES_CREATE,
    getParams({
      walletAddress,
      name,
    })
  );
  return Promise.resolve();
};

const getTemplateProjects = async (): Promise<ProjectTemplateData[]> => {
  const response = await request(
    PMToolEndpoints.PROJECT_TEMPLATES_GET,
    getParams()
  );
  return response.json();
};

const promoteTemplateToTemplate = async () => {
  const state = store.getState();
  const params = {
    selectedProject: getSelectedProject(state),
    coverImage: getCoverImage(state),
    selectedTemplate: getSelectedTemplate(state),
    storyTitle: getStoryTitle(state),
    createAtProject: getCreateAtProject(state),
  };

  if (Object.values(params).some((v) => !v)) {
    throw new Error(
      `Error: Cannot 'createTemplateProject' without ${Object.keys(params).join(
        ' or '
      )}`
    );
  }

  // Using `!` because the check above makes sure they are set but TS doesnt understand that
  await request(
    PMToolEndpoints.TEMPLATES_CREATE,
    getParams({
      templateAddress: params.selectedProject!.walletAddress,
      storyIndex: params.selectedTemplate!.storyIndex,
      destinationTemplateAddress: params.createAtProject!.walletAddress,
      coverImageB64: params.coverImage,
      storyTitle: params.storyTitle,
    })
  );
  return Promise.resolve();
};

const assembleCharacter = async (contractAddress: string): Promise<CharData> => {
  const cfg = await getConfig();

  const params = getParams();

  const state = store.getState();

  // const projectName = getAssemblerProject(state);

  // const contractAddress = assemblerProjects[projectName];

  if (!contractAddress) {
    throw new Error(
      `Error: Cannot 'assembleCharacter' withouth 'contractAddress'`
    );
  }

  const tokenId = getAssemblerTokenId(state);

  const response = await fetch(`${cfg.globals.urls.assembler}/char`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ contractAddress, tokenId, ...params }),
  });

  const { data } = await response.json();

  // Just so it makes clear what data contains
  const metadataUrl = data;

  const { name } = await getJson(metadataUrl);

  return {
    name,
    metadataUrl,
  };
};

const generateStory = async (
  tokenIds: string[],
  project: string
): Promise<string> => {
  if (tokenIds.length <= 0) {
    throw new Error(`Error: Cannot 'generateStory' without at least 1 token`);
  }
  const response = await request(
    PMToolEndpoints.STORY_GENERATE,
    getParams({
      tokenIds,
      contractKey: project,
    })
  );
  const url = await response.text();
  return url;
};

/*
    to?: string;
    from?: string;
    nonce?: BigNumberish;
    gasLimit?: BigNumberish;
    gasPrice?: BigNumberish;
    data?: BytesLike;
    value?: BigNumberish;
    chainId?: number;
    type?: number;
    accessList?: AccessListish;
    maxPriorityFeePerGas?: BigNumberish;
    maxFeePerGas?: BigNumberish;
    customData?: Record<string, any>;
    ccipReadEnabled?: boolean;
*/
const formatEthersTx = (
  txFromAPI: any
): ethers.providers.TransactionRequest => {
  const tx = JSON.parse(JSON.stringify(txFromAPI));
  tx.gasLimit = tx.gas;
  tx.value = ethers.BigNumber.from(tx.value);
  delete tx.gas;
  delete tx.from;
  delete tx.hash;
  delete tx.gasFeeCap;
  delete tx.gasTipCap;
  return tx;
};

const signForMint = async (
  walletAddress: string,
  project: string
): Promise<Response> => {
  const response = await request(
    '/nft/mint' as PMToolEndpoints,
    getParams({
      walletAddress: walletAddress.toLowerCase(),
      project,
    })
  );

  if (!response.ok) {
    const errorStr = await response.text();
    throw new Error(`signForMint error: ${errorStr}`);
  }

  return await response.json();
};

const prepareMint = async (
  walletAddress: string,
  addressLabel: string,
  signedRequest: any
): Promise<ethers.providers.TransactionRequest> => {
  const body = {
    args: [signedRequest.rawMessage, signedRequest.signature],
    from: walletAddress.toLowerCase(),
    signer: walletAddress.toLowerCase(),
    value: ethers.BigNumber.from('0').toString(),
  };
  console.log('mbaas request body:', body);

  const mintData = await mbaasRequest({
    method: 'POST',
    endpoint: MbaasEndpoint.SignatureMint,
    endpointTokens: {
      addressLabel,
    },
    body,
  });

  const tx = mintData?.result?.tx;
  console.log('mbaas tx:', tx);
  if (!tx) {
    throw new Error('prepareMint: No transaction returned');
  }

  const ethersTx = formatEthersTx(tx);
  console.log('ethers tx:', ethersTx);

  return ethersTx;
};

const startMint = async (
  navbar: SvsBridge,
  tx: ethers.providers.TransactionRequest
): Promise<ethers.providers.TransactionResponse> => {
  return await navbar.api.sendTransaction(tx);
};

const waitForReceipt = async (
  navbar: SvsBridge,
  txHash: string
): Promise<ethers.providers.TransactionReceipt> => {
  let receipt: ethers.providers.TransactionReceipt | null = null;
  // 600 times, a bit more than 10 minutes
  for (let i = 0; i < 600 && !receipt; i += 1) {
    receipt = await navbar.api.getTransactionReceipt(txHash);
    if (!receipt) {
      // retry delay: 1000 ms
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }
  if (!receipt) {
    console.error('waiting for receipt timed out');
    throw new Error('Waiting for receipt timed out');
  }
  return receipt;
};

export enum MintStatus {
  Idle = 'idle',
  Signing = 'signing',
  Preparing = 'preparing',
  Minting = 'minting',
  Waiting = 'waiting',
  Minted = 'minted',
  Errored = 'errored',
}

const mintNFT = async (
  navbar: SvsBridge,
  walletAddress: string,
  projectKey: string,
  progressCallback?: (status: MintStatus) => void
): Promise<boolean> => {
  const cfg = await getConfig();
  if (!(projectKey in cfg.globals.genericNFT)) {
    console.error('Invalid project key', projectKey);
    throw new Error(`"${projectKey}" does not exist in genericNFT`);
  }
  const project =
    cfg.globals.genericNFT[projectKey as keyof typeof cfg.globals.genericNFT];
  if (!project) {
    console.error('Project is null for', projectKey);
    throw new Error(`"${projectKey}" found but value is null`);
  }
  progressCallback?.(MintStatus.Signing);
  console.log('signing for mint...');
  const signedRequest = await signForMint(walletAddress, projectKey);
  console.log('signedRequest:', signedRequest);
  progressCallback?.(MintStatus.Preparing);
  console.log('preparing mint...');
  const tx = await prepareMint(
    walletAddress,
    project.addressLabel,
    signedRequest
  );
  console.log('tx:', tx);
  progressCallback?.(MintStatus.Minting);
  console.log('starting mint...');
  const txResponse = await startMint(navbar,tx);
  console.log('tx response:', txResponse);
  progressCallback?.(MintStatus.Waiting);
  console.log('waiting for receipt...');
  const receipt = await waitForReceipt(navbar, txResponse.hash);
  console.log('tx receipt:', receipt);

  if ('status' in receipt && !receipt.status) {
    console.error('tx receipt unsuccessful');
    throw new Error('Minting receipt indicates not successful');
  }

  return true;
};

const getRevealSchedule = async (walletAddress: string, storyId: string) => {
  try {
    const response = await request(
      '/nft/revealschedule' as PMToolEndpoints,
      getParams({
        storyId,
        walletAddress,
      })
    );
    return response.json();
  } catch (e) {
    console.log(`Error (getRevealSchedule):`, e);
    return [];
  }
};

const setRevealSchedule = async (
  walletAddress: string,
  storyId: string,
  revealSchedule: IRevealSchedule[]
) => {
  await request(
    '/nft/revealschedule/update' as PMToolEndpoints,
    getParams({
      storyId,
      walletAddress,
      revealSchedule,
    })
  );
};

const setStoryCast = async (): Promise<void> => {
  const state = store.getState();
  const walletAddress = getWalletAddress(state);
  const selectedStory = getSelectedStory(state);
  if (!walletAddress || !selectedStory.storyId) {
    throw new Error(
      `Error: Cannot 'Set Cast' without 'storyId' or 'walletAddress'`
    );
  }
  const params = getParams({
    selectedStory,
    walletAddress,
  });

  await request(PMToolEndpoints.STORY_CAST, {
    auth: {
      signature: params.signature,
      walletAddress: getWallet(state)?.address?.toLowerCase() as WalletAddress,
    },
    env: params.env,
    storyId: params.selectedStory.storyId,
    walletAddress: params.walletAddress,
    characters: params.selectedStory.characters,
  });
  return Promise.resolve();
};

const promoteStory = async (from: {
  walletAddress: WalletAddress;
  storyId: number | string;
}) => {
  try {
    const response = await request(
      '/nft/story/publish' as PMToolEndpoints,
      getParams({
        authorWalletAddress: from.walletAddress,
        storyId: from.storyId,
      })
    );
    return await response.json();
  } catch (e) {
    console.log(`Error (promoteStory):`, e);
    throw e;
  }
};

const getPermissions = async() => {
  try {
    const response = await request('/svscognito/acl/permissions' as PMToolEndpoints, getParams());
    return await response.json();
  } catch (e) {
    console.log(`Error (getPermissions):`, e);
    return 'Error getting permissions'
  }
}

const updatePermissions = async(permissions: Permission[]) => {
  try {
    await request('/svscognito/acl/permissions/update' as PMToolEndpoints, getParams({
      permissions
    }));
  } catch (e) {
    console.log(`Error (updatePermissions):`, e);
    return 'Error updating permissions';
  }
}

const getGroups = async() => {
  try {
    const response = await request('/svscognito/acl/groups' as PMToolEndpoints, getParams());
    return await response.json();
  } catch (e) {
    console.log(`Error (getGroups):`, e);
    return 'Error getting groups'
  }
}

const updateGroups = async(groups: Group[]) => {
  try {
    await request('/svscognito/acl/groups/update' as PMToolEndpoints, getParams({
      groups
    }));
  } catch (e) {
    console.log(`Error (updateGroups):`, e);
    return 'Error updating groups';
  }
}

const getUsers = async() => {
  try {
    const response = await request('/svscognito/users' as PMToolEndpoints, getParams());
    return await response.json();
  } catch (e) {
    console.log(`Error (getUsers):`, e);
    return [];
  }
}

const updateUsers = async(users: User[]) => {
  try {
    await request('/svscognito/users/update' as PMToolEndpoints, getParams({
      users
    }));
  } catch (e) {
    console.log(`Error (updateUsers):`, e);
    throw 'Error updating users';
  }
}

const auth = async() => {
  try {
    await request('/auth' as PMToolEndpoints, getParams());
  } catch (e) {
    console.log(`Error (auth):`, e);
    throw new Error(`Error (auth): Error to authorise. Try to 'login' again.`) ;
  }
}

const createNFTStory = async(opts: {
  walletAddress: string; 
  name: string;
}) => {
  try {
    const response = await request('/nft/story/create' as PMToolEndpoints, getParams(opts));
    return (await response.json()).url;
  } catch (e) {
    console.log(`Error (createNFTStory):`, e);
    throw e;
  }
}

const getFile = async(opts: {
  bucket: string;
  key: string;
  json?: boolean; // default to true
}) => {
  try {
    const response = await request('/sudo/file' as PMToolEndpoints, getParams(opts));
    // if (opts.json === false) {
      return response.text();
    // }
    // return response.json();
  } catch (e) {
    console.log(`Error (getFile):`, e);
    throw e;
  }
}

const updateFile = async(opts: {
  bucket: string;
  key: string;
  value: string;
}) => {
  try {
    return request('/sudo/file/update' as PMToolEndpoints, getParams(opts));
  } catch (e) {
    console.log(`Error (getFile):`, e);
    throw e;
  }
}

const getNFTStoryACL = async(opts: {
  walletAddress: string; 
  storyId: string;
}) => {
  try {
    const response = await request('/nft/story/acl' as PMToolEndpoints, getParams(opts));
    return response.json();
  } catch (e) {
    console.log(`Error (createNFTStory):`, e);
    throw e;
  }
}


const updateNFTStoryACL = async(opts: {
  walletAddress: string; 
  storyId: string;
  acl: any;
}) => {
  try {
    return request('/nft/story/acl/update' as PMToolEndpoints, getParams(opts));
  } catch (e) {
    console.log(`Error (createNFTStory):`, e);
    throw e;
  }
}

const getNFTStories = async(): Promise<NFTStoriesMetadata> => {
  try {
    const response = await request('/nft/stories' as PMToolEndpoints, getParams());
    return response.json();
  } catch (e) {
    console.log(`Error (getNFTStories):`, e);
    throw e;
  }
}

const updateSaleData = async({saleData}: {
  saleData: Record<string, any>;
}) => {
  try {
    const response = await request('/config/update' as PMToolEndpoints, getParams({value: { saleData }, target: 'saleData'}));
    return response.json();
  } catch (e) {
    console.log(`Error (getNFTStories):`, e);
    throw e;
  }
}

const getFormDataParams = (fields: Record<string, string>, fileData?: { file: File, filename?: string }) => {
  const formData = new FormData();
  Object.keys(fields).forEach(key => formData.append(key, fields[key]));
  if (fileData) {
    if (fileData.filename) {
      formData.append("file", fileData.file, fileData.filename);
    } else {
      formData.append("file", fileData.file);
    }
  }
  
  return {
    method: "POST",
    body: formData
  }
}

const uploadFileWithSignedPost = async(opts: {
  file: File;
  path: string;
  filename?: string;
}) => {
  const filename = opts.filename || opts.file.name;
  let signedPostData: any;
  console.log('uploadFileWithSignedPost', {filename});
  try {
    const response = await request('/media/upload/sign' as PMToolEndpoints, getParams({ filename, path: opts.path }));
    signedPostData = await response.json();
  } catch (e) {
    console.log(`Error (uploadFileWithSignedPost):`, e);
    throw new Error(`Error: Could not sign post for '${filename}'.`);
  }

  // console.log('uploadFileWithSignedPost', {signedPostData});

  const uploadResponse = await fetch(signedPostData.url, getFormDataParams(signedPostData.fields, { file: opts.file, filename }));
  if (!uploadResponse.ok) {
    throw new Error(uploadResponse.statusText);
  }

  const config = await getConfig();

  const uploadedImageUrl = `${config.globals.urls.media}/${opts.path}/${filename}`;
  
  return uploadedImageUrl;
}

const listMediaFiles = async({path}: {
  path: string;
}) => {
  console.log('listMediaFiles', {path});
  try {
    const response = await request('/media/list' as PMToolEndpoints, getParams({ path }));
    return response.json();
  } catch (e) {
    console.log(`Error (listMediaFiles):`, e);
    throw new Error(`Error: Could not list media files for '${path}'.`);
  }
}

const deleteMediaFiles = async({fileUrl}: {
  fileUrl: string;
}) => {
  const fileKey = fileUrl.substring(fileUrl.indexOf('/', 'https://'.length)+1);
  console.log('deleteMediaFiles', {fileUrl, fileKey});
  try {
    const response = await request('/media/delete' as PMToolEndpoints, getParams({ key: fileKey }));
    return response.json();
  } catch (e) {
    console.log(`Error (deleteMediaFiles):`, e);
    throw new Error(`Error: Could not list media files for '${fileUrl}'.`);
  }
}

const getNFTWriters = async() => {
  console.log('getNFTWriters');
  try {
    const response = await request('/writers' as PMToolEndpoints, getParams());
    return response.json();
  } catch (e) {
    console.log(`Error (getNFTWriters):`, e);
    throw new Error(`Error: Could not get NFT writers.`);
  }
}

const updateNFTWriters = async({writers}: {
  writers: Record<string, string>;
}) => {
  console.log('updateNFTWriters', {writers});
  try {
    const response = await request('/writers/update' as PMToolEndpoints, getParams({ writers }));
    return response.json();
  } catch (e) {
    console.log(`Error (updateNFTWriters):`, e);
    throw new Error(`Error: Could not update NFT Writers.`);
  }
}

const updateConfig = async({target, value}: {
  target: ConfigTarget;
  value: Record<string, string>;
}) => {
  console.log('updateConfig', {value});
  try {
    const response = await request('/config/update' as PMToolEndpoints, getParams({ target, value }));
    return response.json();
  } catch (e: any) {
    console.log(`Error (updateConfig):`, e);
    throw new Error(`Error: Could not update '${target}' config. ${e.message}`);
  }
}

const getLoadingConfig = async(): Promise<LoadingConfigs> => {
  try {
    const response = await request('/loading/config' as PMToolEndpoints, getParams());
    return response.json();
  } catch(e: any) {
    console.log(`Error (getLoadingConfig):`, e);
    throw new Error(`Error: Could get loading config.`);
  }
} 

const updateLoadingConfig = async(props: {
  walletAddress: string;
  storyId: string;
  data: LoadingConfig;
}) => {
  try {
    const response = await request('/loading/config/update' as PMToolEndpoints, getParams(props));
    return response.json();
  } catch(e: any) {
    console.log(`Error (updateLoadingConfig):`, e);
    throw new Error(`Error: Could update loading config.`);
  }
} 

const getCPContracts = async(): Promise<Record<string, any>> => {
  try {
    const response = await request('/config/charpass' as PMToolEndpoints, getParams());
    return response.json();
  } catch(e: any) {
    console.log(`Error (getCPContracts):`, e);
    throw new Error(`Error: Could not get character pass contracts.`);
  }
} 

const getStory = async(props: {
  walletAddress: string;
  storyId: string;
}): Promise<IStory> => {
  try {
    const response = await request(PMToolEndpoints.STORY_GET, getParams(props));
    return response.json();
  } catch(e: any) {
    console.log(`Error (getCPContracts):`, e);
    throw new Error(`Error: Could not get character pass contracts.`);
  }
}

const getDefaultNFTStoryACL = async() => {
  try {
    const response = await request('/nft/story/acl/default' as PMToolEndpoints, getParams());
    return response.json();
  } catch (e) {
    console.log(`Error (getDefaultNFTStoryACL):`, e);
    throw e;
  }
}

const updateDefaultNFTStoryACL = async(acl: any) => {
  try {
    const response = await request('/nft/story/acl/default/update' as PMToolEndpoints, getParams({acl}));
    return response.json();
  } catch (e) {
    console.log(`Error (updateDefaultNFTStoryACL):`, e);
    throw e;
  }
}

const getAttributeMappings = async() => {
  try {
    const response = await request('/nft/story/attributemappings' as PMToolEndpoints, getParams());
    return response.json();
  } catch (e) {
    console.log(`Error (getAttributeMappings):`, e);
    throw e;
  }
}

const updateAttributeMappings = async(contractAddress: string, mappings: any) => {
  try {
    const response = await request('/nft/story/attributemappings/update' as PMToolEndpoints, getParams({contractAddress, mappings}));
    return response.json();
  } catch (e) {
    console.log(`Error (updateAttributeMappings):`, e);
    throw e;
  }
}

const nftStoryToTemplate = async(props: {
  nftStoryCreatorUrl: string;
  storyId: string;
}) => {
  try {
    const response = await request('/nft/story/transformtemplate' as PMToolEndpoints, getParams(props));
    return response.json();
  } catch (e) {
    console.log(`Error (updateAttributeMappings):`, e);
    throw e;
  }
}
  

export const api = {
  /** sudo services */
  getFile,
  updateFile,
  /** */
  auth,
  getUser,
  createUser,
  updateUserAssets,
  getExclusiveAssets,
  assembleCharacter,
  getTemplates,
  updateTemplates,
  getTemplateProjects,
  createTemplateProject,
  promoteTemplateToTemplate,
  generateStory,
  mintNFT,
  getRevealSchedule,
  setRevealSchedule,
  setStoryCast,
  promoteStory,
  /* SVS Cognito */
  getPermissions,
  getGroups,
  getUsers,
  updatePermissions,
  updateGroups,
  updateUsers,
  /** */
  createNFTStory,
  getNFTStoryACL,
  updateNFTStoryACL,
  getNFTStories,
  updateSaleData,
  uploadFileWithSignedPost,
  listMediaFiles,
  deleteMediaFiles,
  getNFTWriters,
  updateNFTWriters,
  updateConfig,
  getLoadingConfig,
  updateLoadingConfig,
  getCPContracts,
  getStory,
  getDefaultNFTStoryACL,
  updateDefaultNFTStoryACL,
  getAttributeMappings,
  updateAttributeMappings,
  nftStoryToTemplate,
}
