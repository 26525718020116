import { Dispatch } from '@reduxjs/toolkit';
import { ThunkParams } from '../../lib/types';
import { appSlice } from './appSlice';

interface ThunkAPI {
  dispatch: Dispatch;
}

export const createThunk = (promise: (thunkAPI: ThunkAPI) => Promise<void>) => {
  return async({
    onSuccess,
    onFailure,
  }: ThunkParams<{}>, thunkAPI: ThunkAPI) => {
    thunkAPI.dispatch(appSlice.actions.suspend());
    try {
      await promise(thunkAPI);
      await onSuccess();
    } catch(e) {
      console.error(e);
      await onFailure();
    }
    thunkAPI.dispatch(appSlice.actions.resume());  
  }  
}
