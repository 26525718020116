import styled from "styled-components";
import { Col, Row } from "../../components/Layout";
import { Routes, useNavigation } from "../navigation/useNavigation";
import { Accordion } from "../../components/Accordion";
import { useMemo, useState } from "react";
import './Sidebar.css';
import { Link, useParams } from "react-router-dom";
import { colors } from "../../lib/styles";

const headerHeight = '60px';

const Container = styled(Col)`
  flex: initial;
  min-width: 240px;
  min-height: calc(100vh - ${headerHeight});
  background: #18033f;
  border-right: 2px solid rgb(24, 3, 63);
`;

const ButtonContainer = styled(Row)<{
  selected: boolean;
}>`
  cursor: pointer;
  padding: 12px;
  border-bottom: 1px solid black;
  background: ${({selected}) => selected ? colors.purple : colors.white};
  a {
    color: ${({selected}) => selected ? colors.white : colors.purple};
  }
  margin-left: 8px;
`;

const LinkButton = ({title, route, selectedRoute}:  {title: string, route: Routes, selectedRoute: Routes}) => {
  return (
    <ButtonContainer selected={selectedRoute === route}>
      <Link to={route}>{title}</Link>
    </ButtonContainer>
  )
}

interface SidebarEntry {
  title: string;
  links: {
    title: string;
    route: Routes;
    pathParams?: string[];
  }[];
}

const sidebarLinks: SidebarEntry[] = [
  {
    title: 'Story User',
    links: [
      {
        title: 'Characters/Background',
        route: Routes.STORY_USER,
      },
    ]
  },
  {
    title: 'Collectible Stories',
    links: [
      {
        title: 'Writer links',
        route: Routes.STORY,
      }, 
      {
        title: 'Published Stories',
        route: Routes.STORY_PUBLISHED,
      }, 
      {
        title: 'Create new story',
        route: Routes.STORY_CREATE,
      }, 
      {
        title: 'Story Access Permission',
        route: Routes.STORY_ACL,
      },
      {
        title: 'Reveal Schedule',
        route: Routes.STORY_REVEAL_SCHEDULE,
      },
      {
        title: 'Promote Story',
        route: Routes.STORY_PROMOTE,
      },
      {
        title: 'Edit Story Writers',
        route: Routes.STORY_WRITERS,
      },
      {
        title: 'Edit Stories Attribute Mappings',
        route: Routes.STORY_ATTRIBUTE_MAPPING,
      }
    ]
  }, 
  {
    title: 'Character Pass',
    links: [
      {
        title: 'Mint',
        route: Routes.CP_MINT,
      },
    ]
  },
  {
    title: 'Raffle',
    links: [
      {
        title: 'View Raffle Pages',
        route: Routes.CONFIG_RAFFLE_PAGE,
      },
      {
        title: 'Create Raffle Page',
        route: Routes.CONFIG_RAFFLE_PAGE_UPDATE,
        pathParams: ['saleId'],
      },
      
    ]
  },
  // @TODO: Make config editing eng only
  {
    title: 'Config',
    links: [
      {
        title: 'Sale Data',
        route: Routes.CONFIG_SALE_DATA,
      },
      {
        title: 'Loading',
        route: Routes.CONFIG_LOADING,
      },
      {
        title: 'Default NFT Story ACL',
        route: Routes.CONFIG_DEFAULT_STORIES_ACL,
      }, 
    ]
  },
  {
    title: 'Media',
    links: [
      {
        title: 'Site media upload',
        route: Routes.MEDIA_MAINSITE,
      },
    ]
  },
  {
    title: 'Twitter',
    links: [
      {
        title: 'Send DM',
        route: Routes.TWIITER_DM,
      }
    ]
  },
  {
    title: 'Users / Access Control',
    links: [
      {
        title: 'Users',
        route: Routes.COGNITO_USERS,
      },
      {
        title: 'Groups',
        route: Routes.COGNITO_GROUPS,
      },
      {
        title: 'Permissions',
        route: Routes.COGNITO_PERMISSIONS,
      },
    ]
  },
  {
    title: 'Tools',
    links: [
      {
        title: 'Validate story data',
        route: Routes.TOOL_VALIDATE_STORY,
      }
    ]
  },
  {
    title: 'Engineering',
    links: [
      {
        title: 'Edit S3 files with ease',
        route: Routes.SUDO_FILE_EDITOR,
      },
      {
        title: 'Postman',
        route: Routes.SUDO_POSTMAN,
      }
    ]
  },
]

const groupRoutes = (group: SidebarEntry) => (group.links ?? []).map(l => l.route);


export const Sidebar = () => {
  const { route } = useNavigation();

  const [defaultActiveKey, setDefaultActiveKey] = useState<string[]>([]);

  const accordionItems = useMemo(() => {
    return sidebarLinks.map((group, index) => {
      if (groupRoutes(group).includes(route)) {
        setDefaultActiveKey([index.toString()]);
      }
      return {
        title: group.title,
        content: (
          <>
            {
              group.links.map(link => {
                return (
                  <LinkButton key={link.route} {...link} selectedRoute={route as Routes} />
                )
              })
            }
          </>
        )
      }
    });
  }, [route]);

  // Render
  if (route === Routes.HOME) {
    return null;
  }
  
  return (
    <Container id='sidebar'>
      <Accordion 
        items={accordionItems}
        defaultActiveKey={defaultActiveKey}
      />
    </Container>
  );
}