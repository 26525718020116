import { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { Col, HGap, Row, VGap } from '../../../../components/Layout';
import { TextInput } from '../../../../components/TextInput';
import './groups.css';

const Container = styled(Row)`
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 16px;
`;

interface ListItem {
  name: string;
  [key: string]: any;
}

interface Props {
  target: 'Group' | 'Permission' | 'User';
  onAdd: (name: string) => void;
  /**
   * Not quite sure about this tbh but since they share the exact logic 
   * I thought I could try. 
   */
  list: ListItem[];
  onFilter: (list: ListItem[]) => void;
}

export const ControlsHeader = ({ target, list = [], onAdd, onFilter }: Props) => {
  const [newName, setNewName] = useState('');

  const [search, setSearch] = useState('');

  const onAddClick = useCallback(() => {
    if (!newName) {
      return;
    }

    onAdd(newName);
  }, [newName, onAdd]);

  useEffect(() => {
    onFilter(list.filter(i => i.name.toLowerCase().includes(search.toLowerCase())));
  }, [search]);

  // Reset input whenever list changes
  useEffect(() => {
    setNewName('');
    setSearch('');
  }, [list]);

  return (
    <Container clear>
      <TextInput 
        value={newName}
        onChange={setNewName}
        label={`${target} Name`}
      />
      <HGap size={12} />
      <Button variant='success' onClick={onAddClick} disabled={!newName}>Add {target}</Button>
      <HGap size={36} />
      <TextInput 
        value={search}
        onChange={setSearch}
        label='Search'
      />
    </Container>
  )
}