import { useMemo } from "react";
import { Button, Modal as BSModal } from "react-bootstrap";

const btnDefaults = {
  submit: {
    text: 'Submit',
    variant: 'success'
  },
  cancel: {
    text: 'Cancel',
    variant: 'danger'
  }
}

interface BtnConfig {
  onPress: () => void;
  text?: string;
  variant?: string;
}

interface Props {
  title: string;
  show: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  loading?: boolean;
  cancelBtn?: BtnConfig;
  submitBtn?: BtnConfig;
}

export const Modal = ({
  show,
  onClose,
  title,
  children,
  loading = false,
  cancelBtn,
  submitBtn,
}: Props) => {

  
  const showFooter = useMemo(() => Boolean(submitBtn || cancelBtn), [submitBtn, cancelBtn]);

  const btnsConfig = useMemo(() => {
    return {
      submit: submitBtn && {
        ...btnDefaults.submit,
        ...submitBtn,
      },
      cancel: cancelBtn && {
        ...btnDefaults.cancel,
        ...cancelBtn,
      }
    }
  }, [cancelBtn, submitBtn]);

  return (
    <BSModal show={show} onHide={onClose} backdrop="static">
      <BSModal.Header>
        <BSModal.Title>{title}</BSModal.Title>
      </BSModal.Header>
      <BSModal.Body>{children}</BSModal.Body>
      {
        showFooter && (
          <BSModal.Footer>
            {
              btnsConfig.cancel && (
                <Button variant={btnsConfig.cancel.variant} onClick={btnsConfig.cancel.onPress} disabled={loading}>
                  {btnsConfig.cancel.text}
                </Button>
              )
            }
            {
              btnsConfig.submit && (
                <Button variant={btnsConfig.submit.variant} onClick={btnsConfig.submit.onPress} disabled={loading}>
                  {btnsConfig.submit.text}
                </Button>
              )
            }
          </BSModal.Footer>
        )
      }  
    </BSModal>
  );
}