import styled from 'styled-components';
import { Box, Col } from './Layout';

const Container = styled(Col)<{
  dir: string;
}>`
  min-height: 100%;
`;

const Content = styled(Col)`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 88vh;
  padding: 12px;
`;

interface Props {
  children: React.ReactNode;
  dir?: string;
  center?: boolean;
}

export const Page = ({ children, center, dir = 'col' }: Props) => {
  return (
    <Container dir={dir} center={center}>
      <Content>
        {children}
      </Content>
    </Container>
  );
};
