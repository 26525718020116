import { Wallet } from '@storyverseco/svs-navbar-client';
import {
  AssetsData,
  SignatureMessage,
  UserData,
} from '@storyverseco/svs-types';
import { createSelector } from '@reduxjs/toolkit';
import { nftProjects } from '@storyverseco/svs-consts';
import { CharData, Optional, PickerItem, SelectedStory } from '../../lib/types';
import { RootState } from '.';

type Templates = any;

export const showPageLoading = ({ app }: RootState) => app.loading;

export const getWalletAddress = ({ app }: RootState) =>
  app.walletAddress.toLocaleLowerCase();

export const getAdminWalletAddress = ({ app }: RootState) =>
  app.adminWalletAddress.toLocaleLowerCase();

export const getUserBgs = ({ app }: RootState) => app.userBgs;

export const getStories = ({ app }: RootState) => app.stories;

export const getSelectedStory = ({ app }: RootState) => app.selectedStory;

export const getIsStoryPageReady = ({ app }: RootState) => app.isStoryPageReady;

export const getAssemblerProject = ({ app }: RootState) => app.assemblerProject;

export const getAssemblerTokenId = ({ app }: RootState) => app.assemblerTokenId;

const getUser = ({ app }: RootState) => app.user;

export const getWallet = ({app}: RootState) => app.wallet;

const getExclusiveBgs = ({ app }: RootState) => app.exclusiveBgs;

export const getTemplates = ({ app }: RootState) => app.templates;

const getInitialTemplates = ({ app }: RootState) => app.initialTemplates;

export const getBgsPool = createSelector(
  getExclusiveBgs,
  getUserBgs,
  (exclusiveBgs: string[], userBgs: string[]) =>
    exclusiveBgs.filter((bg) => !userBgs.includes(bg))
);

// We fetch available bgs on wallet submit
export const getShowAccordion = createSelector(getUser, (user?: UserData) =>
  Boolean(user)
);

export const getHiddenTemplates = createSelector(
  getTemplates,
  (templates: Templates) =>
    Object.keys(templates).reduce((res, cur) => {
      const storyIndex = Number(cur);
      if (!templates[storyIndex].visible) {
        return {
          ...res,
          [storyIndex]: templates[storyIndex],
        };
      }
      return res;
    }, {} as Templates)
);

export const getVisibleTemplates = createSelector(
  getTemplates,
  (templates: Templates) =>
    Object.keys(templates).reduce((res, cur) => {
      const storyIndex = Number(cur);
      if (templates[storyIndex].visible) {
        return {
          ...res,
          [storyIndex]: templates[storyIndex],
        };
      }
      return res;
    }, {} as Templates)
);

// We should fetch templates if we haven't set initialTemplates yet
export const getShouldFetchTemplates = createSelector(
  getInitialTemplates,
  (initialTemplates?: Templates) => !Boolean(initialTemplates)
);

export const getAuth = createSelector(
  getWallet,
  (wallet?: Wallet) => wallet?.messageAndSig as Optional<SignatureMessage>
);

/**
 * Story characters
 */

const charDataToPickerItem = (data: CharData[]): PickerItem[] =>
  data.map((char) => ({
    id: char.metadataUrl,
    value: char.name,
  }));

/* All Characters and User Characters: mutually exclusive */
const getUserChars = ({ app }: RootState) => app.userChars;

const getExclusiveChars = ({ app }: RootState) => app.exclusiveChars;

const userCharsPool = createSelector(
  getExclusiveChars,
  getUserChars,
  (exclusiveChars: CharData[], userChars: string[]): CharData[] =>
    exclusiveChars.filter((char) => userChars.includes(char.metadataUrl))
);

const exclusiveCharsPool = createSelector(
  getExclusiveChars,
  getUserChars,
  (exclusiveChars: CharData[], userChars: string[]): CharData[] =>
    exclusiveChars.filter((char) => !userChars.includes(char.metadataUrl))
);

export const getCharacterItems = createSelector(
  userCharsPool,
  exclusiveCharsPool,
  (userPool: CharData[], exclusivePool: CharData[]) => ({
    user: charDataToPickerItem(userPool),
    exclusive: charDataToPickerItem(exclusivePool),
  })
);
/* end of All Characters and User Characters: mutually exclusive */

/* All Characters and Selected Story Cast: mutually exclusive */
const allCharactersPool = createSelector(
  getExclusiveChars,
  getSelectedStory,
  (exclusiveChars: CharData[], selectedStory: SelectedStory): CharData[] =>
    exclusiveChars.filter(
      (char) => !selectedStory.characters.includes(char.metadataUrl)
    )
);

//getSelectedStory
export const selectedStoryCharactersPool = createSelector(
  getExclusiveChars,
  getSelectedStory,
  (exclusiveChars: CharData[], selectedStory: SelectedStory): CharData[] =>
    exclusiveChars.filter((char) =>
      selectedStory.characters.includes(char.metadataUrl)
    )
);

export const getSelectedStoryCast = createSelector(
  selectedStoryCharactersPool,
  allCharactersPool,
  (storyPool: CharData[], exclusivePool: CharData[]) => ({
    cast: charDataToPickerItem(storyPool),
    exclusive: charDataToPickerItem(exclusivePool),
  })
);
/* end of All Characters and Selected Story Cast: mutually exclusive */

export const getUserAssets = createSelector(
  getUserBgs,
  getUserChars,
  (backgrounds: string[], characters: string[]): AssetsData => ({
    backgrounds,
    characters,
    stages: [],
  })
);

const getAllCharsUrl = createSelector(
  getExclusiveChars,
  getUserChars,
  (exclusiveChars: CharData[], userChars: string[]): string[] => [
    ...userChars,
    ...exclusiveChars.map((c) => c.metadataUrl),
  ]
);

const getAssemblerUrls = createSelector(
  getAllCharsUrl,
  (charUrls: string[]): string[] => {
    return charUrls.filter((url) => url.includes('assembler/'));
  }
);

const getCDNUrls = createSelector(
  getAllCharsUrl,
  (charUrls: string[]): string[] => {
    return charUrls.filter((url) => url.includes('defaultcast/'));
  }
);

const isTryingToAssembleCDNChar = createSelector(
  getCDNUrls,
  getAssemblerTokenId,
  getAssemblerProject,
  (cdnUrls: string[], tokenId: string, project: string): boolean => {
    const address = nftProjects.find((p) => p.name === project)?.address || '';
    return cdnUrls.some((url) =>
      url.includes(`defaultcast/${address}/${tokenId}/metadata.json`)
    );
  }
);

const isAssemblerUrl = (url: string, project: string, tokenId: string) => {
  const address = nftProjects.find((p) => p.name === project)?.address || '';
  const isUrl =
    url.includes('/assembler') &&
    url.includes(address) &&
    url.includes(`chars/${tokenId}/`);
  return isUrl;
};

export const getCanAssemble = createSelector(
  getAssemblerUrls,
  getAssemblerTokenId,
  getAssemblerProject,
  isTryingToAssembleCDNChar,
  (
    assemblerUrls: string[],
    tokenId: string,
    project: string,
    isCDNChar: boolean
  ): boolean =>
    !assemblerUrls.some((url) => isAssemblerUrl(url, project, tokenId)) &&
    !isCDNChar
);

export const getTwtAuthData = ({ app }: RootState) => app.twtAuthData;

export const getTwtInittedFromQuery = createSelector(
  getTwtAuthData,
  (data) => data.inittedFromQuery
);

export const getTwtAuth1Creds = createSelector(
  getTwtAuthData,
  (data) => data.auth1Creds
);

export const getRedirect = ({ app }: RootState) => app.redirect;

export const getShowAppLayout = ({ app }: RootState) => app.showAppLayout;
