import { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Accordion } from '../../../../components/Accordion';
import { Col, HGap, Row, VGap } from '../../../../components/Layout';
import { Modal } from '../../../../components/Modal';
import { TextInput } from '../../../../components/TextInput';
import { Crud, Group, Permission } from '../../../../lib/SvsAcl';
import { useACL } from '../../../hooks/useSVSCognito';
import { ControlsHeader } from './ControlsHeader';
import './groups.css';
import { Main } from '../../../layout/Main';
import { getEnv } from '../../../../lib/getEnv';

const DeleteBtnContainer = styled(Row)`
`

const ModalContent = styled(Col)`

`;

interface Props {
  
}

export const Groups = ({  }: Props) => {

  const env = getEnv();

  const { getGroups, createGroup, deleteGroup, getGroupsWithPermission, updateGroupPermissions } = useACL({env});

  const [deleteName, setDeleteName] = useState('');

  const [showModal, setShowModal] = useState(false);

  const [deleteCopy, setDeleteCopy] = useState<string>();

  const [filteredGroups, setFilteredGroups] = useState(getGroups());

  const onDeleteGroup = useCallback((name: string) => {
    return () => {
      setDeleteName(name);
      setShowModal(true);
      const groups = getGroupsWithPermission(name);
      setDeleteCopy(`Deleting this permission will affect ${groups.length} groups. Listed below:
      ${groups.join('\n')}`)
    }
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const onDeleteConfirm = useCallback(async () => {
    if (!deleteName) {
      console.error(`Error (Permission): Cannot 'onDeleteConfirm'. Permission to delete not selected.`);
      return;
    }
    setDeleteCopy('')
    await deleteGroup(deleteName);
    setFilteredGroups(getGroups());
    closeModal();
  }, [deleteName]);


  const getPermissionItems = (groupName: string, permissions: Permission[]) => {
    return permissions.map(permission => ({
      title: permission.name,
      content: (
        <Row>
          {
            Object.keys(permission.crud).map(c_r_u_d => {
              const perm = permission.crud[c_r_u_d  as keyof Crud];
              return (
                <TextInput 
                  key={`${permission.name}/${c_r_u_d}`} 
                  label={c_r_u_d} 
                  value='' 
                  onChange={() => {
                    const newCrud = {
                      ...permission.crud,
                      [c_r_u_d]: !perm,
                    }
                    updateGroupPermissions(groupName, permission.name, newCrud);
                  }} 
                  type='checkbox' 
                  checked={perm} 
                />
              )
            })
          }
        </Row> 
      ),
    }));
  }

  const groupItems = filteredGroups.map(group => ({
    title: group.name,
    content: (
      <Row className='group-items'>
        <Accordion 
          items={getPermissionItems(group.name, group.permissions)}
        />
        <HGap size={8} />
        <DeleteBtnContainer flex={0.1}>
          <Button variant='danger' onClick={onDeleteGroup(group.name)}>Delete Group</Button>
        </DeleteBtnContainer>
      </Row>
    )
  }));

  const onCreateGroup = async(name: string) => {
    await createGroup(name);
    setFilteredGroups(getGroups());
  };

  return (
    <Main
      title='Cognito'
      subtitle='Create and edit permission groups'
    >
      <ControlsHeader
        target='Group'
        onAdd={onCreateGroup}
        list={getGroups()}
        onFilter={(list) => setFilteredGroups(list as Group[])}
      />
      <hr />
      <Col>
        <Accordion 
          items={groupItems}
        />
      </Col>
      <Modal
        title={`Are you sure you want to delete '${deleteName}' permission?`}
        show={showModal}
        onClose={closeModal}
        submitBtn={{
          onPress: onDeleteConfirm,
          text: 'Confirm',
          variant: 'primary'
        }}
        cancelBtn={{
          onPress: closeModal,
          text: 'Close',
          variant: 'info'
        }}
      >
        <ModalContent>
          {deleteCopy}
        </ModalContent>
      </Modal>
    </Main>
  )
}