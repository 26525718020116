import { getConfig } from '../../Config';
import { tokenReplace } from '../../lib/utils';

enum ContentType {
  Json = 'application/json',
  Text = 'text/plain',
  Html = 'text/html',
  Form = 'application/x-www-form-urlencoded',
}

export enum MbaasEndpoint {
  SignatureMint = '/api/v0/chains/ethereum/addresses/{addressLabel}/contracts/generic_nft/methods/signatureMint',
}

export type MbaasTokens = {
  addressLabel?: string;
};

const formatResponse = async <TResponse = any>(response: Response): Promise<TResponse> => {
  const headers: Record<string, string> = {};
  response.headers?.forEach((value, key) => {
    headers[key.toLowerCase()] = value;
  });
  const responseContentType = headers['content-type']?.trim() ?? ContentType.Json;
  const str = await response.text();
  if (responseContentType.includes(ContentType.Json)) {
    try {
      return JSON.parse(str);
    } catch (e: any) {
      console.log('response unexpectedly not a JSON, return as string. Error:', e.message);
      return str as unknown as TResponse;
    }
  }
  if (responseContentType.includes(ContentType.Form)) {
    return new URLSearchParams(str).toString() as unknown as TResponse;
  }
  // html, text, default
  return str as unknown as TResponse;
}

const apiCall = async <TBody = any>(opts: { method: string; url: string; body?: TBody; requestInit?: RequestInit }): Promise<Response> => {
  const { headers, ...restInit } = opts.requestInit ?? {};
  const requestInit: RequestInit = {
    method: opts.method,
    headers: {
      'Content-Type': ContentType.Json,
      ...headers,
    },
    cache: 'no-cache',
    ...restInit,
  };
  if (opts.body) {
    requestInit.body = JSON.stringify(opts.body);
  }

  const response = await fetch(opts.url, requestInit);

  return response;
}

export const getMbaasEndpoint = (endpoint: MbaasEndpoint | string, tokens: MbaasTokens = {}): string => {
  return tokenReplace(endpoint, tokens, true);
}

export const mbaasRequest = async <TBody = any, TResponse = any>({
  method,
  endpoint,
  endpointTokens,
  body,
  requestInit,
}: {
  method: string,
  endpoint: MbaasEndpoint | string,
  endpointTokens?: MbaasTokens,
  body?: TBody,
  requestInit?: RequestInit,
}): Promise<TResponse> => {
  const config = await getConfig();
  const actualEndpoint = getMbaasEndpoint(endpoint, endpointTokens);
  const url = config.mbaasUrl + actualEndpoint;
  const { headers, ...restRequestInit } = requestInit ?? {};
  const combinedRequestInit = {
    headers: {
      Authorization: `Bearer ${config.mbaasApiKey}`,
      ...headers,
    },
    ...restRequestInit,
  };
  const response = await apiCall({
    method,
    url,
    body,
    requestInit: combinedRequestInit,
  });
  if (!response.ok) {
    const errorStr = await response.text();
    throw new Error(`Mbaas error (status: ${response.status}): ${errorStr}`);
  }

  return await formatResponse(response);
}
