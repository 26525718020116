import styled from "styled-components";
import { Main } from "../../layout/Main";
import { Col, HGap, Row, VGap } from "../../../components/Layout";
import { Accordion } from "../../../components/Accordion";
import { TextInput } from "../../../components/TextInput";
import { useMemo, useEffect, useState, useCallback, ChangeEvent } from "react";
import { api } from "../../api/PMToolApi";
import { useDispatch } from "react-redux";
import { appSlice } from "../../state/appSlice";
// import {JsonViewer} from '@textea/json-viewer';
import JsonEditor from 'react-json-editor-ui'
import 'react-json-editor-ui/dist/react-json-editor-ui.cjs.development.css'
import { useToast } from "../../../components/Toast";
import { isEqual } from "../../../lib/utils";
import { Button, Image } from "react-bootstrap";
import { useSVSCognito } from "../../hooks/useSVSCognito";
import { useLogin } from "../../hooks/useLogin";
import { ContractAddress, Env, WalletAddress } from "@storyverseco/svs-types";
import { getConfig } from "../../../Config";
import { LoadingConfig, LoadingConfigs } from "../../../lib/types";
import { Title } from "../../../components/Text";
import { Textarea } from "../../../components/Textarea";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { ConfigClient } from "@storyverseco/svs-config-client";
import { IStory } from "@storyverseco/svs-story-suite";
import { getEnv } from "../../../lib/getEnv";

const ControlsContainer = styled(Row)`
  margin-bottom: 16px;
`;

const AccordionRow = styled(Row)`
  justify-content: space-between;
  padding: 0px 12px;
`;

const AccordionContainer = styled(Row)`
  overflow-y: scroll;
  padding: 0px 8px;
  .accordion {
    width: 100%;
  }
`;

const Img = styled(Image)`
  width: 100px;
  align-self: center;
`;

const prodToDevContracts: Record<string, string> = {
  // BAYC
  "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d": "0xe1754daa9fd0d845c3a64d6779e73d085a1b43b7",
  // MAYC
  "0x60e4d786628fea6478f785a6d7e704777c86a7c6": "0x63929D23F2B1451b2783158929f95fEEd88Be251",
  // CPCP
  "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb": "0x67a99d48f0c6b2c21b6a7d6dfbd3389013ed5243",
  // PPG
  "0xbd3531da5cf5857e7cfaa92426877b022e612cf8": "0x06224922B2f9cf06a34A100b72108472101028C6",
}



interface ConfigList {
  storyName: string;
  address: string;
  tokenId: string;
  config: LoadingConfig;
}

const findSaleData = (address: ContractAddress, tokenId: string) => (saleData: any) =>  {
  return saleData.nftWalletAddress === address && saleData.nftStoryId === tokenId;
}

export const LoadingConfigEditor = () => {
  
  const dispatch = useDispatch();

  const [loadingConfigs, setLoadingConfigs] = useState<LoadingConfigs>();

  const [cfg, setCfg] = useState<ConfigClient>();

  const [newCast, setNewCast] = useState('');

  const [cpContracts, setCpContracts] = useState<Record<string, any>>();

  // const [search, setSearch] = useState('');

  // const [saleDataName, setSaleDataName] = useState('');

  // const [_, _setNext] = useState(0);

  const { success, error } = useToast();

  // const { getUser } = useSVSCognito();
  // const { wallet } = useLogin('saleData');

  const fetchLoadingConfig = useCallback(async() => {
    dispatch(appSlice.actions.suspend());
    // get sale data config for both prod and dev
    try {
      setCfg(await getConfig());
    } catch(e: any) {
      console.log('failed to fetch config', e);
      error(e.message);
    }
    // get loading config 
    try {
      const data = await api.getLoadingConfig();
      console.log('loadingConfigs', data);
      setLoadingConfigs(data);
      success(`Loading configs loaded!`);
    } catch(e: any) {
      console.log('failed to fetch loading config', e);
      error(e.message);
    }

    try {
      const cp = await api.getCPContracts();
      setCpContracts(cp);
    } catch(e: any) {
      console.log('failed to fetch charpass contracts', e);
      error(e.message);
    }

    dispatch(appSlice.actions.resume());
  }, []);

  // On component mount fetch loading config
  useEffect(() => {
    fetchLoadingConfig();
  }, []);

  // On config change compute our UI values
  const configList = useMemo(() => {
    if (!loadingConfigs || !cfg?.saleData) {
      return [];
    }

    const list: ConfigList[] = [];

    const contractAddresses = Object.keys(loadingConfigs);

    for (const address of contractAddresses) {
      const contractAddress = address as ContractAddress

      const tokenIds = Object.keys(loadingConfigs[contractAddress]);

      for (const tokenId of tokenIds) {

        const sale: any = Object.values(cfg.saleData).find(findSaleData(contractAddress, tokenId));
          // || Object.values(salesData.prod).find(findSaleData(contractAddress, tokenId));
        
        if (!sale) {
          console.error(`Error: Could not find sale for address: '${address}' | tokenId: '${tokenId}'`);
          continue;
        }

        const loadingConfig = loadingConfigs[contractAddress][tokenId];

        console.log({
          contractAddress,
          tokenId,
          loadingConfig,
        })

        list.push({
          storyName: sale.saleName,
          address,
          tokenId,
          config: {
            ...loadingConfig,
            cast: loadingConfig.cast || [],
          }
        })
      }      
    }

    return list;
  }, [loadingConfigs, cfg?.saleData]);


  const onAddCast = (cfg: ConfigList) => () => {
    const newConfig = JSON.parse(JSON.stringify(loadingConfigs));

    newConfig[cfg.address][cfg.tokenId] = {
      ...cfg.config,
      cast: [
        ...cfg.config.cast,
        newCast,
      ]
    }

    setLoadingConfigs(newConfig);
    setNewCast('');
  }

  const onRemoveCast = (cfg: ConfigList) => (tokenId: string) => {
    const newConfig = JSON.parse(JSON.stringify(loadingConfigs));

    newConfig[cfg.address][cfg.tokenId].cast = 
      newConfig[cfg.address][cfg.tokenId].cast.filter((c: string) => c !== tokenId);

    setLoadingConfigs(newConfig);
  }

  const onAutoCast = ({address, tokenId}: ConfigList) => async() => {
    if (!cfg || !cpContracts) {
      return;
    }
    const newConfig = JSON.parse(JSON.stringify(loadingConfigs));

    const storyData: IStory = await api.getStory({walletAddress: address, storyId: tokenId});

    const castUrls = Object.values(storyData.charSlots).map(s => {
      const env = getEnv();
      const holdingContract: string = env === 'dev' ? prodToDevContracts[s.charMetadata.contractAddress] : s.charMetadata.contractAddress;

      const charpass = Object.values(cpContracts).find(({holdingContracts}) => holdingContracts.includes(holdingContract));

      const url = `${cfg?.globals.urls.media}/charpass/${charpass?.contractAddress}/${s.charMetadata.tokenId}/portrait.jpg`

      return url;
    });
    
    newConfig[address][tokenId].cast = castUrls;

    setLoadingConfigs(newConfig);
  }

  const onSave = ({address, tokenId}: ConfigList) =>  async() => {
    if (!loadingConfigs) {
      return;
    }
    dispatch(appSlice.actions.suspend());
    const data = loadingConfigs[address as ContractAddress][tokenId];
    try {
      
      await api.updateLoadingConfig({
        walletAddress: address,
        storyId: tokenId,
        data
      });
      success(`Config updated for '${data.title}' success!`);
    } catch (e) {
      console.error('Error: Failed to save loadingConfig', e);
      error(`Failed to update '${data.title}' loading config!`);
    }
    dispatch(appSlice.actions.resume());
  }

  const disableSave = useMemo(() => !Boolean(cfg) || !Boolean(cpContracts) || !Boolean(loadingConfigs), [cfg, cpContracts, loadingConfigs])

  return (
    <Main
      title='Update config'
      subtitle='Edit Sale Data config'
    >
      <ControlsContainer clear>
        {/* Add button */}
        {/* <Button variant='primary' onClick={onAdd} disabled={!saleDataName || !originalSaleData}>Add</Button>   */}
        {/* Save button */}
      </ControlsContainer>
      <AccordionContainer>
        <Accordion
          items={configList.map(item => ({
            title: item.storyName,
            content: (
              <Col>
                <Title bold>{item.storyName}</Title>
                <hr />
                <Row>
                  <Row>
                    <TextInput 
                      disabled
                      label='Wallet Address'
                      value={item.address}
                      onChange={() => {}} 
                    />
                  </Row>
                  <HGap size={12} />
                  <Row flex={.6}>
                    <TextInput 
                      label='Story Id'
                      value={item.tokenId}
                      onChange={() => {}} 
                    />
                  </Row>
                </Row>
                <hr />
                <Title bold>Config</Title>
                <Col>
                  <TextInput 
                    label='Title'
                    value={item.config.title}
                    onChange={() => {}} 
                  />
                  {/* <hr /> */}
                  <TextInput 
                    label='Author name'
                    value={item.config.author.name}
                    onChange={() => {}} 
                  />
                  {/* <hr /> */}
                  <TextInput 
                    label='Author avatar url'
                    value={item.config.author.pic}
                    onChange={() => {}} 
                  />
                  {/* <hr /> */}
                  <Textarea
                    label='Author description'
                    value={item.config.author.desc}
                    onChange={() => {}} 
                  />
                </Col>
                <VGap size={4} />
                <Col>
                  <Title bold center>Cast</Title>            
                  {
                    item.config.cast.map(member => (
                      <CastInput
                        key={member}
                        src={member}
                        value={member}
                        onChange={setNewCast}
                        onAdd={onAddCast(item)}
                        onRemove={onRemoveCast(item)}
                      />
                    ))
                  }
                  <VGap size={32} />
                  <Row>
                    <Button onClick={onAutoCast(item)}>Auto cast</Button>
                    <HGap size={32} />
                    <Row center>
                      <Title>Single cast</Title>
                      <HGap size={8} />
                      <CastInput  
                        value={newCast}
                        onChange={setNewCast}
                        onAdd={onAddCast(item)}
                        onRemove={onRemoveCast(item)}
                      />
                    </Row>
                  </Row>      
                </Col>
                <VGap size={8} />
                <Button variant='success' onClick={onSave(item)} disabled={disableSave}>Save</Button>  
                <Col>

                </Col>
                {/* <textarea>{JSON.stringify(item, null, 2)}</textarea> */}
              </Col>
              
            )
          }

          ))}
        />
      </AccordionContainer>
    </Main>
  );
}

const CastInput = ({
  src,
  value,
  onChange,
  onAdd,
  onRemove,
}: {
  src?: string;
  value?: string;
  onChange: (value: string) => void;
  onAdd: () => void;
  onRemove: (tokenId: string) => void;
}) => {
  const onInputChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    onChange(evt.target.value);
  }, []);

  const tokenId = useMemo(() => {
    if (!src) {
      return undefined;
    }
    return value;
  }, [src, value]);

  const config = useMemo(() => {
    return {
      disableInput: Boolean(src),
      btnVariant: src ? 'danger' : 'success',
      btnLabel: src ? '-' : '+',
      btnClick: () => tokenId ? onRemove(tokenId) : onAdd(),
    }
  }, [src, tokenId, onAdd, onRemove]);

  return (
    <Col>
      <InputGroup className="mb-3">
        {/* input field */}
        <Form.Control 
          disabled={config.disableInput} 
          value={value} 
          onChange={onInputChange} 
        />
        <InputGroup.Text>
          <Button 
            disabled={!Boolean(value)}
            variant={config.btnVariant} 
            onClick={config.btnClick}
          >
            {config.btnLabel}
          </Button>
        </InputGroup.Text>
      </InputGroup>
      {src && <Img src={src} />}
    </Col>
  )
}

/*
{
  "0x3550f13c8b3146d55c617a6b9ffa42d3ad091a51": {
    "1": {
      "title": "Fez Love",
      "author": {
        "pic": "https://media.pnk.one/site/author_troycaylak.jpg",
        "name": "Troy Caylak",
        "desc": "2-time SAG nominee, stars in HBO's Barry."
      }
    },
    "3": {
      "title": "Prison Rush",
      "author": {
        "pic": "https://media.pnk.one/site/author_brunodias.jpg",
        "name": "Bruno Dias",
        "desc": "Alien Awards 2018 finalist, Brazilian Jabuti Sci-FI 2019 Prize Finalist, Writer of “Antigen” Series and “Dr. Werneck Chronicles”."
      }
    }
  },
  "0xb147382ea31bec025f1ae7bcc4bb78626a8f2143": {
    "1": {
      "title": "Fez Love",
      "author": {
        "pic": "https://media.pnk.one/site/author_troycaylak.jpg",
        "name": "Troy Caylak",
        "desc": "2-time SAG nominee, stars in HBO's Barry."
      }
    },
    "2": {
      "title": "Prison Rush",
      "author": {
        "pic": "https://media.pnk.one/site/author_brunodias.jpg",
        "name": "Bruno Dias",
        "desc": "Alien Awards 2018 finalist, Brazilian Jabuti Sci-FI 2019 Prize Finalist, Writer of “Antigen” Series and “Dr. Werneck Chronicles”."
      }
    }
  }
}
*/