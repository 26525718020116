import { ChangeEvent, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { api } from '../../../../api/PMToolApi';
import { appSlice } from '../../../../state/appSlice';
import { getAssemblerProject, getAssemblerTokenId, getCanAssemble } from '../../../../state/selectors';
import { projectNames } from '../../../../../lib/consts';
import { colors } from '../../../../../lib/styles';
import { Dropdown } from '../../../../../components/Dropdown';
import { Col, Row, VGap, } from '../../../../../components/Layout';
import { Title } from '../../../../../components/Text';
import { useToast } from '../../../../../components/Toast';
import { getConfig } from '../../../../../Config';

const Container = styled(Col)`
  width: 50%;
  border-radius: 4px;
  border: 1px solid ${colors.grey};
  padding: 8px
`;

const Input = styled.input`
  margin-right: 8px;
  border-radius: 4px;
  border: 1px solid ${colors.grey};
`;

export const AssemblerInput = () => {
  const dispatch = useDispatch();

  const tokenId = useSelector(getAssemblerTokenId);

  const [project, setProject] = useState('');

  const [projects, setProjects] = useState<Record<string, string>>();

  const canAssemble = useSelector(getCanAssemble);

  const { success, error } = useToast();


  // Load projects dropdown from config
  useEffect(() => {
    const fetchProjects = async() => {
      const sData = await api.getCPContracts();


      const _projects = Object.values(sData)
        .reduce((res, cur) => {

          const { tokenName, holdingContracts } = cur;

          const key = tokenName.replace('Storyverse | ', '');
          const contract = holdingContracts[0];

          return {
            ...res,
            [key]: contract,
          }
        }, {});
      setProjects(_projects);
      setProject(Object.keys(_projects)[0]);
    }
    fetchProjects();
  }, []);

  const onInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
    dispatch(appSlice.actions.setAssemblerTokenId(evt.target.value));
  }

  const onDropdownChange = (value: string) => {
    setProject(value);
  }

  const onAssembleClick = async() => {
    dispatch(appSlice.actions.suspend());
    try {
      const charData = await api.assembleCharacter((projects || {})[project]);
      dispatch(appSlice.actions.addAssembledChar(charData));
      success(`Assembler character added successfully!`);
    } catch(e) {
      error(`Error: Could not assemble ${project} '#${tokenId}'`);
    }   
    dispatch(appSlice.actions.resume());
  }
  
  return (
    <Container>
        <Title>Add Assembler Character</Title>
        <Row>
          <Col>
            <Dropdown
              value={project}
              onChange={onDropdownChange}
              items={projects ? Object.keys(projects) : []}
              title='Select Project'
            />
            <VGap size={4} />
            Token Id
            <Input value={tokenId} onChange={onInputChange} />
          </Col>
          <Button 
            variant='warning' 
            disabled={!tokenId || !canAssemble}
            onClick={onAssembleClick}
          >
            Assemble
          </Button>
        </Row>
    </Container>
  )
}
