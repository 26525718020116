import { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Col, Row, VGap } from '../../../../components/Layout';
import { TextInput } from '../../../../components/TextInput';
import { adminSlice } from '../state/adminSlice';
import { getTemplateAddress, getTemplateName } from '../state/selectors';

const Container = styled(Col)`

`;

const BtnContainer = styled(Row)`
  justify-content: flex-end;
`;

const SubmitBtn = styled(Button)`
  height: 100%;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
`;

interface Props {
  onSubmit: () => void;
}

export const TemplateAddressInput = ({ onSubmit }: Props) => {

  const dispatch = useDispatch();

  const templateName = useSelector(getTemplateName);
  const templateAddress = useSelector(getTemplateAddress);

  const onTemplateAddressChange = useCallback((value: string) => {
    dispatch(adminSlice.actions.setTemplateAddress(value));
  }, []);

  const onTemplateNameChange = useCallback((value: string) => {
    dispatch(adminSlice.actions.setTemplateName(value));
  }, []);

  return (
    <Container>
      <TextInput 
        label='Project Name'
        value={templateName}
        onChange={onTemplateNameChange}
      />
      <VGap size={12} />
      <TextInput 
        label='Project Address'
        value={templateAddress}
        onChange={onTemplateAddressChange}
      />
      <VGap size={24} />
      <BtnContainer>
        <SubmitBtn 
            disabled={!templateName || !templateAddress} 
            onClick={onSubmit}
            variant='success'
          >
            Create
        </SubmitBtn>
      </BtnContainer>
    </Container>
  )
}