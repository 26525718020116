import { CharData } from '@storyverseco/svs-types';
import { nftProjects } from '@storyverseco/svs-consts';

// https://sverse-character-storage-dev.s3.amazonaws.com/assembler/0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d/chars/1010/metadata.json?v=15
export const assemblerUrlToCharData = (url: string): CharData => {
  const assemblerToTokenId = url.substring(
    url.indexOf('assembler/'),
    url.indexOf('/metadata')
  );
  const [, projectAddress, , tokenId] = assemblerToTokenId.split('/');

  const name = `${
    nftProjects.find((p) => p.address === projectAddress)?.name
  } #${tokenId}`;

  return {
    metadataUrl: url,
    name,
  };
};

export const getAssemblerCharDataForUrls = (urls: string[]): CharData[] => {
  const assemblerUrls = urls.filter((url) => url.includes('assembler/'));

  return assemblerUrls.map(assemblerUrlToCharData);
};
