import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Dropdown } from '../../../../components/Dropdown';
import { Col, HGap, Row, VGap } from '../../../../components/Layout';
import { TextInput } from '../../../../components/TextInput';
import { User } from '../../../../lib/SvsAcl';
import { useACL, useSVSCognito } from '../../../hooks/useSVSCognito';
import { ListPicker } from '../../../../components/ListPicker';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';
import { Button } from 'react-bootstrap';
import { Modal } from '../../../../components/Modal';
import { useToast } from '../../../../components/Toast';

const Container = styled(Row)`
  justify-content: flex-end;
  padding-top: 16px;
`;

const RightContainer = styled(Col)`
`;

const BtnsContainer = styled(Row)`
  align-items: flex-end;
  justify-content: space-evenly;
`;


interface Props {
  user: User;
}

export const UserForm = ({ user }: Props) => {
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email || '');
  const [wallets, setWallets] = useState(user.wallets);
  const [groupName, setGroupName] = useState(user.group.name);
  // data?: Record<string, string>;

  const [showModal, setShowModal] = useState(false);

  const { copy } = useCopyToClipboard();

  const { error } = useToast();

  const { getGroup, getGroups } = useACL();
  const { updateUser, deleteUser, } = useSVSCognito();

  const onWalletClick = (address: string) => {
    copy(address);
  }

  // isDirty
  const saveEnabled = name !== user.name || email !== (user.email || '') || groupName !== user.group.name;

  const onSave = () => {
    const group = getGroup(groupName);

    if (!group) {
      error(`Cannot add user. 'Group' not found.`);
      return;
    }

    updateUser({
      ...user,
      walletAddress: wallets[0],
      name,
      email,
      wallets,
      group,
    });
  }

  const onDelete = () => {
    setShowModal(true);
  }

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const onDeleteConfirm = () => {
    closeModal();
    deleteUser({walletAddress: user.wallets[0]});
  }

  return (
    <Container>
      <Col>
        <TextInput 
          label='Name'
          value={name}
          onChange={setName}
        />
        <TextInput 
          disabled
          label='Type'
          value={user.type}
          onChange={() => {}}
        />
        <TextInput 
          label='Email'
          value={email}
          onChange={setEmail}
        />
        <Dropdown
          title='Group'
          value={groupName}
          items={getGroups().map(g => g.name)}
          onChange={setGroupName}
        />
      </Col>
      <HGap size={8} />
      <RightContainer>
        <Col flex={6.5}>
          <ListPicker 
            title='Wallet Addresses'
            items={
              wallets.map(address => ({
                id: address,
                value: address,
              }))
            }
            onItemClick={onWalletClick}
            disabled={false}
            fullWidth
          />
        </Col>
        <BtnsContainer>
          <Button variant='success' onClick={onSave} disabled={!saveEnabled}>Save Changes</Button>
          <Button variant='danger' onClick={onDelete}>Delete User</Button>
          {/* <Button variant='info' onClick={onAddClick} disabled={!saveEnabled}>Sync Envs</Button> */}
        </BtnsContainer>
      </RightContainer>
      <Modal
        title={`Delete User`}
        show={showModal}
        onClose={closeModal}
        submitBtn={{
          onPress: onDeleteConfirm,
          text: 'Confirm',
          variant: 'primary'
        }}
        cancelBtn={{
          onPress: closeModal,
          text: 'Close',
          variant: 'info'
        }}
      >
       Are you sure you want to delete '{name}'?
      </Modal>
    </Container>
  )
}