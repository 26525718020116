import styled from "styled-components";
import { Main } from "../../layout/Main";
import { Col, HGap, Row } from "../../../components/Layout";
import { Accordion } from "../../../components/Accordion";
import { TextInput } from "../../../components/TextInput";
import { useMemo, useEffect, useState, useCallback } from "react";
import { api } from "../../api/PMToolApi";
import { useDispatch } from "react-redux";
import { appSlice } from "../../state/appSlice";
// import {JsonViewer} from '@textea/json-viewer';
import JsonEditor from 'react-json-editor-ui'
import 'react-json-editor-ui/dist/react-json-editor-ui.cjs.development.css'
import { useToast } from "../../../components/Toast";
import { isEqual } from "../../../lib/utils";
import { Button } from "react-bootstrap";
import { useSVSCognito } from "../../hooks/useSVSCognito";
import { useLogin } from "../../hooks/useLogin";
import { WalletAddress } from "@storyverseco/svs-types";
import { assets } from "../../../assets";
import { ImageUploader } from "../../../components/ImageUploader";
import { getFormToSaleData, getSaleToFormData } from "./getFormToSaleData";
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { Title } from "../../../components/Text";
import { useParams } from "react-router-dom";
import { Routes, useNavigation } from "../../navigation/useNavigation";
import { getConfig } from "../../../Config";

const ControlsContainer = styled(Row)`
  margin-bottom: 16px;
`;

const AccordionRow = styled(Row)`
  justify-content: space-between;
  padding: 0px 12px;
`;

const AccordionContainer = styled(Row)`
  overflow-y: scroll;
  padding: 0px 8px;
  .accordion {
    width: 100%;
  }
`;

const TemplateContainer = styled.div`
  position: relative;
  // background: url('${assets.rafflePageTemplate}');
`;

const TemplateImage = styled.img`
  width: 100%;
`;

const AbsDiv = styled.div`
  position: absolute;
`;

const Uploaders = styled(Row)`
  justify-content: space-between;
`;

const BtnsContainer = styled(Row)`
  justify-content: flex-end;
`

const Form = styled(Col)`
  overflow-y: auto;
  max-height: 70vh;
`;

const formConfig = [
  {
    label: 'Story name',
    key: 'saleName',  
  },
  {
    label: 'Collection id',
    key: 'saleId',
    info: `(Example.: 'svsfl' for Fez Love)`
  },
  {
    label: 'Header',
    key: 'saleHeaderTitle'
  },
  {
    label: 'Subheader',
    key: 'saleHeaderSubTitle'
  },
  {
    label: 'Vimeo URL',
    key: 'saleMedia.vimeoUrl',  
  },
  {
    label: 'Story description',
    key: 'saleDescription',  
  },
  {
    label: 'Star info',
    key: 'starInfo',  
  },
  {
    label: 'Twitter handles',
    key: 'followSocials',
    info: '(Comma separated)'  
  },
  {
    label: 'Writer name',
    key: 'writer.name'
  },
  {
    label: 'Writer bio',
    key: 'writer.bio',  
  },
];

const formKeys = formConfig.map(f => f.key);

const getTomorrow = () => (new Date()).setDate(new Date().getDate()+1);

const wait2Minutes = () => new Promise(resolve => setTimeout(resolve, 1000*60*2));

const getProposedSaleId = (saleName: string, prevAttempt?: string) => {
  const nameParts = saleName.split(' ');
  const lettersToInclude = prevAttempt ? prevAttempt.length - 3 : 1;
  const firstLetters = nameParts.map((p: string) => p.substring(0, lettersToInclude));

  const proposedId = `svs${firstLetters.join('').toLowerCase()}`;

  return proposedId;
}


export const RafflePageEditor = () => {

  const dispatch = useDispatch();

  const { success, error } = useToast();

  const { navigate } = useNavigation();

  const { cognitoReady, getUser } = useSVSCognito();
  const { wallet } = useLogin('raffleEditor');

  const [writerAvatarImage, setWriterAvatarImage] = useState<File>();
  const [bannerImage, setBannerImage] = useState<File>();

  const [startDate, setStartDate] = useState<Date | null>(new Date());

  const [endDate, setEndDate] = useState<Date | null>(new Date(getTomorrow()));

  const [formData, setFormData] = useState<any>({});

  const [featured, setFeatured] = useState(false);

  const { saleId } = useParams();

  // If we have a saleId we are editing an existing sale (or that is the wrong value)
  useEffect(() => {
    if (!saleId) {
      // Reset form
      setFormData({});
      setWriterAvatarImage(undefined);
      setBannerImage(undefined);
      setFeatured(false);
      return;
    }

    const loadSaleData = async() => {
      const saleData: Record<string, any> = (await getConfig()).saleData;
      if (saleData) {
        const mySale = saleData[saleId];
        const formData = getSaleToFormData(mySale);

        setFormData(formData);
        setStartDate(new Date(formData.startDate));
        setEndDate(new Date(formData.endDate));
        setFeatured(formData.featured);
      }
    }

    loadSaleData();
  }, [saleId]);

  const onFormUpdate = useCallback((field: string) => {
    return (value: string) => {
      setFormData({
        ...formData,
        [field]: value,
      });
    }
  }, [formData]);

  const canSave = (bannerImage || formData.bannerUrl) && (writerAvatarImage || formData.avatarUrl) && formKeys.every(k => formData[k]);

  const onSave = async() => {
    if (!(writerAvatarImage || formData.avatarUrl) || !(bannerImage || formData.bannerUrl) || !wallet || !cognitoReady) {
      console.log(`Missing one of the following. Wont save`, {
        writerAvatarImage,
        avatarUrl: formData.avatarUrl,
        bannerImage,
        bannerUrl: formData.bannerUrl,
        wallet,
        cognitoReady,
      })
      return;
    }
    dispatch(appSlice.actions.suspend());
    try {
      let avatarUrl = formData.avatarUrl;
      let bannerUrl = formData.bannerUrl;

      // first get the sale data so we can add the collection to it
      const saleData: Record<string, any> = (await getConfig()).saleData;

      // Cannot create new sale with existing saleId
      if (!saleId && saleData[formData.saleId]) {
        onFormUpdate('saleId')(getProposedSaleId(formData.saleName, formData.saleId));
        throw new Error(`Cannot create new Raffle with ID '${formData.saleId}'. ID Already exists.`);
      }

      if (writerAvatarImage) {
        // Save writer avatar image
        const avatarImageName = `${formData.saleId}_writer_avatar`;
        avatarUrl = await api.uploadFileWithSignedPost({ file: writerAvatarImage, path: 'site', filename: avatarImageName });
        success(`Writer avatar image uploaded successfully!`);
      }
      // Save banner image
      if (bannerImage) {
        const bannerImageName = `${formData.saleId}_banner`;
        bannerUrl = await api.uploadFileWithSignedPost({ file: bannerImage, path: 'site', filename: bannerImageName });
        success(`Writer avatar image uploaded successfully!`);
      }
      // Save sale data
      

      const newCollectionSaleData = getFormToSaleData({...formData, avatarUrl, bannerUrl, startDate: startDate?.toISOString(), endDate: endDate?.toISOString(), featured: featured});
      const data = await api.updateSaleData({ 
        saleData: {
          ...saleData,
          ...newCollectionSaleData,
        },
      });
      success(`Sale '${formData.saleName}' add successfully!`);

    } catch (e: any) {
      error(e.message);
    }

    dispatch(appSlice.actions.resume());
  }

  const pageTitle = useMemo(() => `${saleId ? 'Update' : 'Create'} Raffle`, [saleId]);

  const toggleFeatured = useCallback(() => {
    setFeatured(!featured);
  }, [featured]);

  const onDelete = useCallback(async() => {
    if (!saleId || !cognitoReady || !wallet?.address) {
      return;
    }

    dispatch(appSlice.actions.suspend());
    try {
      let saleData: any = (await getConfig()).saleData;
      if (saleData[saleId]) {
        delete saleData[saleId];
        await api.updateSaleData({ saleData });
      }
      dispatch(appSlice.actions.resume());
      success(`Raffle '${saleId}' has been deleted.`);
      navigate(Routes.CONFIG_RAFFLE_PAGE);
    }  catch (e: any) {
      error(e.message);
      dispatch(appSlice.actions.resume());
    }
  }, [saleId, cognitoReady, wallet?.address]);

  useEffect(() => { 
    // Do not do this for "edit" mmode
    if (saleId) {
      return;
    }
    
    if (formData.saleName) {
      onFormUpdate('saleId')(getProposedSaleId(formData.saleName));
    } else {
      onFormUpdate('saleId')('');
    }
  }, [formData.saleName, saleId]);

  // @TODO: Add this to `<Main />`
  if (!cognitoReady) {
    return null;
  }

  return (
    <Main
      title={pageTitle}
      subtitle='Edit Raffle data in config'
    >
      <Form>
        {
          formConfig.map(field => (
            <TextInput
              disabled={Boolean(field.key === 'saleId')}
              key={field.label}
              label={field.label}
              info={field.info}
              value={formData[field.key] || ''}
              onChange={onFormUpdate(field.key)} 
              center={false}
            />
          ))
        }
        <Uploaders>
          <ImageUploader
            label='Writer Avatar'
            id='writer-avatar'
            onFileChange={setWriterAvatarImage}
            previewUrl={formData.avatarUrl}
          />
          <ImageUploader
            label='Home page banner'
            id='writer-avatar'
            onFileChange={setBannerImage}
            previewUrl={formData.bannerUrl}
          />
        </Uploaders>
        <Title>Raffle Start Date</Title>
        <DateTimePicker
          clearIcon={null}
          amPmAriaLabel='Select AM/PM'
          calendarAriaLabel='Toggle calendar'
          clearAriaLabel='Clear value'
          dayAriaLabel='Day'
          hourAriaLabel='Hour'
          maxDetail='second'
          minuteAriaLabel='Minute'
          monthAriaLabel='Month'
          nativeInputAriaLabel='Date and time'
          secondAriaLabel='Second'
          yearAriaLabel='Year'
          onChange={setStartDate}
          value={startDate}
        />
        <Title>Raffle End Date</Title>
        <DateTimePicker
          clearIcon={null}
          amPmAriaLabel='Select AM/PM'
          calendarAriaLabel='Toggle calendar'
          clearAriaLabel='Clear value'
          dayAriaLabel='Day'
          hourAriaLabel='Hour'
          maxDetail='second'
          minuteAriaLabel='Minute'
          monthAriaLabel='Month'
          nativeInputAriaLabel='Date and time'
          secondAriaLabel='Second'
          yearAriaLabel='Year'
          onChange={setEndDate}
          value={endDate}
        />
        <TextInput
          label='Featured?'
          info='(Show in caroussel)'
          value={''}
          onChange={toggleFeatured}
          type='checkbox'
          // center?: boolean;
          checked={featured}
        />
      </Form>
      <hr />
      <BtnsContainer clear>
          {saleId && <Button variant="danger" disabled={!saleId} onClick={onDelete}>Delete Raffle</Button>}
          {saleId && <HGap size={120}/>}
          <Button variant="success" disabled={!canSave} onClick={onSave}>Save</Button>
      </BtnsContainer>
    </Main>
  );
}