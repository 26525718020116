import { useEffect, useState } from "react";
import styled from "styled-components";
import { Col, HGap, Row, VGap } from "../../../../components/Layout";
import { TextInput } from "../../../../components/TextInput";
import { Button } from "react-bootstrap";
import { useToast } from "../../../../components/Toast";
import { useDispatch } from "react-redux";
import { appSlice } from "../../../state/appSlice";
import { api } from "../../../api/PMToolApi";
import { Main } from "../../../layout/Main";

const InputsContainer = styled(Row)`

`;

enum WriterState {
  Idle,
  Exists,
  Create,
}

export const CreateStoryNFT = () => {

  const [walletAddress, setWalletAddress] = useState('');

  const [storyName, setStoryName] = useState('');

  const [authorName, setAuthorName] = useState('');

  const [storyLink, setStoryLink] = useState('');

  const { success, error, warning } = useToast();

  const dispatch = useDispatch();

  const [writers, setWriters] = useState<Record<string, string>>();

  const [requireNewWriter, setRequireNewWriter] = useState(WriterState.Idle);


  useEffect(() => {
    const fetchWriters = async() => {
      try {
        const response = await api.getNFTWriters();
        setWriters(response);
      } catch {}
    }

    fetchWriters();
  }, []);

  const onCreate = async() => {
    dispatch(appSlice.actions.suspend());
    try {
      let w = writers || (await api.getNFTWriters());

      console.log('FOOO');

      if (requireNewWriter === WriterState.Idle) {
        const exists = w[walletAddress];
        console.log({
          exists,
          w, 
          walletAddress,
        })

        if (exists) {
          setRequireNewWriter(WriterState.Exists);
        } else {
          setRequireNewWriter(WriterState.Create);
          warning(`Writer not found. Create new writer by giving a name.`);
          dispatch(appSlice.actions.resume());
          return;
        }
      }

      if (requireNewWriter === WriterState.Create) {
        await api.updateNFTWriters({
          writers: {
            ...w,
            [walletAddress]: authorName,
          }
        })
      }

      const url = await api.createNFTStory({walletAddress: walletAddress.toLocaleLowerCase(), name: storyName});
      setStoryLink(url);
      setRequireNewWriter(WriterState.Idle);
      success(`NFT Story ${storyName} has been created!`);
    } catch (e: any) {
      error(e.message);
    }
    dispatch(appSlice.actions.resume());

  }

  const saveDisabled = !walletAddress || !storyName || (requireNewWriter === WriterState.Create && !authorName);

  return (
    <Main
      title='Collectible Stories'
      subtitle='Create new `user` collectible story'
    >
      <InputsContainer clear>
        <TextInput 
          disabled={requireNewWriter !== WriterState.Idle}
          label='Story Name'
          value={storyName}
          onChange={setStoryName}
        />
        <HGap size={8} />
        <TextInput 
          disabled={requireNewWriter !== WriterState.Idle}
          label='Author Wallet Address'
          value={walletAddress}
          onChange={setWalletAddress}
        />
        {requireNewWriter === WriterState.Create && (
          <TextInput 
            label='Author Name'
            value={authorName}
            onChange={setAuthorName}
          />
        )}
      </InputsContainer>
      <VGap size={8} />
      <Button variant='success' onClick={onCreate} disabled={saveDisabled}>Create Story</Button>
      <VGap size={8} />
      { storyLink && (
          <TextInput 
            disabled
            label='Story Link'
            value={storyLink}
            onChange={() => {}}
          /> 
      )}
    </Main>
  );
}