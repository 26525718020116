import React from 'react';
import { ChangeEvent, useState } from 'react';

interface Props {
  id: string;
  onFileUploaded: (file: File) => void;
  accept: string;
}

export const Uploader = React.forwardRef<HTMLInputElement, Props>(({ 
  id, 
  onFileUploaded, 
  accept  
}, ref) => {
  const [value, setValue] = useState('');

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files;

    if (!files) {
      console.error(`Error: no filed uploaded.`);
      return;
    }

    if (files.length > 1) {
      console.error(`Error: Only 1 file upload supported.`);
      return;
    }

    onFileUploaded(files[0]);
    setValue('');
  };

  return (
    <label htmlFor={id}>
      <input
        ref={ref}
        accept={accept}
        type="file"
        value={value}
        id={id}
        onChange={onChange}
        style={{ display: 'none' }}
      />
    </label>
  );
});
