export const colors = {
  black: '#4c4c4c',
  grey: '#b0aeae',
  lightGrey: '#ebebeb',
  lighterGrey: '#fafafa',
  ventaBlack: 'black',
  white: 'white',   
  success: '#10a910',
  warning: '#ebc74a',
  danger: '#df1b1b',
  purple: '#7848ff',
  darkBlue: 'rgb(24, 3, 63);',
}