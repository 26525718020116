import styled from 'styled-components';
import { Row, Col, HGap } from './Layout';
import { Title } from './Text';
import { colors } from '../lib/styles';
import { ChangeEvent, CSSProperties } from 'react';

const Container = styled(Col)`
  max-height: 80px;
`;  

const Input = styled.input<{
  center: boolean;
}>`
  width: 100%;
  height: 44px;
  text-align: ${({center}) => center ? 'center' : 'initial'};
  border-radius: 8px;
  border: 1px solid ${colors.grey};
  padding-left: 8px;
`;

const InputContainer = styled(Row)`
  width: 100%;
`;

const InfoText = styled(Title)`
  font-size: 14px;
  color: ${colors.grey};
`;

interface Props {
  label?: string;
  info?: string;
  value: string;
  onChange: (value: string) => void;
  style?: CSSProperties;
  type?: React.HTMLInputTypeAttribute;
  disabled?: boolean;
  center?: boolean;
  checked?: boolean;
}

export const TextInput = ({ 
  label,
  onChange,
  value,
  style,
  type,
  disabled,
  checked,
  center = true,
  info,
}: Props) => {
  const onWalletAddressChange = (evt: ChangeEvent<HTMLInputElement>) => {
    onChange(evt.target.value);
  }

  return (
    <Container center={center}>
      <Row clear centerV>
        {label && <Title bold>{label}</Title>}
        <HGap size={12} />
        {info && <InfoText>{info}</InfoText>}
      </Row>
      <InputContainer>
        <Row>
          <Input 
            center={center}
            checked={checked}
            disabled={disabled}
            style={style}
            value={value}
            type={type}
            onChange={onWalletAddressChange}
          />
        </Row>
      </InputContainer>
    </Container>
  )
}