import { useSelector, useDispatch } from 'react-redux';
import { getAdminWalletAddress } from '../../../state/selectors';
import { appSlice } from '../../../state/appSlice';
import { WalletAddressInput } from '../../../../components/WalletAddressInput';

interface Props {
  onSubmit: () => void;
}

export const AdminWalletAddressInput = ({ onSubmit }: Props) => {

  const dispatch = useDispatch();

  const walletAddress = useSelector(getAdminWalletAddress);

  const onInputChange = (value: string) => {
    dispatch(appSlice.actions.setAdminWalletAddress(value));
  }

  return (
    <WalletAddressInput
      onSubmit={onSubmit}
      value={walletAddress}
      onChange={onInputChange}
      submitText='Create'
      submitVariant='success'
    />
  )
}