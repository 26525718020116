import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { appSlice } from './appSlice';
import { adminSlice } from '../pages/admin/state/adminSlice';
import { templatesSlice } from '../pages/templates/state/templatesSlice';
import { createTemplateSlice } from '../CreateTemplateForm/state/createTemplateSlice';
import { storySlice } from '../pages/story/state/storySlice';

const reducer = combineReducers({
  app: appSlice.reducer,
  admin: adminSlice.reducer,
  templates: templatesSlice.reducer,
  createTemplate: createTemplateSlice.reducer,
  story: storySlice.reducer,
});

export const store = configureStore({
  reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
