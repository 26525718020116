import { useCallback, useState } from "react";
import styled from "styled-components";
import { Col, HGap, Row, VGap } from "../../../../components/Layout";
import { TextInput } from "../../../../components/TextInput";
import { Button } from "react-bootstrap";
import { useToast } from "../../../../components/Toast";
import { useDispatch, useSelector } from "react-redux";
import { appSlice } from "../../../state/appSlice";
import { api, getParams } from "../../../api/PMToolApi";
import { Textarea } from "../../../../components/Textarea";
import { Dropdown } from "../../../../components/Dropdown";
import { Main } from "../../../layout/Main";
import { endpoints } from "./postmanConfig";
import { request } from "../../../api/api";
import { PMToolEndpoints } from "@storyverseco/svs-types";
import { getEnv } from "../../../../lib/getEnv";

const Container = styled(Col)`

`;

const InputsContainer = styled(Row)`

`;

const TextareaContainer = styled(Row)`
  
`;

const EnvContainer = styled(Row)`
  padding: 0px 12px;
`;

const prettifyFile = (jsonStr: string) => JSON.stringify(JSON.parse(jsonStr), null, 2);

const envs = ['dev', 'staging', 'prod'];




export const Postman = () => {

  const stateEnv = getEnv() as string;

  const [env, setEnv] = useState(stateEnv);

  const [useCurrentCredentials, setUserCurrentCredentials] = useState(true);

  const [signature, setSignature] = useState('');

  const [walletAddress, setWalletAddress] = useState('');

  const [endpoint, setEndpoint] = useState(endpoints[0]);

  const [body, setBody] = useState('');

  const [response, setResponse] = useState('');

  const [inFlight, setInFlight] = useState(false);

  const { success, error } = useToast();

  const dispatch = useDispatch();


  const onSave = async() => {
    dispatch(appSlice.actions.suspend());
    
    // validate body
    try {
      if (body) {
        JSON.parse(body);
      }
    } catch {
      error('Failed to validate request body. Fix your JSON');
      dispatch(appSlice.actions.resume());
      return;
    }

    if (!useCurrentCredentials) {
      if (!signature || !walletAddress) {
        error(`Cannot make non creds request without 'signature' and 'walletAddress'.`);
        return;
      }
    }

    setInFlight(true);

    const requestBody = body ? JSON.parse(body) : {};

    const preParams: any = {
      env,
      ...requestBody
    }

    if (!useCurrentCredentials) {
      preParams.auth = {
        signature,
        walletAddress,
      }
    }

    console.log({preParams});

    try {
      const response = await request(endpoint as PMToolEndpoints, getParams(preParams));
      success(`Request to '${endpoint}' success`);
      let outcome: any;
      console.log(response);
      try {
        outcome = await response.json();
        outcome = JSON.stringify(outcome, null, 2);
        setResponse(outcome);
      } catch {
        try {
          outcome = await response.text();
          setResponse(outcome);
        } catch {
          setResponse('Error!');
          throw new Error(`Failed to decode response`);
        }
      }
    } catch (e: any) {
      error(e.message);
    } 
    
    setInFlight(false);
    dispatch(appSlice.actions.resume());
  }

  const onToggleCreds = useCallback(() => {
    setUserCurrentCredentials(!useCurrentCredentials);
  }, [useCurrentCredentials]);

  



  return (
    <Main
      title='PMTool API Postman'
      subtitle='Make API requests with ease'
    >
      <InputsContainer clear>
        <Col>
          <TextInput 
            type='checkbox' 
            checked={useCurrentCredentials} 
            label='Use loggedIn credentials?'
            onChange={onToggleCreds} 
            // not used in checkbox
            value=''
          />
          <VGap size={12} />
          <EnvContainer clear>
            <Dropdown 
              items={envs} 
              title='Env'
              value={env}
              onChange={setEnv}
            />
          </EnvContainer>
        </Col>
        <Col>
          <TextInput 
            label='Signature'
            value={signature}
            onChange={setSignature}
            disabled={useCurrentCredentials}
          />
          <VGap size={12} />
          <TextInput 
            label='Wallet Address'
            value={walletAddress}
            onChange={setWalletAddress}
            disabled={useCurrentCredentials}
          />
        </Col>
      </InputsContainer>
      <hr />
      <VGap size={8} />
      <TextareaContainer>
        <Col>
          <Dropdown 
            clear
            items={endpoints} 
            title='Endpoint'
            value={endpoint}
            onChange={setEndpoint}
          />
          <Textarea 
            label='Body'
            onChange={setBody}
            value={body}
          />
          <VGap size={12} />
          <Button variant='success' onClick={onSave} disabled={inFlight}>Request</Button>  
        </Col>
        <HGap size={12} />
        <Col>
          <Textarea 
            label='Response'
            onChange={setResponse}
            value={response}
          />
        </Col>

      </TextareaContainer>
    </Main>
  );
}