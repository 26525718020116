import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../../components/Toast";
import { api } from "../api/PMToolApi";
import { appSlice } from "../state/appSlice";
// import { } from '@storyverseco/svs-cognito'
import { CreateUserOpts, Crud, DeleteUserOpts, SVSACL, SVSCognito, UpdateUserOpts, UserType } from '@storyverseco/svs-cognito';
import { useLogin } from "./useLogin";
import { WalletAddress } from "@storyverseco/svs-types";

let cognito: SVSCognito;
export const useSVSCognito = () => {
  if (!cognito) {
    cognito = new SVSCognito({
      fetchUsers: api.getUsers,
      updateUsers: api.updateUsers,
    });
  }

  const [_next, _setNext] = useState(0);

  const next = () => {
    _setNext(_next+1);
  }

  const dispatch = useDispatch();

  const { success, error } = useToast();

  const [isReady, setIsReady] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { wallet } = useLogin('cognito');

  // We need to wait for the wallet from login so we can make the auth request to get users afterwards
  useEffect(() => {
    if (isReady || cognito.isInit || !wallet || isLoading) {
      return;
    }

    setIsLoading(true);

    const loadCognito = async() => {
      await cognito.init();
      console.log('loadCognito', cognito);
      setIsReady(true);
      setIsLoading(false);
    }

    loadCognito();
  }, [isReady, wallet, isLoading]);

  const createUser = async(opts: CreateUserOpts) => {
    if (!cognito.isInit) {
      return;
    }

    dispatch(appSlice.actions.suspend());
    try {
      await cognito.createUser(opts);
      success(`User '${opts.name}' created successfully!`);
      next();
    } catch(e: any) {
      error(e.message);
    }
    dispatch(appSlice.actions.resume());
  }

  const updateUser = async(opts: UpdateUserOpts) => {
    if (!cognito.isInit) {
      return;
    }

    dispatch(appSlice.actions.suspend());
    try {
      await cognito.updateUser(opts);
      success(`User '${opts.name}' updated successfully!`);
      next();
    } catch(e: any) {
      error(e.message);
    }
    dispatch(appSlice.actions.resume());
  }

  const deleteUser = async(opts: DeleteUserOpts) => {
    if (!cognito.isInit) {
      return;
    }

    dispatch(appSlice.actions.suspend());
    try {
      const user = cognito.getUser(opts);
      await cognito.deleteUser(opts);
      success(`User '${user?.name}' deleted successfully!`);
      next();
    } catch(e: any) {
      error(e.message);
    }
    dispatch(appSlice.actions.resume());
  }

  const getUser = (opts: {walletAddress: WalletAddress}) => {
    if (!cognito.isInit) {
      return;
    }

    try {
      const user = cognito.getUser(opts);
      return user;
    } catch(e: any) {
      error(e.message);
    }
  }

  const getUsers = () => {
    if (!cognito.isInit) {
      return [];
    }

    return cognito.users;
  }

  // Don't expose 'cognito' directly. This hooks is our layer around it
  return {
    getUsers,
    getUser,
    createUser,
    updateUser,
    deleteUser,
    cognitoReady: isReady || (!isLoading && cognito.isInit),
  }
}


/**
 * =============== ACL ==============
 */

enum ACLState {
  Idle,
  Loading,
  Ready,
}

let acl: SVSACL;
interface ACLProps {
}
export const useACL = (opts?: ACLProps) => {
  if (!acl) {
    acl = new SVSACL({
      fetchPermissions: api.getPermissions,
      fetchGroups: api.getGroups,
      updatePermissions: api.updatePermissions,
      updateGroups: api.updateGroups,
    });
  }

  const [_next, _setNext] = useState(0);

  const next = () => {
    _setNext(_next+1);
  }

  const dispatch = useDispatch();

  const { success, error } = useToast();

  const [state, setState] = useState(ACLState.Idle);

  const { wallet } = useLogin('cognito');

  // We require wallet to make the auth request when init acl
  useEffect(() => {
    if (acl.isInit || state !== ACLState.Idle || !wallet) {
      return;
    }

    setState(ACLState.Loading);

    const loadAcl = async() => {
      await acl.init();
      setState(ACLState.Ready);
      next();
    }

    loadAcl();
  }, [wallet, state]);

  const request = async(promise: Promise<any>, successMessage: string) => {
    if (state !== ACLState.Ready) {
      console.warn(`Trying to make request before hook ready`);
      return;
    }

    dispatch(appSlice.actions.suspend());
    try {
      await promise;
      success(successMessage);
      next();
    } catch(e: any) {
      error(e.message);
    }
    dispatch(appSlice.actions.resume());
  }

  const addPermission = async(name: string) => {
    await request(
      acl.addPermission(name),
      `Permission '${name}' added successfully!`
    );
  }

  const deletePermission = async(name: string) => {
    await request(
      acl.deletePermission(name),
      `Permission '${name}' deleted successfully!`
    );
  }

  const createGroup = async(name: string) => {
    await request(
      acl.createGroup(name),
      `Group '${name}' created successfully!`
    );
  }

  const deleteGroup = async(name: string) => {
    await request(
      acl.deleteGroup(name),
      `Group '${name}' deleted successfully!`
    );
  }

  const updateGroupPermissions = async(groupName: string, permissionName: string, crud: Crud) => {
    await request(
      acl.editGroupPermission(groupName, permissionName, crud),
      `Group '${groupName}/${permissionName}' updated successfully!`
    );
  }

  const getGroups = () => {
    if (state !== ACLState.Ready) {
      return [];
    }
    return acl.groups;
  }

  const getPermissions = () => {
    if (state !== ACLState.Ready) {
      return [];
    }
    return acl.permissions;
  }

  const getGroupsWithPermission = (name: string) => {
    const groupList: string[] = [];

    getGroups().forEach(g => {
      if (g.permissions.find(p => p.name === name)) {
        groupList.push(g.name);
      }
    })

    return groupList;
  }

  const getGroup = (name: string) => {
    if (state !== ACLState.Ready) {
      return undefined;
    }
    return acl.getGroup(name)
  }

  return { 
    aclReady: state === ACLState.Ready,
    acl,
    getPermissions,
    addPermission,
    deletePermission,
    getGroups,
    createGroup,
    deleteGroup,
    getGroup,
    updateGroupPermissions,
    getGroupsWithPermission,
  };
}