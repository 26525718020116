import styled from 'styled-components';
import { Col, Row } from './Layout';
import { colors } from '../lib/styles';
import { ChangeEvent, CSSProperties } from 'react';
import { Title } from './Text';

const Container = styled(Col)`
  
`;

const Content = styled.select`
  width: 100%;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 8px;
  border: 1px solid ${colors.grey};
`;

interface Props {
  title?: string;
  value?: string;
  onChange: (value: string) => void;
  items: string[];
  style?: CSSProperties;
  clear?: boolean;
}

export const Dropdown = ({
  value,
  onChange,
  items,
  title,
  style,
  clear,
}: Props) => {
  const onSelect = (evt: ChangeEvent<HTMLSelectElement>) => {
    onChange(evt.target.value);
  }

  return (
    <Container clear={clear}>
      <Title bold center>{title}</Title>
      <Content value={value} onChange={onSelect} style={style}>
        {
          items.map((item, i) => (<option key={`${i}:${item}`}>{item}</option>))
        }
      </Content>
    </Container>
  )
}

