import { useSelector, useDispatch } from 'react-redux';
import { appSlice } from '../../../../state/appSlice';
import { getBgsPool, getUserBgs } from '../../../../state/selectors';
import { PickerData } from '../../../../../lib/types';
import { Col } from '../../../../../components/Layout';
import { Picker } from "../../../../../components/Picker"
import { PickerImgItem } from '../../../../../components/PickerImgItem';
import { Title } from '../../../../../components/Text';

const BASE_URL_TEMP = `https://svs-stories-storage-dev-beta.s3.us-east-1.amazonaws.com/defaultstories/backgrounds`;

const getUrl = (filename: string) => {
  return `${BASE_URL_TEMP}/${encodeURI(filename)}`;
}

export const BackgroundPicker = () => {
  const dispatch = useDispatch();

  const availableBgs = useSelector(getBgsPool);

  const userBgs = useSelector(getUserBgs);

  const onPickerUpdate = ({to}: PickerData) => {
    dispatch(appSlice.actions.setUserBgs(to.map(i => i.id)));
  }

  const bgItems = availableBgs.map(url => ({
    id: url,
    value: <PickerImgItem src={url} show />
  }));

  const userBgItems = userBgs.map(url => ({
    id: url,
    value: <PickerImgItem src={url} show />
  }));
  
  return (
    <Col>
      <Title>Background Picker</Title>
      <Picker 
        fromTitle='Available Backgrounds'
        from={bgItems}
        toTitle='User Backgrounds'
        to={userBgItems}
        onPickerUpdate={onPickerUpdate}
      />
    </Col>
  )
}
