import { Toast } from "react-bootstrap"
import styled from "styled-components";

const config = {
  error: {
    delay: 6000,
    title: 'Error!',
    variant: 'danger',
    color: 'white',
  },
  success: {
    delay: 3000,
    title: 'Success!',
    variant: 'success',
    color: 'white',
  },
  warning: {
    delay: 2000,
    title: 'Warning!',
    variant: 'warning',
    color: 'white',
  }
}

const Body = styled(Toast.Body)<{
  color: string;
  background?: string
}>`
  ${({color}) => `color: ${color};`}
  background: #ffffff47;
`;
// ${({background}) => `background: ${background};`}

export type AlertType = keyof typeof config;

interface Props { 
  type: AlertType;
  delay?: number;
  onClose: () => void;
  children: React.ReactNode;
}

export const Alert = ({
  type,
  delay,
  onClose,
  children,
}: Props) => {

  const conf = config[type];

  return (
    <Toast delay={delay || conf.delay} autohide onClose={onClose} bg={conf.variant}>
      <Toast.Header>
        {/* <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" /> */}
        <strong className="me-auto">{conf.title}</strong>
      </Toast.Header>
      <Body color={conf.color}>{children}</Body>
    </Toast>
  )
}