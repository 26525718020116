import { WalletAddress } from '@storyverseco/svs-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Dropdown } from '../../../../components/Dropdown';
import { Col, VGap } from '../../../../components/Layout';
import { useToast } from '../../../../components/Toast';
import { api } from '../../../api/PMToolApi';
import { appSlice } from '../../../state/appSlice';
import { Main } from '../../../layout/Main';
import { NFTStoriesMetadata } from '../../../../lib/types';
import { TextInput } from '../../../../components/TextInput';
import { getEnv } from '../../../../lib/getEnv';

const Container = styled(Col)``;

const SubmitBtn = styled(Button)`
  margin-top: 12px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
`;

interface StoryStorageInfo {
  label: string;
  walletAddress: WalletAddress;
  storyId: number | string;
}

interface StoryMapEntry {
  storyName: string;
  dev: StoryStorageInfo;
  prod: StoryStorageInfo;
}

const DEFAULT_STORY = 'NONE';

export const PromoteStory = () => {
  const dispatch = useDispatch();

  const [story, setStory] = useState(DEFAULT_STORY);

  const env = getEnv();

  const [storiesMetadata, setStoriesMetadata] = useState<NFTStoriesMetadata>();

  const [saveOutput, setSaveOutput] = useState<string>();

  const { success, error } = useToast();

  // Fetch stories metadata on load
  useEffect(() => {
    const fetchStoriesMetadata = async() => {
      dispatch(appSlice.actions.suspend());
      try {
        const response = await api.getNFTStories();
        setStoriesMetadata(response);
      } catch (e: any) {
        console.error(`Error (PromoteStory):`, e.message);
        error(`Failed to fetch stories metadata.`);
      }
      dispatch(appSlice.actions.resume());
    }

    fetchStoriesMetadata();
  }, []);

  const storiesList = useMemo(() => 
    [DEFAULT_STORY, ...Object.values(storiesMetadata || {}).map(s => s.storyName)]
  , [storiesMetadata]);  

  const onSubmit = useCallback(async() => {
    setSaveOutput(undefined);
    if (story === DEFAULT_STORY || !storiesMetadata) {
      return;
    }
    const storyData = Object.values(storiesMetadata).find(s => s.storyName === story);
    if (!storyData) {
      console.error(`Error (PromoteStory): Failed to 'submit'. Story data not found for '${story}'.`);
      return;
    }
    try {
      dispatch(appSlice.actions.suspend());
      const response = await api.promoteStory({
        walletAddress: storyData.walletAddress as WalletAddress,
        storyId: storyData.storyId,
      });
      setSaveOutput(response.data);
      success(`${story} story promoted to ${env} successfully!`);
      setStory(DEFAULT_STORY);
    } catch {
      error(`Failed to promote story '${story}' to ${env}.`);
    }
    dispatch(appSlice.actions.resume());
  }, [story, storiesMetadata]);

  return (
    <Main 
      clear
      title='Collectible Stories'
      subtitle='Promote story from `user wallet` to `playco wallet`'
    >
      <Dropdown
        title='Pick a story'
        value={story}
        onChange={setStory}
        items={storiesList}
      />
      <SubmitBtn
        disabled={story === DEFAULT_STORY}
        onClick={onSubmit}
        variant={'success'}
      >
        Promote
      </SubmitBtn>
      <VGap size={8} />
      {
        saveOutput && (
          <TextInput
            disabled
            label='Viewer URL'
            value={saveOutput}
            onChange={() => {}}
          />
        )
      }
    </Main>
  );
};
