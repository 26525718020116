import { ProjectTemplateData, Template } from '@storyverseco/svs-types';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../state';

export const getCoverImage = ({ createTemplate }: RootState) =>
  createTemplate.coverImage;

export const getCreateAtProject = ({ createTemplate }: RootState) =>
  createTemplate.selectedProject;

export const getSelectedTemplate = ({ createTemplate }: RootState) =>
  createTemplate.selectedTemplate;

export const getStoryTitle = ({ createTemplate }: RootState) =>
  createTemplate.storyTitle;

export const getCanCreateTemplate = createSelector(
  getStoryTitle,
  getCoverImage,
  getCreateAtProject,
  getSelectedTemplate,
  (
    title: string,
    coverImage?: string,
    project?: ProjectTemplateData,
    template?: Template
  ) => Boolean(title && coverImage && project && template)
);

export const getHasChanges = createSelector(
  getStoryTitle,
  getCoverImage,
  getCreateAtProject,
  getSelectedTemplate,
  (
    title: string,
    coverImage?: string,
    project?: ProjectTemplateData,
    template?: Template
  ) => Boolean(title || coverImage || project || template)
);
