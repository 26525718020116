import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ProjectTemplateData,
  Optional,
  Template,
} from '@storyverseco/svs-types';

interface CreateTemplateState {
  coverImage?: string;
  storyTitle: string;
  selectedProject?: ProjectTemplateData;
  selectedTemplate?: Template;
}

const initialState: CreateTemplateState = {
  storyTitle: '',
};

const setCoverImage: CaseReducer<
  CreateTemplateState,
  PayloadAction<Optional<string>>
> = (state, { payload }) => {
  state.coverImage = payload;
};

const setStoryTitle: CaseReducer<CreateTemplateState, PayloadAction<string>> = (
  state,
  { payload }
) => {
  state.storyTitle = payload;
};

const setSelectedProject: CaseReducer<
  CreateTemplateState,
  PayloadAction<Optional<ProjectTemplateData>>
> = (state, { payload }) => {
  state.selectedProject = payload;
};

const setSelectedTemplate: CaseReducer<
  CreateTemplateState,
  PayloadAction<Optional<Template>>
> = (state, { payload }) => {
  state.selectedTemplate = payload;
};

const resetState: CaseReducer<CreateTemplateState> = (state) => initialState;

export const createTemplateSlice = createSlice({
  name: 'createTemplate',

  initialState,

  reducers: {
    setCoverImage,
    setStoryTitle,
    setSelectedProject,
    setSelectedTemplate,
    resetState,
  },
});
