export const b64FromImgFile = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = document.createElement("img");
    const reader = new FileReader();
    reader.onloadend = function() {
      resolve(reader.result as string);
    }
    reader.onerror = function(error) {
      reject(error);
    }
    reader.readAsDataURL(file);
  });  
}

/**
 * Replace instances of `{token}` in a string with the specified token mapping.
 * 
 * Example: `tokenReplace("Hello {world}!", {"world": "Mars"})` will return
 * `"Hello Mars!"`
 * 
 * You can also make this strict by passing in `true` in the third parameter,
 * which will throw an error if a token does not exist in the mapping.
 * 
 * Example: `tokenReplace("Hello {world}!", {"foo": "bar"}, true)` will throw
 * an error because `world` is not in the mapping.
 * 
 * @param str The original string with tokens to replace.
 * @param tokens the string token mapping, i.e. `{"token": "hello"}`
 * @param strict If strict, a missing mapping will throw an error. Otherwise,
 *               the token will be replaced by an empty string.
 *               Default: `false`
 * @returns String with tokens replaced by the specified token mapping.
 */
export function tokenReplace<TTokens extends Record<string, string> = Record<string, string>>(str: string, tokens: TTokens, strict = false) {
  return str.replace(/\{\s*([a-zA-Z0-9_]+)\s*\}/gi, (_substring, p1) => {
    if (p1 && tokens[p1]) {
      return tokens[p1];
    }

    // if no match and strict, throw an error
    if (strict) {
      throw new Error(`No matching token for "${p1}"`);
    }

    // if no match and non-strict, replace with empty string
    return '';
  });
}

export const validateWalletAddress = (id: string) => {
  const match = id.match(/^0x\w{40}/);

  if (!match) {
    return false;
  }

  return match[0] === id;
}

export const isStringArray = (obj: any): obj is string[] => (
  obj && Array.isArray(obj) && obj.every(item => typeof item === 'string')
);

export const isEqual = (obj1: Record<string, any>, obj2: Record<string, any>) =>JSON.stringify(obj1) === JSON.stringify(obj2);