import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CharData,
  ExclusiveAssets,
  Optional,
  SelectedStory,
  TwtAuth1Creds,
  TwtAuthData,
} from '../../lib/types';
import { Wallet } from '@storyverseco/svs-navbar-client';
import { UserData } from '@storyverseco/svs-types';
import { getAssemblerCharDataForUrls } from '../../lib/assemblerUrlToCharData';
import { dropdownDefaultValue } from '../../lib/consts';
import { Routes } from '../navigation/useNavigation';

type Templates = any;

// @TODO: Break state per page + appSlice for general purpose stuff (suspend/resume/login)
interface SceneState {
  exclusiveBgs: string[];
  exclusiveChars: CharData[];
  initialUserBgs?: string[];
  initialUserChars?: string[];
  loading: boolean;
  stories: string[];
  selectedStory: SelectedStory;
  userChars: string[];
  userBgs: string[];
  initialTemplates?: Templates;
  templates: Templates;
  wallet?: Wallet;
  walletAddress: string;
  isStoryPageReady: boolean;
  adminWalletAddress: string;
  user?: UserData;
  assemblerTokenId: string;
  assemblerProject: string;
  twtAuthData: TwtAuthData;
  redirect?: Routes; // Use to redirect when loading a specific route
  loadingRequests: number[];
  showAppLayout: boolean;
}

const initialState: SceneState = {
  exclusiveBgs: [],
  exclusiveChars: [],
  loading: false,
  stories: [],
  selectedStory: {
    storyId: '',
    characters: [],
  },
  templates: {},
  userBgs: [],
  userChars: [],
  walletAddress: '',
  isStoryPageReady: false,
  adminWalletAddress: '',
  assemblerTokenId: '',
  assemblerProject: dropdownDefaultValue,
  twtAuthData: {
    inittedFromQuery: false,
    auth1Creds: null,
  },
  loadingRequests: [],
  showAppLayout: false,
};

const resume: CaseReducer<SceneState> = (state) => {
  const [, ...newLoadingRequests] = state.loadingRequests;
  state.loadingRequests = newLoadingRequests;
  state.loading = !(state.loadingRequests.length === 0);
  state.loading = false;
};

const suspend: CaseReducer<SceneState> = (state) => {
  state.loadingRequests.push(Date.now());
  state.loading = true;
};

const setWallet: CaseReducer<SceneState, PayloadAction<Optional<Wallet>>> = (
  state,
  { payload }
) => {
  state.wallet = payload;
};

const setUserBgs: CaseReducer<SceneState, PayloadAction<string[]>> = (
  state,
  { payload }
) => {
  state.userBgs = payload;
  if (!state.initialUserBgs) {
    state.initialUserBgs = state.userBgs;
  }
};

const setWalletAddress: CaseReducer<SceneState, PayloadAction<string>> = (
  state,
  { payload }
) => {
  state.walletAddress = payload;
};

const setStories: CaseReducer<SceneState, PayloadAction<string[]>> = (
  state,
  { payload }
) => {
  // assumning payload is an array of story ids
  state.stories = payload.reduce((acc, storyId: string) => {
    acc[storyId] = {
      cast: [],
    };
    return acc;
  }, {} as any);
};

const setSelectedStory: CaseReducer<
  SceneState,
  PayloadAction<{ storyId?: string; characters?: string[] }>
> = (state, { payload }) => {
  if (payload.storyId) {
    state.selectedStory.storyId = payload.storyId;
  }
  if (payload.characters) {
    state.selectedStory.characters = payload.characters;
  }
};

const resetSelectedStory: CaseReducer<SceneState> = (state) => {
  state.selectedStory = {
    storyId: '',
    characters: [],
  };
};

const setUserChars: CaseReducer<SceneState, PayloadAction<string[]>> = (
  state,
  { payload }
) => {
  state.userChars = payload;
  if (!state.initialUserChars) {
    state.initialUserChars = state.userChars;
  }
};

const resetUserChanges: CaseReducer<SceneState> = (state) => {
  state.userChars = state.initialUserChars || [];
  state.userBgs = state.initialUserBgs || [];
};

const setTemplates: CaseReducer<SceneState, PayloadAction<Templates>> = (
  state,
  { payload }
) => {
  state.templates = payload;
  if (!state.initialTemplates) {
    state.initialTemplates = state.templates;
  }
};

const resetTemplatesChanges: CaseReducer<SceneState> = (state) => {
  state.templates = state.initialTemplates || [];
};

const setExclusiveAssets: CaseReducer<
  SceneState,
  PayloadAction<ExclusiveAssets>
> = (state, { payload }) => {
  state.exclusiveBgs = payload.bgs;
  state.exclusiveChars = payload.chars;
  state.isStoryPageReady = true;
};

const setAdminWalletAddress: CaseReducer<SceneState, PayloadAction<string>> = (
  state,
  { payload }
) => {
  state.adminWalletAddress = payload;
};

const setUser: CaseReducer<SceneState, PayloadAction<Optional<UserData>>> = (
  state,
  { payload }
) => {
  state.user = payload;
  if (!state.user) {
    state.initialUserBgs = undefined;
    state.initialUserChars = undefined;
    state.userChars = [];
    state.userBgs = [];
    // Remove any assembler characters we added to exclusiveChars
    state.exclusiveChars = state.exclusiveChars.filter(
      (c) => !c.metadataUrl.includes('assembler/')
    );
  } else {
    // add assembler characters to the list of exclusives to make them available
    state.exclusiveChars.push(
      ...getAssemblerCharDataForUrls(state.user.assets.characters)
    );
  }
};

const setAssemblerTokenId: CaseReducer<SceneState, PayloadAction<string>> = (
  state,
  { payload }
) => {
  state.assemblerTokenId = payload;
};

const setAssemblerProject: CaseReducer<SceneState, PayloadAction<string>> = (
  state,
  { payload }
) => {
  state.assemblerProject = payload;
};

const addAssembledChar: CaseReducer<SceneState, PayloadAction<CharData>> = (
  state,
  { payload }
) => {
  state.exclusiveChars.push(payload);
  state.userChars.push(payload.metadataUrl);
};

const setTwtInittedFromQuery: CaseReducer<
  SceneState,
  PayloadAction<boolean>
> = (state, { payload }) => {
  state.twtAuthData.inittedFromQuery = payload;
};

const setTwtAuth1Creds: CaseReducer<
  SceneState,
  PayloadAction<TwtAuth1Creds | null>
> = (state, { payload }) => {
  state.twtAuthData.auth1Creds = payload;
};

const setRedirect: CaseReducer<
  SceneState,
  PayloadAction<Optional<Routes>>
> = (state, { payload }) => {
  state.redirect = payload;
};

const setShowAppLayout: CaseReducer<
  SceneState,
  PayloadAction<boolean>
> = (state, { payload }) => {
  state.showAppLayout = payload;
};

export const appSlice = createSlice({
  name: 'app',

  initialState,

  reducers: {
    resume,
    suspend,
    setWallet,
    setUserBgs,
    setWalletAddress,
    setStories,
    setUserChars,
    resetUserChanges,
    setTemplates,
    resetTemplatesChanges,
    setExclusiveAssets,
    setAdminWalletAddress,
    setUser,
    setAssemblerTokenId,
    setAssemblerProject,
    addAssembledChar,
    setSelectedStory,
    resetSelectedStory,
    setTwtInittedFromQuery,
    setTwtAuth1Creds,
    setRedirect,
    setShowAppLayout,
  },
});
