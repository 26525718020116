import { PMToolEndpoints } from '@storyverseco/svs-types';
import { getConfig } from '../../Config';
import { ApiParams } from '../../lib/types';

export const getJson = async (url: string) => {
  const response = await fetch(url);

  if (response.status >= 200 && response.status < 400) {
    return response.json();
  }

  return Promise.reject(await response.text());
};

export const request = async (endpoint: PMToolEndpoints, body: ApiParams) => {
  const config = await getConfig();

  const url = `${config.APIUrl}${endpoint}`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const isSuccess = response.status >= 200 && response.status < 400;

  if (!isSuccess) {
    throw new Error(await response.text());
  }

  return response;
};
