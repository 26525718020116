import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Col, HGap, Row, VGap } from '../../../../../components/Layout';
import { colors } from '../../../../../lib/styles';
import { Title } from '../../../../../components/Text';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRevealSchedule,
  getStoryAddress,
  getStoryId,
} from '../../state/selectors';
import { TextInput } from '../../../../../components/TextInput';
import { validateWalletAddress } from '../../../../../lib/utils';
import { Dropdown } from '../../../../../components/Dropdown';
import { adminSlice } from '../../state/adminSlice';
import { getConfig } from '../../../../../Config';
import { api } from '../../../../api/PMToolApi';
import { appSlice } from '../../../../state/appSlice';
import { useToast } from '../../../../../components/Toast';
import { ListPicker } from '../../../../../components/ListPicker';
import { IRevealSchedule, RevealType, TimelineId } from '@storyverseco/svs-story-suite';
import { NewSchedule } from './NewSchedule';
import { getTimeUntilReveal } from '../../../../../lib/time';
import { Schedule } from './Schedule';
import { Main } from '../../../../layout/Main';
import { getEnv } from '../../../../../lib/getEnv';

const Container = styled(Col)``;

const InputContainer = styled(Row)`
  width: 100%;
`;

const SubmitBtn = styled(Button)`
  height: 100%;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
`;

const testWalletAddress: Record<string, string> = {
  NONE: '',
};

const devTestWallets: Record<string, string> = {
  cai: '0xFf3bde3d2B0565F804f9a4191e1bEd875A45C3Cb',
  fezlove: '0x9f7a9c6679193aabed7f0af4069485b32a1ff251',
  carles: '0x1d8669a3db4c65430b1f3d0d613f460e8656b538'
};

/**
 * @TODO: For better UX, we could use the storyAddress to fetch the story counter
 * and display a dropdown with available storyIds Array(storyCounter)
 */
export const RevealSchedule = () => {
  const dispatch = useDispatch();

  const walletAddress = useSelector(getStoryAddress);

  const storyId = useSelector(getStoryId);

  const env = getEnv();

  const revealSchedule = useSelector(getRevealSchedule);

  const { success, error } = useToast();

  const [addresses, setAddresses] = useState(testWalletAddress);

  const [dropdownSelection, setDropdownSelection] = useState('NONE');

  const [selectedSchedule, setSelectedSchedule] = useState<IRevealSchedule>();

  useEffect(() => {
    if (env === 'dev') {
      // Apply dev test wallets
      Object.keys(devTestWallets).forEach((wallet) => {
        testWalletAddress[wallet] = devTestWallets[wallet];
      });
    }

    const getPlaycoWalletAddress = async () => {
      const config = await getConfig();
      console.log(config.globals);
      console.log(config);
      setAddresses({
        ...addresses,
        playco: (config.globals as any).playcoWalletAddress,
      });
    };
    getPlaycoWalletAddress();
  }, []);

  const onWalletAddressChange = (key: string) => {
    const value = addresses[key];
    setDropdownSelection(key);
    // Reset the story id when changing the wallet
    dispatch(adminSlice.actions.setStoryId(''));
    dispatch(adminSlice.actions.setStoryAddress(value));
  };

  const onStoryIdChange = (value: string) => {
    dispatch(adminSlice.actions.setStoryId(value));
  };

  const onGetRevealSchedule = async () => {
    // First clear the current value
    dispatch(adminSlice.actions.setRevealSchedule(undefined));
    dispatch(appSlice.actions.suspend());
    const revealSchedule = await api.getRevealSchedule(walletAddress, storyId);
    if (revealSchedule.length === 0) {
      success('No reveal file created for this story. Showing default.');
    } else {
      success('Fetched reveal file successfully!');
    }
    // Then set the new value
    dispatch(adminSlice.actions.setRevealSchedule(revealSchedule));
    dispatch(appSlice.actions.resume());
  };

  const onScheduleSelected = useCallback(
    (id: string) => {
      setSelectedSchedule(
        revealSchedule?.find((s) => s.revealedTimelineId === id)
      );
    },
    [revealSchedule]
  );

  const clearSchedule = useCallback(() => {
    setSelectedSchedule(undefined);
  }, []);

  const onDeleteSchedule = async (timelineId: TimelineId) => {
    const newSchedule = (revealSchedule || []).filter(
      (s) => s.revealedTimelineId !== timelineId
    );

    try {
      dispatch(appSlice.actions.suspend());
      await api.setRevealSchedule(walletAddress, storyId, newSchedule);
      success(`Schedule deleted successfully!`);
      await onGetRevealSchedule();
    } catch (e: any) {
      error(e.message);
    }
    dispatch(appSlice.actions.resume());
  }

  const onSaveRevealSchedule = async (
    revealedTimelineId: TimelineId,
    timestamp: number
  ) => {
    let currentSchedule = revealSchedule || [];

    // If we are editing an existing entry, remove the current
    currentSchedule = currentSchedule.filter(
      (s) => s.revealedTimelineId !== revealedTimelineId
    );

    const newRevealSchedule: IRevealSchedule[] = [
      ...currentSchedule,
      {  revealedTimelineId, timestamp, type: RevealType.CHOICE_AUTO, revealed: false }
    ];

    try {
      dispatch(appSlice.actions.suspend());
      await api.setRevealSchedule(walletAddress, storyId, newRevealSchedule);
      success(`Schedule saved successfully!`);
      await onGetRevealSchedule();
    } catch (e: any) {
      error(e.message);
    }
    dispatch(appSlice.actions.resume());
  };

  return (
    <Main
      title='Collectible Stories'
      subtitle='Set upcoming reveal schedules'
    >
      <Title bold center upper>
        Search Story
      </Title>
      <VGap size={12} />
      <InputContainer clear>
        <Row>
          <Row>
            <Dropdown
              title='Story Address'
              value={dropdownSelection}
              onChange={onWalletAddressChange}
              items={Object.keys(addresses)}
              style={{ fontSize: 24, paddingLeft: 12, marginTop: 2 }}
            />
          </Row>
          <HGap size={12} />
          <Row flex={0.5}>
            <TextInput
              label='Story Id'
              value={storyId}
              onChange={onStoryIdChange}
            />
          </Row>
        </Row>
        <Row flex={0.1} center>
          <SubmitBtn
            disabled={!validateWalletAddress(walletAddress)}
            onClick={onGetRevealSchedule}
            // variant={submitVariant}
          >
            Submit
          </SubmitBtn>
        </Row>
      </InputContainer>

      <VGap size={24} />
      <Row style={{ width: '100%' }} clear>
        <Row>
          <ListPicker
            title='Schedules'
            items={(revealSchedule || []).map((schedule) => ({
              id: schedule.revealedTimelineId,
              value: <Schedule schedule={schedule} />,
            }))}
            onItemClick={onScheduleSelected}
            disabled={false}
            fullWidth
          />
        </Row>
        <NewSchedule
          schedule={selectedSchedule}
          onSubmit={onSaveRevealSchedule}
          onClear={clearSchedule}
          onDelete={onDeleteSchedule}
        />
      </Row>
    </Main>
  );
};
