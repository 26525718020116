
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux';
import { Toast, ToastContextProvider } from './components/Toast';
import { AppNavigationRoutes } from './features/navigation/Navigation';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { store } from './features/state';
import { PageLoading } from './components/PageLoading';
import { Layout } from './features/layout/Layout';

function App() {  
  console.log('App');
  return (
    <Provider store={store}>
      <ToastContextProvider>
        <Toast />
        <PageLoading />
        <BrowserRouter>
          <Layout>
            <AppNavigationRoutes />
          </Layout>
        </BrowserRouter>
      </ToastContextProvider>
    </Provider>
  );
}

export default App;
