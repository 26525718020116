import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { BackgroundPicker } from './BackgroundPicker';
import { CharacterPicker } from './CharacterPicker';
import { Row, Col, VGap, HGap } from '../../../../../components/Layout';
import { useToast } from '../../../../../components/Toast';
import { api } from '../../../../api/PMToolApi';
import { appSlice } from '../../../../state/appSlice';

const Container = styled(Col)`

`;

const BtnsContainer = styled(Row)`
  justify-content: flex-end;
`;


export const UserSettings = () => {

  const dispatch = useDispatch();

  const { success, error } = useToast();

  const onSaveChanges = () => {
    onAction({
      successMsg: 'User settings saved successfully',
      errorMsg: 'Failed to save user settings',
      promise: api.updateUserAssets(),
    });
  }

  const onResetChanges = () => {
    onAction({
      successMsg: 'Changes in user settings resetted!',
      errorMsg: 'Failed to reset changes in user settings. Please refresh the app.',
      promise: (async() => dispatch(appSlice.actions.resetUserChanges()))(),
    });
  }

  const onAction = async({promise, successMsg, errorMsg}: {promise: Promise<any>, successMsg: string, errorMsg: string}) => {
    await dispatch(appSlice.actions.suspend());
    try {
      await promise;  
      success(successMsg);
    } catch (e) {
      error(errorMsg);
    }    
    await dispatch(appSlice.actions.resume());
  }


  return (
    <Container>
      <CharacterPicker />
      <VGap size={16} />
      <BackgroundPicker />
      <VGap size={16} />
      <BtnsContainer>
        <Button variant='danger' onClick={onResetChanges}>Reset Changes</Button>
        <HGap size={32} />
        <Button variant='success' onClick={onSaveChanges}>Save Changes</Button>
      </BtnsContainer>
    </Container>
  )
}