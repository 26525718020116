import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Accordion } from '../../../components/Accordion';
import { AdminWalletAddressInput } from './components/AdminWalletAddressInput';
import { Col, VGap } from '../../../components/Layout';
import { Page } from "../../../components/Page";
import { Title } from '../../../components/Text';
import { useToast } from '../../../components/Toast';
import { TemplateAddressInput } from './components/TemplateCreateInput';
import { createUserThunk, createTemplateProject } from './state/thunks';
import { AppDispatch } from '../../state';
import { MintInput } from './components/MintInput';
import { RevealSchedule } from './components/RevealSchedule/RevealSchedule';
import { PromoteStory } from './components/PromoteStory';
import { getEnv } from '../../../lib/getEnv';

const Content = styled(Col)`
  width: 1200px;
`;

export const AdminPage = () => {

  const dispatch = useDispatch<AppDispatch>();

  const env = getEnv()

  const { success, error } = useToast();

  const onSubmitWalletAddress = async() => {
    dispatch(createUserThunk({
      onSuccess: async() => success(`User created successfully!`),
      onFailure: async() => error('Failed to create user ')
    }));
  }

  const onSubmitTemplateProject = () => {
    dispatch(createTemplateProject({
      onSuccess: async() => success(`Template project created successfully!`),
      onFailure: async() => error('Failed to create template project')
    }));
  }

  const baseItems = [
    {
      title: 'Users',
      content: (
        <Col>
          <Title>Create User</Title>
          <AdminWalletAddressInput onSubmit={onSubmitWalletAddress} />
        </Col>
      )
    }, 
    {
      title: 'Template Projects',
      content: (
        <Col>
          <Title>Create Template Project</Title>
          <TemplateAddressInput onSubmit={onSubmitTemplateProject} />
        </Col>
      )
    },
    {
      title: 'Reveal Schedule',
      content: (
        <Col>
          <Title>Set reveal schedule</Title>
          <RevealSchedule />
        </Col>
      )
    },
    {
      title: 'Promote Story',
      content: (
        <Col>
          <Title>Promote NFT Story</Title>
          <PromoteStory />
        </Col>
      )
    }                     
  ];

  const items = env === 'dev' ? [
    ...baseItems,
    {
      title: 'Mint',
      content: (
        <Col>
          <Title>Mint NFT for <b>MYSELF</b></Title>
          <MintInput />
        </Col>
      )
    },         
  ] : baseItems;

  return (
    <Page center>
      <Title bold upper fontSize={40}>Admin Page</Title>
      <Title fontSize={18}>Add new freemium users.</Title>
      <Content>
        <VGap size={32} />
        <Accordion items={items} />
      </Content>
    </Page>
  );
}