import styled from "styled-components";
// import { Page } from "../../components/Page"
import { Title } from "../../components/Text"
import { ReactNode, useEffect } from "react";
import { Col } from "../../components/Layout";
import { useDispatch } from "react-redux";
import { appSlice } from "../state/appSlice";
import { Routes } from "../navigation/useNavigation";

const Page = styled(Col)`
  padding: 12px;
`;

const Content = styled(Col)`
  margin-top: 24px;
  overflow-y: auto;
  max-height: 100%;
`;

interface Props {
  children: ReactNode;
  clear?: boolean;
  title: string;
  subtitle?: string;
}

export const Main = ({children, clear, title, subtitle}: Props) => {
  return (
    <Page id='page'>
      <Title bold upper fontSize={40}>{title}</Title>
      {subtitle && <Title fontSize={18}>{subtitle}</Title>}
      <Content clear={clear}>
          {children}
      </Content>
    </Page>
  )
}