import { ChangeEvent, useCallback, useState } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { Col, Row } from "../../../../components/Layout";
import { Modal } from "../../../../components/Modal";
import { Optional, Nullable } from "../../../../lib/types";
import { BaseText, Title } from "../../../../components/Text";
import { colors } from "../../../../lib/styles";
import { TextInput } from "../../../../components/TextInput";
import { Dropdown } from "../../../../components/Dropdown";
import { useDispatch } from "react-redux";
import { appSlice } from "../../../state/appSlice";
import { api } from "../../../api/PMToolApi";
import { useToast } from "../../../../components/Toast";

const Container = styled(Row)`
mar
  padding-top: 16px;
`;

const LeftContainer = styled(Col)`
  padding: 8px;
`;

const RightContainer = styled(Col)`
  padding: 8px 0px;
`;

const DropdownContainer = styled(Col)`

`;


const projects = ['BAYC'];


export const GenerateStory = () => {

  const dispatch = useDispatch();

  const [token1, setToken1] = useState('');
  const [token2, setToken2] = useState('');
  const [token3, setToken3] = useState('');

  const [project, setProject] = useState(projects[0]);

  const [outputUrl, setOutputUrl] = useState('');

  const { success, error } = useToast();

  const onGenerateStory = async() => {
    setOutputUrl('');
    dispatch(appSlice.actions.suspend());
    try {
      const url = await api.generateStory([token1, token2, token3].filter(Boolean), project);
      setOutputUrl(url);
      success(`Story generated successfully! Check output for URL`);
    } catch (e: any) {
      error(e.message);
    } finally {
      dispatch(appSlice.actions.resume());
    }
  }


  return (
    <Container>
      <LeftContainer>
        <TextInput 
          label='Token 1'
          value={token1}
          type='number'
          onChange={setToken1}
        />
        <TextInput 
          label='Token 2'
          value={token2}
          type='number'
          onChange={setToken2}
          disabled={!Boolean(token1)}
        />
        <TextInput 
          label='Token 3'
          value={token3}
          type='number'
          onChange={setToken3}
          disabled={!Boolean(token2)}
        />
      </LeftContainer>
      <RightContainer>
        <DropdownContainer flex={0.58}>
          <Title>Project</Title>
          <Dropdown
            value={project}
            onChange={setProject}
            items={projects}
            style={{fontSize: 24, paddingLeft: 12}}
          />
        </DropdownContainer>
        <Button style={{flex: 0.15}} disabled={!Boolean(token1)} onClick={onGenerateStory}>Generate story</Button>
        <TextInput 
          label='Output URL'
          value={outputUrl}
          onChange={() => {}}
          disabled={true}
        />
      </RightContainer>
    </Container>
  )
}

