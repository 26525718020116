import * as config from '@storyverseco/svs-config-client';
import { ConfigClient } from '@storyverseco/svs-config-client';
import { getEnv } from './lib/getEnv';
import { Env } from '@storyverseco/svs-types';

export const PATCHED_PLOTS_PATH = 'patchedPlots.json';
export const PATCHED_BACKGROUNDS_PATH = 'patchedBackgroundPlots.json';
export const METADATA_PATH = 'n';
export const CHARACTER_PATCH_PATH = 'patch';
export const DELETE_DRAFT_PATH = 'draft/delete';
export const DELETE_DRAFT_PATCH_PATH = 'patch/draft/delete';
export const USABLE_CHARACTERS_PATH = 'usableCharacters/characters.json';
export const USABLE_CHARACTERS_METADATA_PATH =
  'usableCharacters/metadatas.json';
export const BACKGROUND_IMAGE_LIST_PATH =
  'defaultstories/backgrounds/backgroundsList.json';
export const BACKGROUND_IMAGES_PATH = 'defaultstories/backgrounds';

let devCfg: ConfigClient;
let prodCfg: ConfigClient;
let stagingCfg: ConfigClient;

async function fetchConfigs() {
  console.log('Fetching configs');
  // @ts-ignore
  const pd = config.stage('dev').addSource('frontend').addSource('saleData').loadConfigs(true).promise.then((cfg) => {
    devCfg = cfg;
  });

  // @ts-ignore
  const pp = config.stage('prod').addSource('frontend').addSource('saleData').loadConfigs(true).promise.then((cfg) => {
    prodCfg = cfg;
  });

  // @ts-ignore
  const ps = config.stage('staging').addSource('frontend').addSource('saleData').loadConfigs(true).promise.then((cfg) => {
    stagingCfg = cfg;
  });

  return Promise.all([pd, pp, ps]);
}

const configFetching = fetchConfigs();

type Config = ConfigClient & {
  APIUrl: string;
  mbaasUrl: string;
  mbaasApiKey: string;
};

const constConfig = {
  dev: {
    APIUrl: 'https://pmt-backend.fodaeh.net',
    mbaasUrl: 'https://amgpm7yoizg27p4gm7qb3ehsiu.multibaas.com',
    mbaasApiKey:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3MjY2YjNlOC05MmY0LTQyOTQtYWIyYi00OGY5YWZmYmJlNzAiLCJpYXQiOjE2NzQ3NzA5MDAsInN1YiI6IjIifQ.xb5HPSHR_Ah0whN21EwT4a2L_iXEYrDrrRjYYHB3xzA',
    getConfig: () => devCfg,
  },
  staging: {
    APIUrl: 'https://pmt-backend.fodaeh.net',
    // @TODO: ask santosh about this
    mbaasUrl: 'https://mt65tblyozhhlid4zgswhly6qi.multibaas.com',
    mbaasApiKey:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIzYjg3Nzk5Ni0zYWRjLTQxMTUtYTEyZC04MTlkNGU0NTg1ZDIiLCJpYXQiOjE2NzQ4NTI3MzksInN1YiI6IjM1In0.wQscun1GPvcz3WFxl--8WJN5YzRZnbz9EZckFH3B7Do',
    getConfig: () => stagingCfg,
  },
  prod: {
    APIUrl: 'https://pmt-backend.fodaeh.net',
    mbaasUrl: 'https://mt65tblyozhhlid4zgswhly6qi.multibaas.com',
    mbaasApiKey:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIzYjg3Nzk5Ni0zYWRjLTQxMTUtYTEyZC04MTlkNGU0NTg1ZDIiLCJpYXQiOjE2NzQ4NTI3MzksInN1YiI6IjM1In0.wQscun1GPvcz3WFxl--8WJN5YzRZnbz9EZckFH3B7Do',
    getConfig: () => prodCfg,
  }
}


export async function getConfig(opts?: {env?:Env, fresh?: boolean}): Promise<Config> {
  if (opts?.fresh) {
    await fetchConfigs();
  }

  const { getConfig, ...rest } = constConfig[opts?.env || getEnv()];

  await configFetching;

  const remoteConfig = getConfig();

  console.log({remoteConfig});

  return {
    ...remoteConfig,
    ...rest
  } as Config;
}

// const config = {
//   dev: {
//     env: 'dev',
//     MetadataPrefix: 'https://meta.alpha.pnk.one/n',
//     CMSServer: 'https://cms.stories.alpha.pnk.one',
//     PatchedPlotsUrl: 'https://cms.stories.alpha.pnk.one/patchedPlots.json',
//     PatchedBackgroundPlotsUrl: 'https://cms.stories.alpha.pnk.one/patchedBackgroundPlots.json',
//     UsableCharacters: 'https://sverse-character-storage-dev.s3.amazonaws.com/usableCharacters/characters.json',
//     UsableCharactersMetadata: 'https://sverse-character-storage-dev.s3.amazonaws.com/usableCharacters/metadatas.json',
//     CharacterPatchingPrefix: 'https://cp.fodaeh.net/patch',
//     BackgroundImageFileNamelist: 'https://sverse-stories-storage-dev.s3.amazonaws.com/defaultstories/backgrounds/backgroundsList.json',
//     BackgroundImageUrlPrefix: 'https://cms.stories.alpha.pnk.one/defaultstories/backgrounds',
//     DeleteDraftUrl: 'https://cp.fodaeh.net/draft/delete',
//     DeleteDraftAndPatchUrl: 'https://cp.fodaeh.net/patch/draft/delete',
//     BlockchainAPIPrefix: 'https://blockchain.alpha.pnk.one',
//     AssemblerGetCharUrl: 'https://assembler-api.alpha.pnk.one/char',
//     APIUrl: 'https://pmt-backend.fodaeh.net',
//   },
//   prod: {
//     env: 'prod',
//     MetadataPrefix: 'https://meta.alpha.storyverse.xyz/n',
//     CMSServer: 'https://cms.stories.alpha.storyverse.xyz',
//     PatchedPlotsUrl: 'https://cms.stories.alpha.storyverse.xyz/patchedPlots.json',
//     PatchedBackgroundPlotsUrl: 'https://cms.stories.alpha.storyverse.xyz/patchedBackgroundPlots.json',
//     UsableCharacters: 'https://sverse-character-storage-ll.s3.amazonaws.com/usableCharacters/characters.json',
//     UsableCharactersMetadata: 'https://sverse-character-storage-ll.s3.amazonaws.com/usableCharacters/metadatas.json',
//     CharacterPatchingPrefix: 'https://cp.fodaeh.net/patch',
//     BackgroundImageFileNamelist: 'https://sverse-stories-storage-ll.s3.amazonaws.com/defaultstories/backgrounds/backgroundsList.json',
//     BackgroundImageUrlPrefix: 'https://cms.stories.alpha.storyverse.xyz/defaultstories/backgrounds',
//     DeleteDraftUrl: 'https://cp.fodaeh.net/draft/delete',
//     DeleteDraftAndPatchUrl: 'https://cp.fodaeh.net/patch/draft/delete',
//     BlockchainAPIPrefix: 'https://blockchain.alpha.storyverse.xyz',
//     AssemblerGetCharUrl: 'https://assembler-api.alpha.storyverse.xyz/char',
//     APIUrl: 'https://pmt-backend.fodaeh.net',
//   }
