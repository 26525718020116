import styled from "styled-components"
import { FilterFunction, PickerData, PickerItem } from "../lib/types";
import { Row } from "./Layout"
import { ListPicker } from "./ListPicker";
import { useToast } from "./Toast";

const Containter = styled(Row)<{
  height: number;
}>`
  min-height: ${({height}) => height}px;
  max-height: ${({height}) => height}px;
`;

interface Props {
  from: PickerItem[];
  to: PickerItem[];
  toTitle: string;
  fromTitle: string;
  onPickerUpdate: (value: PickerData) => void;
  maxTo?: number;
  filterToBy?: FilterFunction;
  filterFromBy?: FilterFunction;
  height?: number;
  disabled?: boolean;
}

export const Picker = ({
  from,
  to,
  onPickerUpdate,
  maxTo,
  toTitle,
  fromTitle,
  filterFromBy,
  filterToBy,
  height = 350,
  disabled = false,
}: Props) => {

  const { warning } = useToast();

  const onFromItemClick = (id: string) => {
    if (disabled) {
      warning(`Items disabled until a "search" is performed.`)
      return;
    }
    const toItem = from.find(i => i.id === id);
    if (!toItem) {
      return;
    }
    onPickerUpdate({
      to: [...to, toItem],
      from: from.filter(i => i.id !== id),
    });
  }

  const onToItemClick = (id: string) => {
    if (disabled) {
      warning(`Items disabled until a "search" is performed.`)
      return;
    }
    const fromItem = to.find(i => i.id === id);
    if (!fromItem) {
      return;
    }
    onPickerUpdate({
      from: [...from, fromItem],
      to: to.filter(i => i.id !== id),
    });
  }

  return (
    <Containter height={height}>
      <ListPicker 
        title={fromTitle}
        items={from}
        onItemClick={onFromItemClick}
        disabled={disabled || maxTo === to.length}
        filterBy={filterFromBy}
      />
      <ListPicker 
        title={toTitle}
        items={to}
        onItemClick={onToItemClick}
        filterBy={filterToBy}
        disabled={disabled}
      />
    </Containter>
  )
}