import styled from 'styled-components';
import { Accordion } from '../../../components/Accordion';
import { Col, VGap } from '../../../components/Layout';
import { Page } from "../../../components/Page";
import { Title } from '../../../components/Text';
import { colors } from '../../../lib/styles';
import { GenerateStory } from './components/generateStory';
import { ValidateInputStory } from './components/ValidateInputStory';
import { TwitterDMTool } from './components/TwitterDMTool';

const Content = styled(Col)`
  width: 1200px;
`;

const ComponentWrapper = styled(Col)`
  padding: 16px 0px;
  border-bottom: 1px solid ${colors.grey};
`;

export const ToolsPage = () => {
  return (
    <Page center>
      <Title bold upper fontSize={40}>Tools Page</Title>
      <Title fontSize={18}>Tools to make our life a bit easier.</Title>
      <Content>
        <VGap size={32} />
        <Accordion
          items={[
            {
              title: 'Story',
              content: (
                <>
                  <ComponentWrapper>
                    <Title>Story Validator</Title>
                    <ValidateInputStory />
                  </ComponentWrapper>
                  <ComponentWrapper>
                    <Title>Generate Story</Title>
                    <GenerateStory />
                  </ComponentWrapper>
                </>
              )
            },
            {
              title: 'Twitter DM',
              content: <TwitterDMTool />
            }
          ]}
        />
      </Content>
    </Page>
  );
}