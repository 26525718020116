import { ReactNode, useMemo } from 'react';
import BSAccordion from 'react-bootstrap/Accordion';

export interface AccordionItem {
  title: ReactNode;
  content: ReactNode;
}

interface Props {
  items: AccordionItem[];
  defaultActiveKey?: string[];
}

export const Accordion = ({
  items,
  defaultActiveKey
}: Props) => {

  const defaultActive = useMemo(() => {
    return defaultActiveKey ?? items.map((_, i) => i.toString());
  }, [defaultActiveKey]);

  return (
    <BSAccordion defaultActiveKey={defaultActive} flush>
      {
        items.map((item, index) => (
          <BSAccordion.Item key={index} eventKey={index.toString()}>
            <BSAccordion.Header>{item.title}</BSAccordion.Header>
            <BSAccordion.Body style={{}}>
              {item.content}
            </BSAccordion.Body>
          </BSAccordion.Item>
        ))
      }
    </BSAccordion>
  );
}