import { WalletAddress } from '@storyverseco/svs-types';
import { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Accordion } from '../../../../components/Accordion';
import { Dropdown } from '../../../../components/Dropdown';
import { Col, HGap, Row, VGap } from '../../../../components/Layout';
import { Modal } from '../../../../components/Modal';
import { TextInput } from '../../../../components/TextInput';
import { useToast } from '../../../../components/Toast';
import { Crud, Group, Permission, User, UserType } from '../../../../lib/SvsAcl';
import { validateWalletAddress } from '../../../../lib/utils';
import { useACL, useSVSCognito } from '../../../hooks/useSVSCognito';
import { UserForm } from './UserForm';
import { Main } from '../../../layout/Main';

const BtnsContainer = styled(Row)`
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 16px;
`;

const DeleteBtnContainer = styled(Row)`
`

const ModalContent = styled(Col)`

`;


const userFilter = (user: User, search: string): boolean => {

  const compare = (value: string) => value.toLowerCase().includes(search.toLowerCase());

  return [
    user.wallets.some(compare), 
    compare(user.name),
    compare(user.email || ''),
    compare(user.group.name),
  ].some(v => v);
}

const userTypes = Object.keys(UserType) as UserType[];

interface Props {
  
}

export const Users = ({  }: Props) => {
  const { getGroup, getGroups } = useACL();

  const { cognitoReady, getUsers, createUser } = useSVSCognito();

  const [newName, setNewName] = useState('');

  const [newType, setNewType] = useState<UserType>(userTypes[0]);

  const [newGroupName, setNewGroupName] = useState(getGroups().map(g => g.name)[0]);

  const [newWalletAddress, setNewWalletAddress] = useState('');

  const [search, setSearch] = useState('');

  const { error } = useToast();

  const onAddClick = () => {
    if (!newName) {
      error(`Cannot add user. 'Name' is required.`);
      return;
    }
    const group = getGroup(newGroupName);

    if (!validateWalletAddress(newWalletAddress)) {
      error(`Cannot add user. 'WalletAddress' is invalid.`);
      return;
    }

    if (!group) {
      error(`Cannot add user. 'Group' not found.`);
      return;
    }

   createUser({
    name: newName,
    type: newType,
    group,
    walletAddress: newWalletAddress.toLowerCase() as WalletAddress,
   })
  };


  if (!cognitoReady) {
    return null;
  }

  const filteredUsers = getUsers().filter(u => userFilter(u, search));

  const userItems = filteredUsers.map(user => {
    return {
      title: user.name,
      content: <UserForm user={user} />
    }
  });

  return (
    <Main
      title='Cognito'
      subtitle='Add, edit, remove platform users'
    >
      <BtnsContainer>
        <Col>
          <TextInput 
            value={newName}
            onChange={setNewName}
            label='User Name'
          />
          <Dropdown
            title='User Group'
            value={newGroupName}
            items={getGroups().map(g => g.name)}
            onChange={setNewGroupName}
          />
        </Col>
        <HGap size={8} />
        <Col>
          <Dropdown
            title='User Type'
            value={newType}
            items={userTypes}
            onChange={(type) => setNewType(type as UserType)}
          />
          <TextInput 
            value={newWalletAddress}
            onChange={setNewWalletAddress}
            label='Wallet Address'
          />
        </Col>
        <HGap size={8} />
        <Col>
          <Button variant='success' onClick={onAddClick} disabled={!newName}>Add User</Button>
          <VGap size={4} />
          <TextInput 
            value={search}
            onChange={setSearch}
            label='Search'
          />
        </Col>
      </BtnsContainer>
      <VGap size={6} />
      <hr />
      <Col>
        <Accordion 
          items={userItems}
        />
      </Col>
    </Main>
  )
}