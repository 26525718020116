import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Box, Col, Row } from './Layout';
import { Uploader } from './Uploader';
import { colors } from '../lib/styles';
import { Title } from './Text';

const Container = styled(Col)`
  margin: 12px;
`;

const ImagesContainer = styled(Box)`
  overflow-y: auto;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ImageBox = styled(Col)`
  justify-content: space-between;
  button {
    max-width: 300px;
  }
`;

const Image = styled.img`
  width: 300px;
  height: auto;
  max-height: 300px;
`;

const AddFileBtn = styled(Row)`
  font-size: 80px;
  width: 300px;
  border: 1px solid ${colors.grey};
  color: ${colors.black};
  border-radius: 8px;
  cursor: pointer;
`

interface Props {
  label?: string;
  id: string;
  onFileChange: (file?: File) => void;
  previewUrl?: string;
}

export const ImageUploader = ({id, onFileChange, previewUrl, label = 'Upload Image'}: Props) => {

  const uploader = useRef<HTMLInputElement>(null);

  const [file, setFile] = useState<File>();

  const [previewImage, setPreviewImage] = useState(previewUrl);

  useEffect(() => {
    setPreviewImage(previewUrl);
  }, [previewUrl]);

  const onFileUploaded = useCallback(async(f: File) => {
    setFile(f);
    onFileChange(f);
  }, []);

  const openFileUploader = useCallback(() => {
    if (!uploader?.current) {
      return;
    }
    onClearImage();
    uploader.current.click();
  }, [uploader.current]);

  const onClearImage = useCallback(() => {
    setFile(undefined);
    onFileChange(undefined);
    setPreviewImage(undefined);
  }, []);

  const imageUrl = useMemo(() => {
    console.log({previewImage});
    if (previewImage) {
      return previewImage;
    }
    if (file) {
      return URL.createObjectURL(file);
    }
  }, [previewImage, file])

  return (
    <Container center>
      <Title>{label}</Title>
      <ImagesContainer clear>    
        {
          imageUrl && (
            <ImageBox>
              <Image src={imageUrl} />
              <Col clear>
                <Button onClick={onClearImage}>Clear Image</Button>
              </Col>
            </ImageBox>
          )
        }
        {!imageUrl && <AddFileBtn center clear onClick={openFileUploader}>+</AddFileBtn>}
      </ImagesContainer>   
      <Uploader
        ref={uploader} 
        id={id}
        onFileUploaded={onFileUploaded}
        accept='*'
      />
    </Container>
  );
}