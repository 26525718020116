import { ReactNode, useEffect, useState } from 'react';
import { LoginStatic } from '../../../components/LoginStatic';
import { Routes, useNavigation } from '../../navigation/useNavigation';
import { useDispatch } from 'react-redux';
import { appSlice } from '../../state/appSlice';
import { useNavbar } from '../../hooks/useNavbar';
import { useLogin } from '../../hooks/useLogin';
import { useSVSCognito } from '../../hooks/useSVSCognito';
import { useProfile } from '../../hooks/useProfile';

interface Props {
  children: ReactNode;
}

export const AppLoader = ({children}: Props) => {  

  const { navigate, route } = useNavigation();

  const dispatch = useDispatch();

  const { isReady, requireLogin } = useApp();

  // console.log('AppLoader', {
  //   isReady,
  //   requireLogin,
  // });

  if (!isReady) {
    return <LoginStatic />
  }

  // Take user to login page (HOME) if needs auth
  if (requireLogin && route !== Routes.HOME) {
    dispatch(appSlice.actions.setRedirect(route as Routes));
    navigate(Routes.HOME);
    return null;
  }

  return <>{children}</>
}

const useApp = () => {
  const [isReady, setIsReady] = useState(false);

  const [_next, _setNext] = useState(0);

  const { navbarReady } = useNavbar('app');

  const { loginReady, loggedIn } = useLogin('app');

  const { profileReady } = useProfile();

  const dispatch = useDispatch()

  useEffect(() => {
    setIsReady(navbarReady && loginReady);
  }, [navbarReady, loginReady]);

  // Once we are ready, set app layout according to our auth state
  useEffect(() => {
    if (!isReady) {
      return;
    }
    dispatch(appSlice.actions.setShowAppLayout(loggedIn));
  }, [isReady, loggedIn]);

  // Handle cognito loading wait, after navabar and login are ready and we are authenticated already (we need wallet for cognito)
  useEffect(() => {
    if (!loggedIn) {
      return;
    }
    setIsReady(profileReady);
  }, [loggedIn, profileReady]);

  return {
    isReady,
    requireLogin: !loggedIn,
  };
};