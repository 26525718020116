import { useState } from "react";
import styled from "styled-components";
import { Col, HGap, Row, VGap } from "../../../../components/Layout";
import { TextInput } from "../../../../components/TextInput";
import { Button } from "react-bootstrap";
import { useToast } from "../../../../components/Toast";
import { useDispatch } from "react-redux";
import { appSlice } from "../../../state/appSlice";
import { api } from "../../../api/PMToolApi";
import { Textarea } from "../../../../components/Textarea";
import { Dropdown } from "../../../../components/Dropdown";
import { Main } from "../../../layout/Main";

const Container = styled(Col)`

`;

const InputsContainer = styled(Row)`

`;

const TextareaContainer = styled(Col)`
  min-height: 200px;
`;

const prettifyFile = (jsonStr: string) => JSON.stringify(JSON.parse(jsonStr), null, 2);

const buckets = ['', "characters", "stories", "nfts", "admin"];

export const FileEditor = () => {

  const [key, setKey] = useState('');

  const [bucket, setBucket] = useState('');

  const [originalFileContent, setOriginalFileContent] = useState<string>();
  const [fileContent, setFileContent] = useState('');

  const [isJson, setIsJson] = useState(false);

  const { success, error } = useToast();

  const dispatch = useDispatch()

  const onGet = async() => {
    setOriginalFileContent(undefined);
    dispatch(appSlice.actions.suspend());
    try {
      const file = await api.getFile({bucket, key});
      try {
        const prettyFile = prettifyFile(file);
        setFileContent(prettyFile);
        setOriginalFileContent(prettyFile);
        setIsJson(true);
      } catch {
        setIsJson(false);
        setFileContent(file);
        setOriginalFileContent(file);
      }
      success(`File retrieved!`);
    } catch (e: any) {
      error(e.message);
    }
    dispatch(appSlice.actions.resume());
  }

  const onSave = async() => {
    dispatch(appSlice.actions.suspend());
    try {
      let value = '';
      try {
        value = prettifyFile(fileContent);
      } catch {
        throw new Error(`Error (saveFile): Invalid JSON.`);
      }
      await api.updateFile({bucket: bucket, key: key, value: fileContent});
      setOriginalFileContent(fileContent);
      success(`File updated!`);
    } catch (e: any) {
      error(e.message);
    }
    dispatch(appSlice.actions.resume());
  }

  const getDisabled = !key || !bucket;

  const saveEnabled = fileContent !== originalFileContent;

  return (
    <Main
      title='Sudo functionality'
      subtitle='For developers by developers'
    >
      <InputsContainer clear>
        <Dropdown 
          items={buckets} 
          title='File bucket'
          value={bucket}
          onChange={setBucket}
        />
        <HGap size={8} />
        <TextInput 
          label='File key'
          value={key}
          onChange={setKey}
        />
        <Button variant='success' onClick={onGet} disabled={getDisabled}>Get File</Button>
      </InputsContainer>
      <VGap size={8} />
      <TextareaContainer>
        <Textarea 
          disabled={!originalFileContent}
          label='File'
          value={fileContent}
          onChange={setFileContent}
        />
      </TextareaContainer>
      <br />
      <Button variant='success' onClick={onSave} disabled={!saveEnabled}>Save File</Button>
    </Main>
  );
}