import { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Accordion } from '../../../../components/Accordion';
import { Col, HGap, Row } from '../../../../components/Layout';
import { Modal } from '../../../../components/Modal';
import { TextInput } from '../../../../components/TextInput';
import { Crud, Permission } from '../../../../lib/SvsAcl';
import { useACL } from '../../../hooks/useSVSCognito';
import { ControlsHeader } from './ControlsHeader';
import { Main } from '../../../layout/Main';
import { getEnv } from '../../../../lib/getEnv';

const BtnsContainer = styled(Row)`
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 16px;
`;

const ModalContent = styled(Col)`

`;

interface Props {
  
}

export const Permissions = ({  }: Props) => {

  const env = getEnv();

  const { getPermissions, addPermission, deletePermission, getGroupsWithPermission } = useACL({env});

  const [deleteName, setDeleteName] = useState('');

  const [showModal, setShowModal] = useState(false);

  const [deleteCopy, setDeleteCopy] = useState<string>();

  const [filteredPerms, setFilteredGroups] = useState(getPermissions());

  const onDeletePermission = useCallback((name: string) => {
    return () => {
      setDeleteName(name);
      setShowModal(true);
      const groups = getGroupsWithPermission(name);
      setDeleteCopy(`Deleting this permission will affect ${groups.length} groups. Listed below:
      ${groups.join('\n')}`)
    }
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const onDeleteConfirm = useCallback(async() => {
    if (!deleteName) {
      console.error(`Error (Permission): Cannot 'onDeleteConfirm'. Permission to delete not selected.`);
      return;
    }
    setDeleteCopy('')
    await deletePermission(deleteName);
    setFilteredGroups(getPermissions());
    closeModal();
  }, [deleteName]);

  const onAddPermission = async(name: string) => {
    await addPermission(name);
    setFilteredGroups(getPermissions());
  }

  return (
    <Main
      title='Cognito'
      subtitle='Create new permissions'
    >
      <ControlsHeader
        target='Permission'
        onAdd={onAddPermission}
        list={getPermissions()}
        onFilter={(list) => setFilteredGroups(list as Permission[])}
      />
      <hr />
      <Col>
        {
          filteredPerms.map(permission => (
            <BtnsContainer key={permission.name}>
              <TextInput 
                value={permission.name}
                onChange={() => {}}
                disabled
              />
              <HGap size={12} />
              <Button variant='danger' onClick={onDeletePermission(permission.name)}>Delete Permission</Button>
            </BtnsContainer>
          ))        
        }
      </Col>
      <Modal
        title={`Are you sure you want to delete '${deleteName}' permission?`}
        show={showModal}
        onClose={closeModal}
        submitBtn={{
          onPress: onDeleteConfirm,
          text: 'Confirm',
          variant: 'primary'
        }}
        cancelBtn={{
          onPress: closeModal,
          text: 'Close',
          variant: 'info'
        }}
      >
        <ModalContent>
          {deleteCopy}
        </ModalContent>
      </Modal>
    </Main>
  )
}