import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Col, HGap, Row, VGap } from "../../../../components/Layout";
import { TextInput } from "../../../../components/TextInput";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useToast } from "../../../../components/Toast";
import { useDispatch } from "react-redux";
import { appSlice } from "../../../state/appSlice";
import { api } from "../../../api/PMToolApi";
import { Dropdown } from "../../../../components/Dropdown";
import { useSVSCognito } from "../../../hooks/useSVSCognito";
import { walletAddress } from "@storyverseco/svs-types";
import { Main } from "../../../layout/Main";
import { assets } from "../../../../assets";
import { Title } from "../../../../components/Text";
import { getConfig } from "../../../../Config";

/*
  export interface NFTStoryMetadata {
  walletAddress: string;
  storyId: number;
  createdAt: number;
  templateId: string;
  storyName: string;
}
*/

const Container = styled(Col)`

`;

const RowContainer = styled(Row)<{
  first: boolean;
}>`
  margin-bottom: 8px;
  max-height: 80px;
  min-height: ${({first}) => first ? 100 : 80}px;
`;

const InputsContainer = styled(Row)`
  margin-bottom: 12px;
`;

const IconsContainer = styled(Row)`
`;

const Icon = styled.img`
  cursor: pointer;
  display: flex;
  width: 40px;
  height: 40px;
`;



const getRows = (data: any[]) => {
  return data.map(({storyName, walletAddress, storyId, createdAt}, i) => {
    const showLabel = i === 0 || undefined;
    return [
      <RowContainer key={walletAddress+i} first={i === 0}>
        <Row flex={.6} clear>
          <TextInput 
            disabled
            label={showLabel && 'Story Name'}
            value={storyName}
            onChange={() => {}}
          />
        </Row>
        <HGap size={8} />
        <TextInput 
          disabled
          label={showLabel && 'Author Wallet Address'}
          value={walletAddress}
          onChange={() => {}}
        />
        <HGap size={8} />
        <Row flex={.3} clear>
          <TextInput 
            disabled
            label={showLabel && 'Story Id'}
            value={storyId}
            onChange={() => {}}
          />
        </Row>
        <HGap size={8} />
        <Row flex={.6} clear>
          <TextInput 
            disabled
            label={showLabel && 'Created At'}
            value={new Date(createdAt).toLocaleString()}
            onChange={() => {}}
          />
        </Row>
        <HGap size={8} />
        <Col centerH flex={.5} clear>
          {showLabel && <Title bold>Story links</Title>}
          <IconsContainer center clear>
            <OverlayTrigger overlay={<Tooltip id={'viewer'}>Story Viewer</Tooltip>}>
              <Icon src={assets.view} onClick={async() => {
                const config = await getConfig();
                const url = `${config.globals.urls.viewer}/${walletAddress}/${storyId}/n`;
                window.open(url, '_blank');
              }} />
            </OverlayTrigger>
            <HGap size={12} line />
            <OverlayTrigger overlay={<Tooltip id={'creator'}>Story Creator</Tooltip>}>
              <Icon src={assets.edit} onClick={async() => {
                const config = await getConfig();
                const url = `${config.globals.urls.creator}/nft/${walletAddress}/${storyId}`;
                window.open(url, '_blank');
              }} />
            </OverlayTrigger>
          </IconsContainer>
        </Col>
      </RowContainer>,
      <hr />
    ]
  })
}

const defaultEmptyUser = {
  name: '',
  walletAddress: '',
}

export const ViewPublishedStories = () => {

  const [stories, setStories] = useState<any[]>();

  const [search, setSearch] = useState('');

  const [filter, setFilter] = useState('');
  
  const [users, setUsers] = useState([defaultEmptyUser]);

  const { success, error } = useToast();

  const { getUser } = useSVSCognito();

  useEffect(() => {
    const fetchStories = async() => {
      try {
        const nftStories = await fetchStoriesFromMetadata();
        console.log({nftStories});
        setStories(Object.values(nftStories));
        success(`NFT stories data fetched!`);
      } catch(e) {
        error(`Error: Failed to fetch NFT stories.`);
      }
    }

    if (!stories) {
      fetchStories();
    }
  }, []);

  useEffect(() => {
    if (stories) {
      const newUsers = stories.map(s => {
        const user = getUser({walletAddress: s.walletAddress});
        if (!user) {
          return undefined;
        }
        return {
          name: user.name,
          walletAddress: s.walletAddress,
        }
      }).filter(Boolean) as typeof users;
      setUsers([defaultEmptyUser, ...new Set(newUsers)]);
    }
  }, [stories]);

  const defStories = stories || [];

  const filteredStories = useMemo(() => {
    if (filter === '' || users.length === 1) {
      return defStories;
    }

    const user = users.find(u => u.name === filter);
    
    return defStories.filter(s => s.walletAddress === user?.walletAddress);
  }, [filter, defStories, users]);

  const finalStories = useMemo(() => {
    return filteredStories.filter(s => s.storyName.toLowerCase().includes(search.toLowerCase()));
  }, [filteredStories, search]);
  

  const rows = getRows(finalStories);

  return (
    <Main
      title='Published Collectible Stories'
      subtitle='See all published collectible stories in the wild'
    >
      <InputsContainer clear>
        <Row></Row>
        <Row>
          <Dropdown 
            items={users.map(u => u.name)}
            title='Filter'
            value={filter}
            onChange={setFilter}
          />
          <HGap size={8} />
          <TextInput 
            label='Search'
            value={search}
            onChange={setSearch}
          />
        </Row>
      </InputsContainer>
      {rows}
    </Main>
  );
}

const fetchStoriesFromMetadata = async() => {
  const { saleData } = await getConfig();
  
  const salesWithStoryLinked = Object.values(saleData).filter((sale: any) => {
    return sale.nftWalletAddress && sale.nftStoryId;
  });

  const storieMetadataFromSale = salesWithStoryLinked.reduce((res, cur: any) => {
    const key = `${cur.nftWalletAddress}/${cur.nftStoryId}`;
    return {
      ...res,
      [key]: {
        walletAddress: cur.nftWalletAddress,
        storyId: cur.nftStoryId,
        createdAt: Date.now(),
        templateId: "0",
        storyName: cur.saleName,
      }
    }
  }, {})

  return storieMetadataFromSale;
}