import styled from "styled-components";
import { Box, Row } from "../../components/Layout";
import { Routes, useNavigation } from "../navigation/useNavigation";
import { assets } from "../../assets";
import { colors } from "../../lib/styles";
import { Dropdown } from "../../components/Dropdown";
import { useCallback, useMemo } from "react";
import { DropdownButton, Dropdown as RBDropdown } from 'react-bootstrap';
import { useSVSCognito } from "../hooks/useSVSCognito";
import { useLogin } from "../hooks/useLogin";
import { WalletAddress } from "@storyverseco/svs-types";
import { getEnv } from "../../lib/getEnv";

const Container = styled(Row)`
  flex: initial;
  height: 60px;
  background: ${colors.darkBlue};
  padding: 8px;
  justify-content: space-between;
`;

const Logo = styled(Row)`
  margin-left: 8px;
  padding: 2px 0px; 
  flex: initial;
`;

const LogoText = styled.p`
  color: white;
  font-size: 40px;
  font-weight: bold;
  font-transform: uppercase;
  margin: 0px;
  padding: 0px;
  margin-left: 8px;
`;

const LogoImg = styled.img`
  height: 100%;
`;

const EnvSelector = styled(Row)`
  flex: initial;
`;

const EnvText = styled(LogoText)<{
  color?: string;
}>`
  font-size: 30px;
  margin: 0px;
  margin: 0px 4px;
  color: ${({color}) => color ?? 'white'};
`;

const UserProfile = styled(Row)`
  flex: initial;
  margin-right: 4px;
  cursor: pointer;
  .dropdown-toggle {
    background: none;
    border: none;
  }
`;

const UserBall = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${colors.purple};
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
`;

const dropdownStyle = {
  background: 'none',
  color: 'white',
  fontWeight: 'bold',
  border: 'none',
  fontSize: '24px',
}

const colorByEnv = {
  dev: colors.success,
  staging: colors.warning,
  prod: colors.danger,
}

const upperFirst = (word: string) => `${word[0].toUpperCase()}${word.substring(1)}`;

const envNames = [
  'Dev', 
  'Staging', 
  'Prod',
];


export const Header = () => {

  const { route, navigate } = useNavigation();

  const env = getEnv();

  const onEnvChange = useCallback((newEnv: string) => {
    const urlNoQueryParams = window.location.href.split('?')[0];
    window.location.href = `${urlNoQueryParams}?env=${newEnv.toLowerCase()}`;
  }, []);

  const { getUser, cognitoReady } = useSVSCognito();
  const { wallet, logout } = useLogin('header');

  const username = useMemo(() => {
    if (!wallet?.address || !cognitoReady) {
      return 'loading';
    }
    return getUser({walletAddress: wallet.address as WalletAddress})?.name || 'unknown';
  }, [wallet?.address, cognitoReady]);

  const initials = useMemo(() => {
    if (username === 'loading' || username === 'unknown') {
      return '?';
    }
    const [first, last] = username.toUpperCase().split(' ');
    return `${first[0]}${last ? `${last[0]}` : ''}`;
  }, [username]);

  const onLogout = useCallback(async() => {
    await logout();
    navigate(Routes.HOME);
  }, []);

  // Render
  if (route === Routes.HOME) {
    return null;
  }

  return (
    <Container>
      <Logo centerV>
        <LogoImg src={assets.logo} />
        <LogoText>PMTool</LogoText>
      </Logo>

      <EnvSelector centerV>
        <EnvText>You are on</EnvText>
        <Dropdown 
          style={{...dropdownStyle, color: colorByEnv[env]}}
          value={upperFirst(env)}
          items={envNames}
          onChange={onEnvChange}
        />
        <EnvText>environment</EnvText>
      </EnvSelector>

      <UserProfile centerV>
        <UserBall center>{initials}</UserBall>
        <DropdownButton id="user-options" title={username}>
          <RBDropdown.Item>Profile</RBDropdown.Item>
          <RBDropdown.Item onClick={onLogout}>Logout</RBDropdown.Item>
        </DropdownButton>
      </UserProfile>
    </Container>
  );
}