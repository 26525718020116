import { useEffect, useState } from 'react';
import { useACL, useSVSCognito } from './useSVSCognito';
import { useLogin } from './useLogin';

// Use this to export logged in user related info
export const useProfile = () => {
  const [isReady, setIsReady] = useState(false);

  const { wallet } = useLogin('profile');

  const { cognitoReady } = useSVSCognito();

  const { aclReady } = useACL();

  useEffect(() => {
    setIsReady(aclReady && cognitoReady);
  }, [cognitoReady, aclReady]);

  return {
    profileReady: isReady,
    wallet,
  };
};