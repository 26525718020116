import { useMemo } from "react";
import styled from "styled-components";
import { Row } from "./Layout";

const Container = styled(Row)`
  height: 200px;
`;

const Image = styled.img`
  height: 100%;
`;

interface Props {
  src: string;
  show: boolean;
}

export const PickerImgItem = ({
  src,
  show,
}: Props) => {

  const image = useMemo(() => <Image src={src} />, [src]);

  return (
    <Container center hide={!show}>
      {image}
    </Container>
  )
}