import { useEffect, useMemo, useState, useCallback } from "react";
import styled from "styled-components";
import { Col, HGap, Row, VGap } from "../../../../components/Layout";
import { TextInput } from "../../../../components/TextInput";
import { Button } from "react-bootstrap";
import { useToast } from "../../../../components/Toast";
import { useDispatch } from "react-redux";
import { appSlice } from "../../../state/appSlice";
import { api } from "../../../api/PMToolApi";
import { Main } from "../../../layout/Main";

const getSortedWriters = (writers: Record<string, string>) => {
  return Object.keys(writers).sort((a, b) => {
    const v1 = writers[a];
    const v2 = writers[b];
    return v1.localeCompare(v2);
  }).reduce((res, cur) => ({...res, [cur.toLowerCase()]: writers[cur] }), {})
}

const InputsContainer = styled(Row)`

`;

export const StoryWriters = () => {

  const [walletAddress, setWalletAddress] = useState('');

  const [authorName, setAuthorName] = useState('');

  const { success, error } = useToast();

  const dispatch = useDispatch()

  const [writers, setWriters] = useState<Record<string, string>>({});

  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    const fetchWriters = async() => {
      const response = await api.getNFTWriters();
      setWriters(response);
    }

    fetchWriters();
  }, []);

  const onAddWriter = async() => {
    dispatch(appSlice.actions.suspend());
    try {
      const newWriters = getSortedWriters({
        ...writers,
        [walletAddress]: authorName,
      });
      await api.updateNFTWriters({writers: newWriters});
      setWalletAddress('');
      setAuthorName('');
      setWriters(newWriters);
      success(`Writer '${authorName}' has been added!`);
    } catch (e: any) {
      error(e.message);
    }
    dispatch(appSlice.actions.resume());
  }  

  const writersList = useMemo(() => {
    return Object.keys(writers).map(walletAddress => `${writers[walletAddress]}: ${walletAddress}`);
  }, [writers]);

  useEffect(() => {
    const walletAlreadyInUse = writers[walletAddress];

    if (walletAlreadyInUse) {
      error(`This wallet address is already in use.`, 1);
      setCanSave(false);
      return;
    }

    const authors = Object.values(writers);

    const uniqueName = authors.includes(authorName);
   

    if (uniqueName) {
      error(`The author name must be unique.`, 1);
      setCanSave(false);
      return;
    }

    setCanSave(authorName !== '' && walletAddress !== '');
  }, [authorName, walletAddress, writers]);

  return (
    <Main
      title='NFT Story Writers'
      subtitle='Story writers can see "stages" and save NFT stories'
    >
      <InputsContainer clear>
        <TextInput 
          label='Author Wallet Address'
          value={walletAddress}
          onChange={setWalletAddress}
        />
        <HGap size={8} />
        <TextInput 
          label='Author Name'
          value={authorName}
          onChange={setAuthorName}
        />
        <Button variant='success' onClick={onAddWriter} disabled={!canSave}>Add Writer</Button>
      </InputsContainer>
      <VGap size={8} />
      <ul>
        {
          writersList.map(v => (<li>{v}</li>))
        }
      </ul>
    </Main>
  );
}