import styled from 'styled-components';
import { Row, Col } from './Layout';
import { Title } from './Text';
import { colors } from '../lib/styles';
import { ChangeEvent, CSSProperties } from 'react';

const Container = styled(Col)`
  
`;  

const Input = styled.textarea`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${colors.grey};
`;

const InputContainer = styled(Row)`
  width: 100%;
`;

interface Props {
  label?: string;
  value: string;
  onChange: (value: string) => void;
  style?: CSSProperties;
  disabled?: boolean;
  center?: boolean;
}

export const Textarea = ({ 
  label,
  onChange,
  value,
  style,
  disabled,
  center = true,
}: Props) => {
  const onWalletAddressChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(evt.target.value);
  }

  return (
    <Container center={center}>
      {label && <Title bold>{label}</Title>}
      <InputContainer>
        <Row>
          <Input 
            disabled={disabled}
            style={style}
            value={value}
            onChange={onWalletAddressChange}
          />
        </Row>
      </InputContainer>
    </Container>
  )
}