import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Dropdown } from '../../../../components/Dropdown';
import { Col, Row, VGap } from '../../../../components/Layout';
import { useToast } from '../../../../components/Toast';
import * as cfg from '@storyverseco/svs-config-client';
import { useLogin } from '../../../hooks/useLogin';
import { api, MintStatus } from '../../../api/PMToolApi';
import { Main } from '../../../layout/Main';
import { useNavbar } from '../../../hooks/useNavbar';
import { getEnv } from '../../../../lib/getEnv';

const Container = styled(Col)``;

const BtnContainer = styled(Row)`
  justify-content: flex-end;
`;

const SubmitBtn = styled(Button)`
  height: 100%;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
`;

type MintBtnConfig = {
  label: string;
  variant: string;
  disabled: boolean;
};

const mintBtnConfigMap: Record<MintStatus, MintBtnConfig> = {
  [MintStatus.Idle]: {
    label: 'Mint',
    variant: 'success',
    disabled: false,
  },
  [MintStatus.Signing]: {
    label: 'Signing...',
    variant: 'info',
    disabled: true,
  },
  [MintStatus.Preparing]: {
    label: 'Preparing...',
    variant: 'info',
    disabled: true,
  },
  [MintStatus.Minting]: {
    label: 'Minting...',
    variant: 'info',
    disabled: true,
  },
  [MintStatus.Waiting]: {
    label: 'Waiting...',
    variant: 'info',
    disabled: true,
  },
  [MintStatus.Minted]: {
    label: 'Minted!',
    variant: 'success',
    disabled: true,
  },
  [MintStatus.Errored]: {
    label: 'Error!',
    variant: 'danger',
    disabled: true,
  },
};

export const MintInput = () => {
  const [projects, setProjects] = useState<string[]>([]);

  const [selectedProject, setSelectedProject] = useState('');
  const [mintStatus, setMintStatus] = useState(MintStatus.Idle);

  const { success, error } = useToast();

  const env = getEnv()

  const { wallet } = useLogin('mint');

  const { navbar, navbarReady } = useNavbar('mint');

  // Load projects
  useEffect(() => {
    if (!env) {
      return;
    }
    const getProjectsFromConfig = async () => {
      console.log({ env });
      const config = await cfg.stage(env);
      const projects = Object.keys(config.globals.genericNFT);
      setProjects(projects);
      setSelectedProject(projects[0]);
    };
    getProjectsFromConfig();
  }, [env]);

  const onMintPress = async () => {
    if (!wallet || !wallet.address|| !navbarReady) {
      return;
    }

    try {
      const mintSuccess = await api.mintNFT(
        navbar,
        wallet.address,
        selectedProject,
        setMintStatus
      );
      if (!mintSuccess) {
        throw new Error('Unknown error (check console');
      }

      success('Minted!');
      setMintStatus(MintStatus.Minted);
    } catch (e: any) {
      console.error('error during mint:', e);
      error(e.message);
      setMintStatus(MintStatus.Errored);
    }

    // Reset so we can mint/try again
    setTimeout(() => {
      setMintStatus(MintStatus.Idle);
    }, 1000);
  };

  const mintBtnConfig = mintBtnConfigMap[mintStatus];

  return (
    <Main
      title='Character Pass'
      subtitle='Mint character pass NFT token'
      clear
    >
      <Dropdown
        value={selectedProject}
        onChange={setSelectedProject}
        items={projects}
        style={{ fontSize: 24, paddingLeft: 12 }}
      />
      <VGap size={24} />
      <BtnContainer>
        <SubmitBtn
          disabled={!selectedProject || !wallet || mintBtnConfig.disabled}
          onClick={onMintPress}
          variant={mintBtnConfig.variant}
        >
          {mintBtnConfig.label}
        </SubmitBtn>
      </BtnContainer>
    </Main>
  );
};
