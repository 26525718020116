import { useNavigate, useLocation } from "react-router-dom";

export enum Routes {
  ADMIN = 'admin',
  LOGIN = 'login',
  HOME = '',
  // TEMPLATES = 'templates',
  TOOLS = 'tools',
  ROOT = 'root',
  // STORY
  STORY = 'story',
  STORY_PUBLISHED = 'story/published',
  STORY_CREATE = 'story/create',
  STORY_ACL = 'story/acl',
  STORY_REVEAL_SCHEDULE = 'story/reveal',
  STORY_PROMOTE = 'story/promote',
  STORY_WRITERS = 'story/writers',
  STORY_ATTRIBUTE_MAPPING = 'story/attributemapping',
  // User assets
  STORY_USER = 'story/user',
  // CHARACTER PASS
  CP_MINT = 'cp/mint',
  // TOOLS
  TOOL_VALIDATE_STORY = 'tools/validatestory',
  // TWITTER
  TWIITER_DM = 'twitter/dm',
  // COGNITO
  COGNITO_USERS = 'cognito/users',
  COGNITO_GROUPS = 'cognito/groups',
  COGNITO_PERMISSIONS = 'cognito/permissions',
  // SUDO
  SUDO_FILE_EDITOR = 'sudo/files',
  SUDO_POSTMAN = 'sudo/postman',
  // CONFIG
  CONFIG_SALE_DATA = 'config/saledata',
  CONFIG_RAFFLE_PAGE_UPDATE = 'config/raffle/update',
  CONFIG_RAFFLE_PAGE = 'config/raffle',
  CONFIG_LOADING = 'config/loading',
  CONFIG_DEFAULT_STORIES_ACL = 'config/default/stories/acl',
  // MEDIA
  MEDIA_MAINSITE = 'media/mainsite',
}

export const useNavigation = () => {

  const nav = useNavigate();
  const { search, pathname } = useLocation();

  const navigate = (route: Routes) => {
    nav(`/${route}${search}`);
  }

  const navigateWithPathParams = (route: Routes, params: string[]) => {
    const newRoute = `/${route}/${params.join('/')}${search}`;
    console.log({newRoute});
    nav(newRoute);
  }

  return { 
    navigate,
    route: pathname.substring(1) as Routes,
    navigateWithPathParams,
    search,
  }
}