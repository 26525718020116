import { ReactNode, useMemo } from "react"
import styled from "styled-components";
import { Box, Col, Row } from "../../components/Layout";
import { Sidebar } from "../Sidebar/Sidebar";
import { Header } from "./Header";
import { useSelector } from "react-redux";
import { getShowAppLayout } from "../state/selectors";

const Container = styled(Col)`
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
`;

const Content = styled(Row)`
  height: 100%;
`;


const Main = styled(Box)<{
  appPadding: boolean
}>`
  padding: ${({appPadding}) => appPadding ? '2px' : 'initial' };
`;

interface Props {
  children: ReactNode;
  overlay?: boolean;
}

export const Layout = ({children}: Props) =>  {
  const showApp = useSelector(getShowAppLayout);

  return (
    <Container clear>
      {showApp && <Header />}
      <Content clear>
        {showApp && <Sidebar />}
        <Main appPadding={showApp}>
          {children}
        </Main>
      </Content>
    </Container>
  );
}