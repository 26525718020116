import { ChangeEvent, useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { Col } from '../../../../components/Layout';
import { Modal } from '../../../../components/Modal';
import { Optional, Nullable } from '../../../../lib/types';
import {
  isStoryValid,
  getValidationError,
  IStory,
} from '@storyverseco/svs-story-suite';
import { BaseText } from '../../../../components/Text';
import { colors } from '../../../../lib/styles';
import { Main } from '../../../layout/Main';

const Container = styled(Col)`
  padding-top: 16px;
`;

const ModalContent = styled(Col)``;

const ErrorText = styled(BaseText)`
  margin-top: 8px;
  color: ${colors.danger};
`;

const SuccessText = styled(BaseText)`
  margin-top: 8px;
  color: ${colors.success};
`;

const StoryInput = styled.textarea`
  margin-left: 4px;
  margin-top: 4px;
`;

export const ValidateInputStory = () => {
  const [showModal, setShowModal] = useState(false);

  const [input, setInput] = useState('');

  // undefined for unset, null for no error, string for error message
  const [error, setError] = useState<Optional<Nullable<string>>>();

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
    setInput('');
    setError(undefined);
  }, []);

  const onValidate = useCallback(() => {
    try {
      const story = JSON.parse(input) as IStory;

      if (isStoryValid(story)) {
        setError(null);
      } else {
        setError(getValidationError(story)?.message);
      }
    } catch {
      setError(`Error: input is not a valid JSON`);
    }
  }, [input]);

  const onStoryInputChange = useCallback(
    (evt: ChangeEvent<HTMLTextAreaElement>) => {
      setInput(evt.target.value);
    },
    []
  );

  return (
    <Main
      title='Tools'
      subtitle='Validate story data'
    >
      <Container>
        <Button onClick={openModal}>Validate Input Story</Button>
      </Container>
      <Modal
        title={'Validate Input Story'}
        show={showModal}
        onClose={closeModal}
        submitBtn={{
          onPress: onValidate,
          text: 'Validate',
          variant: 'primary',
        }}
        cancelBtn={{
          onPress: closeModal,
          text: 'Close',
          variant: 'info',
        }}
      >
        <ModalContent>
          <StoryInput value={input} onChange={onStoryInputChange} />
          {error && <ErrorText>{error}</ErrorText>}
          {error === null && <SuccessText>Sucess: Story is valid!</SuccessText>}
        </ModalContent>
      </Modal>
    </Main>
  );
};
