import { ConfigClient } from '@storyverseco/svs-config-client';
import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TwtAuth1Creds, isTwtAuth1Creds } from '../../lib/types';
import { isStringArray } from '../../lib/utils';
import { appSlice } from '../state/appSlice';
import { getTwtInittedFromQuery, getTwtAuth1Creds } from '../state/selectors';
import { getConfig } from '../../Config';
import { Routes } from '../navigation/useNavigation';
import { getEnv } from '../../lib/getEnv';

enum StorageKey {
  Auth1Creds = 'svs.pmt.tw.dmt.a:1.0.0',
}

const getStorageKey = (storageKey: StorageKey): string =>
  `${storageKey}:${getEnv()}`;

enum TwtAuthVersion {
  Auth1ReadWrite = 'v1',
  Auth1Read = 'v1read',
  Auth2 = 'v2',
  Auth1DM = 'v1dm',
}

/**
 * ```
 * [
 *   userId,
 *   userName,
 *   accessToken,
 *   accessTokenSecret,
 *   authVersion?,
 *   usageTypes[]?
 * ]
 * ```
 */
type AuthValue = [
  string,
  string,
  string,
  string,
  string | null | undefined,
  string[] | null | undefined
];

const isAuthValue = (obj: any): obj is AuthValue =>
  obj &&
  Array.isArray(obj) &&
  obj.length >= 4 &&
  obj.every((item, index) => {
    if (index < 4) {
      return typeof item === 'string';
    }
    if (index === 4) {
      const itemType = typeof item;
      return itemType === 'string' || item === null || itemType === 'undefined';
    }
    if (index === 5) {
      const itemType = typeof item;
      return item === null || itemType === 'undefined' || isStringArray(item);
    }
    // unexpected length but pass as valid
    return true;
  });

const authValueToAuth1Creds = (authValue: AuthValue): TwtAuth1Creds => ({
  twitterUserId: authValue[0],
  twitterUserName: authValue[1],
  accessToken: authValue[2],
  accessTokenSecret: authValue[3],
  authVersion: authValue[4] ?? TwtAuthVersion.Auth1ReadWrite,
  usageTypes: authValue[5] ?? [],
});

const encodeAuth1Creds = (auth1Creds: TwtAuth1Creds): string =>
  JSON.stringify(auth1Creds);

const decodeAuth1Creds = (
  encodedCreds: string | null
): TwtAuth1Creds | null => {
  if (!encodedCreds) {
    return null;
  }
  let obj: unknown;
  try {
    obj = JSON.parse(encodedCreds);
  } catch (e) {
    console.warn('decodeAuth1Creds: Cannot parse JSON:', e);
    return null;
  }

  if (!isTwtAuth1Creds(obj)) {
    console.warn('decodeAuth1Creds: unexpected data structure');
    return null;
  }

  return obj;
};

const saveAuth1Creds = (auth1Creds: TwtAuth1Creds | null) => {
  try {
    if (auth1Creds) {
      window.localStorage.setItem(
        getStorageKey(StorageKey.Auth1Creds),
        encodeAuth1Creds(auth1Creds)
      );
    } else {
      window.localStorage.removeItem(getStorageKey(StorageKey.Auth1Creds));
    }
  } catch (e) {
    console.warn('saveAuth1Creds error:', e);
  }
};

const loadAuth1Creds = (): TwtAuth1Creds | null => {
  try {
    const str = window.localStorage.getItem(
      getStorageKey(StorageKey.Auth1Creds)
    );
    return decodeAuth1Creds(str);
  } catch (e) {
    console.warn('loadAuth1Creds error:', e);
    return null;
  }
};

const getAuth1CredsFromQuery = (): TwtAuth1Creds | null => {
  const queryParams = new URLSearchParams(window.location.search);
  const authValueStr = queryParams.get('auth');
  if (!authValueStr) {
    return null;
  }

  let authValue: unknown | null = null;
  try {
    authValue = JSON.parse(authValueStr);
  } catch (e) {
    console.warn('getAuth1CredsFromQuery: unable to parse "auth" query param');
    return null;
  }

  if (!isAuthValue(authValue)) {
    console.warn(
      'getAuth1CredsFromQuery: unexpected data structure from "auth" query param'
    );
    return null;
  }

  return authValueToAuth1Creds(authValue);
};

export const useTwitterAuth1DM = () => {
  const dispatch = useDispatch();
  const queryInitted = useSelector(getTwtInittedFromQuery);
  const auth1Creds = useSelector(getTwtAuth1Creds);
  const [config, setConfig] = useState<ConfigClient | null>(null);

  useEffect(() => {
    if (!queryInitted) {
      // from query params
      const queryCreds = getAuth1CredsFromQuery();
      if (queryCreds) {
        saveAuth1Creds(queryCreds);
        dispatch(appSlice.actions.setTwtAuth1Creds(queryCreds));
      }
      dispatch(appSlice.actions.setTwtInittedFromQuery(true));
      return;
    }

    // from local storage
    const storageCreds = loadAuth1Creds();
    if (storageCreds) {
      dispatch(appSlice.actions.setTwtAuth1Creds(storageCreds));
    }
  }, [queryInitted, dispatch]);

  useEffect(() => {
    getConfig()
      .then((c) => {
        setConfig(c);
      })
      .catch((e) => {
        console.error('useTwitterAuth1 getConfig error:', e);
      });
  }, []);

  const authorizeUrl = useMemo(() => {
    if (!config) {
      return '';
    }

    const childUrl = new URL(window.location.href);
    let parentUrlBase: string;
    if (childUrl.hostname === 'localhost') {
      parentUrlBase = 'http://localhost:3000';
    } else {
      parentUrlBase = window.location.origin;
    }

    const redirectUrl = new URL(parentUrlBase);
    redirectUrl.pathname = '/parent/index.html';
    redirectUrl.searchParams.append('childPath', `/${Routes.TOOLS}`);
    redirectUrl.searchParams.append('env', getEnv());

    const url = new URL(`${config.globals.urls.auth}/twitter/authorize/v1dm`);
    url.searchParams.append('redir', redirectUrl.toString());

    return url.toString();
  }, [config]);

  const authorize = () => {
    if (!window.top) {
      return;
    }
    window.top.location.href = authorizeUrl;
  };

  const clearAuth1Creds = () => {
    saveAuth1Creds(null);
    dispatch(appSlice.actions.setTwtAuth1Creds(null));
  };

  return {
    auth1Creds,
    clearAuth1Creds,
    authorize,
    authorizeUrl,
  };
};
