import styled, { keyframes} from 'styled-components';
import { assets } from '../assets';

const Container = styled.div`
  overflow:hidden;
  width: 100%;
  height: 100%;
  display: flex;
  background: #18013f;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -36px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Logo = styled.img`
  width: 80%;
  margin: 12px 0;
  animation: ${rotate} 5s linear infinite;
`;

const Title = styled.p`
  margin: 0;
  font-size: 100px;
  color: white;
  text-align: center;
  font-weight: bold;
  margin-bottom: 36px;
`;

// We don't actually want to display this, but we want the design to match the login page in the other app
const LoginText = styled(Title)`
  font-size: 30px;
  margin-top: 36px;
  border-bottom: solid 1px;
  cursor: pointer;
  opacity: 0.01;
`;

export const LoginStatic = () => {
  return (
    <Container>
      <Content>
        <Title>PM Tool</Title>
        <Logo src={assets.logo} />
        <LoginText>A</LoginText>
      </Content>
    </Container> 
  );
}