import styled from "styled-components";
import { colors } from "../lib/styles";

export const BaseText = styled.p<{
  fontSize?: number;
  color?: string;
  bold?: boolean;
  center?: boolean;
}>`
  margin: 0;
  padding: 0;
  font-size: ${({fontSize}) => fontSize || 16}px;
  color: ${({color}) => color || colors.black};
  font-weight: ${({bold}) => bold && 'bold' || 'initial'};
  text-align: ${({center}) => center && 'center' || 'initial'};
`;

export const Title = styled(BaseText)<{
  upper?: boolean;
}>`
  font-size: ${({fontSize}) => fontSize || 24}px;
  ${({upper}) => upper && `text-transform: uppercase`}
`;