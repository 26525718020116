import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { assets } from '../../../assets';
import { useToast } from '../../../components/Toast';
import { useNavigation, Routes } from '../../navigation/useNavigation';
import { appSlice } from '../../state/appSlice';
import { useLogin } from '../../hooks/useLogin';
import { useSearchParams } from 'react-router-dom';
import { api } from '../../api/PMToolApi';
import { SvsProvider } from '@storyverseco/svs-navbar-client';
import { getRedirect } from '../../state/selectors';

const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  background: #18013f;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -36px;
`;

const Logo = styled.img`
  width: 80%;
  margin: 12px 0;
`;

const Title = styled.p`
  margin: 0;
  font-size: 100px;
  color: white;
  text-align: center;
  font-weight: bold;
  margin-bottom: 36px;
`;

const LoginText = styled(Title)`
  font-size: 30px;
  margin-top: 36px;
  border-bottom: solid 1px;
  cursor: pointer;
`;

export const LoginPage = () => {
  const dispatch = useDispatch();

  const { navigate } = useNavigation();

  const [searchParams] = useSearchParams();

  const redirect = useSelector(getRedirect);

  const { isLoading, loggedIn, wallet, login, logout, getWallet, loginReady } = useLogin('login');

  const { error, success } = useToast();

  useEffect(() => {
    if (isLoading) {
      dispatch(appSlice.actions.suspend());
    } else {
      dispatch(appSlice.actions.resume());
    }
  }, [isLoading]);

  console.log({wallet});

  // Redirect user to assembler page when we have the wallet data
  useEffect(() => {
    const onWalletSuccess = async () => {
      await dispatch(appSlice.actions.setWallet(wallet));    
      try {
        await api.auth();
        success(`User logged in!`);
        const startPath = searchParams.get('startPath');
        let toRoute = Routes.STORY_USER;
        if (startPath && startPath.startsWith('/')) {
          const maybeRoute = startPath.substring(1);
          if (Object.values<string>(Routes).includes(maybeRoute)) {
            toRoute = maybeRoute as Routes;
          }
        }
        navigate(redirect || toRoute);
      } catch(e: any) {
        error(e.message);
        logout();
      }
    };

    if (wallet) {
      onWalletSuccess();
    }
  }, [wallet, searchParams]);

  useEffect(() => {
    if (loggedIn) {
      getWallet();
    }
  }, [loggedIn]);

  const onLogin = useCallback(() => {
    console.log('onLogin', {loggedIn});
    if (loggedIn) {
      getWallet();
      return;
    } else {
      login();
    }
  }, [loggedIn, loginReady]);

  const onLogout = useCallback(() => {
    logout();
  }, []);

  return (
    <Container>
      <Content>
        <Title>PM Tool</Title>
        <Logo src={assets.logo} />
        { loggedIn && <LoginText onClick={onLogout}>Logout</LoginText> }
        { !loggedIn && <LoginText onClick={onLogin}>Login</LoginText> }
      </Content>
    </Container>
  );
};
