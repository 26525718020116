interface Props {
  onSuccess: () => {};
  onError: () => {};
}

export const useCopyToClipboard = () => {

  const fallbackCopyTextToClipboard = (text: string) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var success = document.execCommand('copy');
      if (success) {
        // onSuccess();
      } else {
        // onError();
      }
    } catch (err) {
      // onError();
    }
  
    document.body.removeChild(textArea);
  }

  const copy = async(text: string) => {
    if (!navigator.clipboard) {
      return fallbackCopyTextToClipboard(text);
    }

    try {
      await navigator.clipboard.writeText(text);
    } catch {
      fallbackCopyTextToClipboard(text);
    }
  }

  return {
    copy,
  }
}