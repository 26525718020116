const logo = require('./logo.png');
const rafflePageTemplate = require('./raffle_page_template.png');
const view = require('./icon-view-file.png');
const edit = require('./icon-edit-file.png');

export const assets = {
  logo,
  rafflePageTemplate,
  view,
  edit,
  transfer: require('./icon-transfer.png'),
};
