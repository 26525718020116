import { useSelector } from 'react-redux';
import { Spinner as BSSpinner } from "react-bootstrap";
import styled from "styled-components";
import { Row } from "./Layout";
import { showPageLoading } from '../features/state/selectors';

const Backdrop = styled.div`
  position: fixed;
  display: flex;
  inset: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 9;
`;

const SpinnerContainer = styled(Row)`
  height: 100vh;
  width: 100vw;
  background: transparent;
  position: absolute;
`;

const Spinner = styled(BSSpinner)`
  color: white;
  z-index: 10;
  width: 75px;
  height: 75px;
`;

export const PageLoading = () => {

  const show = useSelector(showPageLoading);

  if (!show) {
    return null;
  }

  return (
    <>
      <Backdrop />
      <SpinnerContainer center>
        <Spinner animation="border" size='l' />
      </SpinnerContainer>
    </>
  )
}