import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ProjectTemplateData,
  Optional,
  Template,
} from '@storyverseco/svs-types';

interface TemplatesState {
  projects: ProjectTemplateData[];
  selectedProject?: ProjectTemplateData;
  initialTemplates?: Template[];
  templates: Template[];
  refetchProjects: boolean;
}

const initialState: TemplatesState = {
  projects: [],
  templates: [],
  refetchProjects: true,
};

const setProjects: CaseReducer<
  TemplatesState,
  PayloadAction<ProjectTemplateData[]>
> = (state, { payload }) => {
  state.projects = payload;
};

const setSelectedProject: CaseReducer<
  TemplatesState,
  PayloadAction<Optional<ProjectTemplateData>>
> = (state, { payload }) => {
  state.selectedProject = payload;
};

const setTemplates: CaseReducer<TemplatesState, PayloadAction<Template[]>> = (
  state,
  { payload }
) => {
  if (!state.initialTemplates) {
    state.initialTemplates = payload;
  }
  state.templates = payload;
};

const resetTemplateChanges: CaseReducer<
  TemplatesState,
  PayloadAction<'initial' | 'current'>
> = (state, { payload }) => {
  state.initialTemplates =
    payload === 'initial' ? initialState.initialTemplates : state.templates;
};

const revertTemplateChanges: CaseReducer<TemplatesState> = (state) => {
  state.templates = state.initialTemplates || [];
};

const onProjectsFetched: CaseReducer<TemplatesState> = (state) => {
  state.refetchProjects = false;
};

const shouldFetchProjects: CaseReducer<TemplatesState> = (state) => {
  state.refetchProjects = true;
};

export const templatesSlice = createSlice({
  name: 'templates',

  initialState,

  reducers: {
    setProjects,
    setSelectedProject,
    setTemplates,
    onProjectsFetched,
    shouldFetchProjects,
    resetTemplateChanges,
    revertTemplateChanges,
  },
});
