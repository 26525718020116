import { WalletAddress } from "@storyverseco/svs-types"

export interface Crud {
  create: boolean;
  read: boolean;
  update: boolean;  
  delete: boolean;
}

export interface Permission {
  name: string;
  crud: Crud;
}

export interface Group {
  name: string;
  permissions: Permission[];
}

export enum UserType {
  SVS = 'SVS',
  EXTERNAL_WRITER = 'EXTERNAL_WRITER',
}

export interface User {
  wallets: WalletAddress[];
  name: string;
  email?: string;
  type: UserType;
  group: Group;
  data?: Record<string, string>; // this can be used to store extra info. for example SlackId (used for notifications)
}


interface SVSACLProps {
  fetchPermissions: () => Promise<Permission[]>;
  fetchGroups: () =>  Promise<Group[]>;
  updatePermissions: (permissions: Permission[]) => Promise<any>;  
  updateGroups: (groups: Group[]) => Promise<any>;
}

export class SVSACL {

  isInit = false;

  // All available permissions
  private _permissions: Permission[] = [];

  get permissions() {
    return this._permissions;
  }

  private _groups: Group[] = [];

  get groups() {
    return this._groups;
  }
  
  constructor(private props: SVSACLProps) {}

  private async fetchPermissions(): Promise<Permission[]> {
    return this.props.fetchPermissions();
  }
  
  private async fetchGroups(): Promise<Group[]> {
    return this.props.fetchGroups();
  }
  
  private async savePermissions() {
    return this.props.updatePermissions(this._permissions);
  }
  
  private async saveGroups() {
    return this.props.updateGroups(this._groups);
  }  

  async init() {
    try {
      this._permissions = await this.fetchPermissions();
      this._groups = await this.fetchGroups();
      this.isInit = true;
    } catch(e: any) {
      console.error(`Error (SVSACL): failed to init`, e.message);
      throw e;
    }
  }
  
  getGroup(name: string) {
    return this._groups.find(g => g.name === name);
  }

  createGroup(name: string, permissions: Permission[] = this.permissions) {
    if (this.getGroup(name)) {
      throw new Error(`Error (SVSACL): Cannot 'createGroup'. Group '${name}' already exists.`);
    }

    this._groups.push({
      name, 
      permissions,
    });

    return this.saveGroups();
  }

  deleteGroup(name: string) {
    if (!this.getGroup(name)) {
      throw new Error(`Error (SVSACL): Cannot 'deleteGroup'. Group '${name}' not found.`);
    }

    this._groups = this.groups.filter(g => g.name !== name);

    return this.saveGroups();
  }

  editGroupPermission(groupName: string, permissionName: string, crud: Partial<Crud>) {
    const editGroup = this.getGroup(groupName);
    if (!editGroup) {
      throw new Error(`Error (SVSACL): Cannot 'editGroupPermission'. Group '${groupName}' not found.`);
    }

    const permission = this.getPermission(permissionName);
    if (!permission) {
      throw new Error(`Error (SVSACL): Cannot 'editGroupPermission'. Permission '${permissionName}' not found.`);
    }

    // Remove the existing entry from permission list
    editGroup.permissions = editGroup.permissions.filter(p => p.name !== permissionName);
    // Add it back but updated
    editGroup.permissions.push({
      ...permission,
      crud: {
        ...permission.crud,
        ...crud,
      }
    });

    return this.saveGroups();
  }

  getPermission(name: string) {
    return this._permissions.find(g => g.name === name);
  }

  // When adding a permission we have to add to all exising groups as well
  addPermission(name: string) {
    if (this.getPermission(name)) {
      throw new Error(`Error (SVSACL): Cannot 'addPermission'. Permission '${name}' already exists.`);
    }

    const newPermission = {
      name,
      crud: this._getDefaultCrud(),
    }

    this._permissions.push(newPermission);

    this._groups = this._groups.map(g => ({
      ...g,
      permissions: [
        ...g.permissions,
        newPermission
      ]
    }));

    return this.savePermissions().then(() => this.saveGroups());
  }

  // When deleting a permission we have to remove from all existing groups as well
  deletePermission(name: string) {
    if (!this.getPermission(name)) {
      throw new Error(`Error (SVSACL): Cannot 'deletePermission'. Permission '${name}' not found.`);
    }

    this._permissions = this._permissions.filter(p => p.name !== name);

    this._groups = this._groups.map(g => ({
      ...g,
      permissions: g.permissions.filter(p => p.name !== name),
    }));

    return this.savePermissions().then(() => this.saveGroups());
  }

  _getDefaultCrud() {
    return {
      create: false,
      read: false,
      update: false,
      delete: false,
    }
  }


}

let users: User[] = [
  {
    wallets: [
      '0xff3bde3d2b0565f804f9a4191e1bed875a45c3cb',
    ],
    name: 'Cai Leao',
    email: 'caina.leao@play.co',
    type: UserType.SVS,
    group: {
      name: 'ROOT',
      permissions: [],
    }
  },
  {
    wallets: [
      '0x9f7a9c6679193aabed7f0af4069485b32a1ff251',
    ],
    name: 'Anastasiia Proshkina',
    email: 'anastasiia.proshkina@play.co',
    type: UserType.SVS,
    group: {
      name: 'Content Creator',
      permissions: [],
    }
  }
]

interface BaseUserOpts {
  walletAddress: WalletAddress;
}

export interface CreateUserOpts extends BaseUserOpts {
  name: string;
  email?: string;
  type: UserType;
  group: Group;
}

export type UpdateUserOpts = BaseUserOpts & Partial<User>;

export type DeleteUserOpts = BaseUserOpts;


interface SVSCognitoOpts {
  fetchUsers: () => Promise<User[]>; // api.getUsers();
  updateUsers: (users: User[]) => Promise<any>; // api.updateUsers(this._users);
}

export class SVSCognito {

  isInit = false;

  _users: User[] = [];

  get users() {
    return this._users;
  }

  constructor(private opts: SVSCognitoOpts) {}

  async init() {
    try {
      this._users = await this.getUsersData();
      this.isInit = true;
    } catch(e: any) {
      console.error(`Error (SVSCognito): failed to init`, e.message);
      throw e;
    }
  }

  private async getUsersData() { 
    return this.opts.fetchUsers();
  }

  private async saveUsersData(newUsers: User[]) {
    this._users = newUsers;
    return this.opts.updateUsers(this._users);
  }

  requireInit() {
    if (!this.isInit) {
      throw new Error(`Error (SVSCognito): Class not initialised. Did you forget to call 'await init()'?`);
    }
  }

  createUser(opts: CreateUserOpts) {
    this.requireInit();
    const user = this.getUser(opts);
    if (user) {
      throw new Error(`Error (SVSCognito): Cannot 'createUser'. User '${opts.walletAddress}' already exists.`);
    }

    const newUsers = [
      ...this._users,
      {
        wallets: [
          opts.walletAddress
        ],
        name: opts.name,
        email: opts.email || '',
        type: opts.type,
        group: opts.group,
      },
    ]

    this.saveUsersData(newUsers);
  }

  getUser(opts: BaseUserOpts) {
    this.requireInit();
    return this._users.find(u => u.wallets.includes(opts.walletAddress));
  }

  deleteUser(opts: DeleteUserOpts) {
    const user = this.getUser(opts);
    if (!user) {
      throw new Error(`Error (SVSCognito): Cannot 'deleteUser'. User '${opts.walletAddress}' not found.`);
    }

    const newUsers = this._users.filter(u => u !== user);

    return this.saveUsersData(newUsers);
  }

  updateUser(opts: UpdateUserOpts) {
    const user = this.getUser(opts);
    if (!user) {
      throw new Error(`Error (SVSCognito): Cannot 'updateUser'. User '${opts.walletAddress}' not found.`);
    }
    /**
     * Do we care if the walletAddress used to update the user has to be in wallets? There's a case scenario where we might want to 
     * disassociate the current wallet from the user.
     */
    if (opts.wallets && opts.wallets.length < 1) {
      throw new Error(`Error (SVSCognito): Cannot 'updateUser'. User must have at least 1 wallet address associated with the account.`);
    }
    if (opts.name === '') {
      throw new Error(`Error (SVSCognito): Cannot 'updateUser'. User must have a name.`);
    }

    const newUser = {
      wallets: opts.wallets || user.wallets,
      name: opts.name || user.name,
      email: opts.email || user.email || '',
      type: opts.type || user.type,
      group: opts.group || user.group,
      data: opts.data || user.data,
    }

    const newUsers = this._users.map(u => u === user ? newUser : u);

    return this.saveUsersData(newUsers);
  }
} 