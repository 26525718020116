import { SvsProviderType, Wallet } from '@storyverseco/svs-navbar-client';
import { useEffect, useState } from 'react';
import { SvsProvider } from '@storyverseco/svs-navbar-client';
import { useNavbar } from './useNavbar';
import { useDispatch } from 'react-redux';
import { appSlice } from '../state/appSlice';

const DEBUG = true;

const log = (...msg: any[]) => {
  if (DEBUG) {
    console.log('@useLogin', ...msg);
  }
};
enum LoginState {
  Idle,
  WaitingForWallet,
  CheckingForLogin,
  LoggedOut,
  LoggedIn,
}


let loggedIn = false;
let wallet: Wallet | undefined = undefined;

export const useLogin = (caller?: string) => {
  const [isLoading, setIsLoading] = useState(false);

  const [_next, _setNext] = useState(0);

  const dispatch = useDispatch();

  const { navbar, navbarReady } = useNavbar('login');

  const [state, setState] = useState(LoginState.Idle); 

  const [isReady, setIsReady] = useState(false);

  const next = () => {
    _setNext(_next+1);
  }

  // Check for loggedIn upon loading
  useEffect(() => {
    if (navbarReady && !loggedIn) {
      checkForLogin();
    }
  }, [navbarReady]);

  const checkForLogin = async () => {
    log('checkForLogin');
    if (!navbarReady) {
      console.warn(`Cannot make 'login' request before navbar is ready.`);
      return;
    }

    if (isLoading) {
      console.warn(`Request already in flight. Please wait for the response`);
      return;
    }
    setState(LoginState.CheckingForLogin);
    setIsLoading(true);
    const isLoggedIn = await navbar.api.isLoggedIn();
    log({ isLoggedIn });
    loggedIn = isLoggedIn;
    await getWallet();
    // We mighe get here with loggedOut state
    if (state === LoginState.WaitingForWallet) {
      setState(LoginState.LoggedIn);
    }
    setIsReady(true);
    setIsLoading(false);
  };

  const login = async (providerType: SvsProviderType = SvsProvider.WalletConnect) => {
    log('login', {navbarReady});
    if (!navbarReady) {
      console.warn(`Cannot make 'login' request before navbar is ready.`);
      return;
    }

    if (loggedIn) {
      next();
      console.warn(`Requested login for a user already loggged in.`);
      return;
    }

    if (isLoading) {
      console.warn(`Request already in flight. Please wait for the response`);
      return;
    }
    setIsLoading(true); 

    // Note: IIRC we don't need to do the provider switch anymore
    // await navbar.api.changeSvsProvider({ providerType });
    const isLoggedIn =  await navbar.api.logIn();
    log({ isLoggedIn });
    loggedIn = isLoggedIn;
    setIsLoading(false);
  };

  const getWallet = async () => {
    log('getWallet');
    if (!navbarReady) {
      console.warn(`Cannot make 'login' request before navbar is ready.`);
      return;
    }

    if (!loggedIn) {
      console.warn(`Trying to get wallet before logging in.`);
      return;
    }
    setState(LoginState.WaitingForWallet);
    setIsLoading(true);
    const w = await navbar.api.getWallet();
    wallet = w;
    if (!w.messageAndSig) {
      console.warn(`Wallet without signature. Force logout`);
      await logout();
    }
    dispatch(appSlice.actions.setWallet(wallet));
    log({ wallet });
    setIsLoading(false);
  };

  const logout = async() => {
    if (!navbarReady) {
      console.warn(`Cannot make 'login' request before navbar is ready.`);
      return;
    }

    if (isLoading) {
      return;
    }
    setIsLoading(true);
    await navbar.api.logOut();
    wallet = undefined;
    loggedIn = false;
    setState(LoginState.LoggedOut);
    setIsLoading(false);
  }

  return {
    isLoading,
    loggedIn,
    wallet,
    login,
    logout,
    getWallet,
    loginReady: isReady,
  };
};
