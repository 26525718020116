import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Col, HGap, Row, Box } from '../../../components/Layout';
import { useToast } from '../../../components/Toast';
import { AppDispatch } from '../../state';
import { Main } from '../../layout/Main';
import { Uploader } from '../../../components/Uploader';
import { useCallback, useEffect, useRef, useState } from 'react';
import { TextInput } from '../../../components/TextInput';
import { Button } from 'react-bootstrap';
import { appSlice } from '../../state/appSlice';
import { api } from '../../api/PMToolApi';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';

const UploaderContainer = styled(Row)`
`;

const ImagesContainer = styled(Box)`
  overflow-y: auto;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ImageBox = styled(Col)`
  justify-content: space-between;
  button {
    max-width: 300px;
  }
`;

const Image = styled.img`
  width: 300px;
  height: auto;
  max-height: 300px;
`;

export const MainSitePage = () => {

  const dispatch = useDispatch<AppDispatch>();

  const { success, error } = useToast();

  const { copy } = useCopyToClipboard();

  const uploader = useRef<HTMLInputElement>(null);

  const [file, setFile] = useState<File>();

  const [filename, setFilename] = useState<string>();

  const [remoteFiles, setRemoteFiles] = useState<string[]>([]);

  useEffect(() => {
    const fetchMediaFiles = async() => {
      dispatch(appSlice.actions.suspend());
      try {
        const response = await api.listMediaFiles({ path: 'site' });
        success(`Fetched media files`);
        setRemoteFiles(response);
      } catch (e: any) {
        error(e.message);
      }
  
      dispatch(appSlice.actions.resume());
    }

    fetchMediaFiles();
  }, []);

  const onFileUploaded = useCallback(async(f: File) => {
    setFile(f);
    setFilename(f.name);
  }, []);

  const onUploadClick = useCallback(async() => {
    if (!file) {
      error(`Error: Must first select a file`);
      return;
    }

    dispatch(appSlice.actions.suspend());
    try {
      const response = await api.uploadFileWithSignedPost({ file, path: 'site', filename });
      success(`File ${file.name} uploaded successfully!`);
      setFile(undefined);
      setFilename(undefined);
      setRemoteFiles([
        ...remoteFiles,
        response
      ]);
    } catch (e: any) {
      error(e.message);
    }

    dispatch(appSlice.actions.resume());
  }, [file, filename, remoteFiles]);

  const openFileUploader = useCallback(() => {
    if (!uploader?.current) {
      return;
    }
    setFile(undefined);
    uploader.current.click();
  }, [uploader.current]);

  const onFileDelete = useCallback((fileUrl: string) => {
    return async() => {
      dispatch(appSlice.actions.suspend());
      try {
        await api.deleteMediaFiles({ fileUrl });
        success(`${fileUrl} file has been deleted.`);
        setRemoteFiles(remoteFiles.filter(f => f !== fileUrl));
      } catch (e: any) {
        error(e.message);
      }
  
      dispatch(appSlice.actions.resume());
    }
  }, [remoteFiles]);


  return (
    <Main
      title='Main site media'
      subtitle='View, Edit and Upload media for the Main Site'
    > 
      <UploaderContainer clear>
        <Button onClick={openFileUploader}>Select file</Button>
        <HGap size={4} />
        <TextInput 
          label='File name'
          disabled={!file}
          value={filename || 'No file selected.'}
          onChange={setFilename}
        />
        <HGap size={24} />
        <Button onClick={onUploadClick} disabled={!file} variant='success'>Upload</Button>
        <Uploader
          ref={uploader} 
          id='main-site-media-uploader'
          onFileUploaded={onFileUploaded}
          accept='*'
        />
      </UploaderContainer>
      <hr />
      <ImagesContainer clear>
        {
          remoteFiles.map(file => {
            return (
              <ImageBox key={file}>
                <Image src={file} />
                <Col clear>
                  <Button onClick={() => copy(file)}>{file.substring(file.lastIndexOf('/')+1)}</Button>
                  <Button onClick={onFileDelete(file)} variant='danger'>Delete</Button>
                </Col>
              </ImageBox>
            )
          })
        }
      </ImagesContainer>
    </Main>
  );
}