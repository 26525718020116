import styled from 'styled-components';
import { Col, Row, HGap } from '../../../../../components/Layout';
import { colors } from '../../../../../lib/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedStory } from '../../../../state/selectors';
import { Button } from 'react-bootstrap';
import { Picker } from '../../../../../components/Picker';
import { Title } from '../../../../../components/Text';
import { ChangeEvent } from 'react';
import { appSlice } from '../../../../state/appSlice';
import { PickerData, PickerItem } from '../../../../../lib/types';
import { getSelectedStoryCast } from '../../../../state/selectors';
import { useToast } from '../../../../../components/Toast';
import { api } from '../../../../api/PMToolApi';

const Container = styled(Col)`
`;

const TextInput = styled.input`
  width: 100px;
  height: 44px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid ${colors.grey};
  margin-top: -10px;
  margin-left: 10px;
`;

const DropdownContainer = styled(Row)`
  justify-content: start;
  padding: 15px 0;
`;

const BtnsContainer = styled(Row)`
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 16px;
`;

export const StoryCast = () => {

  const dispatch = useDispatch();
  const selectedStory = useSelector(getSelectedStory);
  const { cast, exclusive } = useSelector(getSelectedStoryCast);

  const { success, error } = useToast();

  const onSaveChanges = () => {
    onAction({
      successMsg: 'Cast saved successfully',
      errorMsg: 'Failed to save cast',
      promise: api.setStoryCast(),
    });
  }

  const onResetChanges = () => {
    onAction({
      successMsg: 'Story resetted!',
      errorMsg: 'Failed to reset a story. Please refresh the app.',
      promise: (async() => dispatch(appSlice.actions.resetSelectedStory()))(),
    });
  }

  const onAction = async({promise, successMsg, errorMsg}: {promise: Promise<any>, successMsg: string, errorMsg: string}) => {
    await dispatch(appSlice.actions.suspend());
    try {
      await promise;  
      success(successMsg);
    } catch (e) {
      error(errorMsg);
    }    
    await dispatch(appSlice.actions.resume());
  }


  const onStoryIdChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const storyId = evt.target.value;
    dispatch(appSlice.actions.setSelectedStory({storyId}));
  }
  const filterByValue = (item: PickerItem, filter: string) => (item.value as string).toLowerCase().includes(filter.toLowerCase());

  const onPickerUpdate = ({to}: PickerData) => {
    if (to.length > 4) {
      error('Cannot add more than 4 characters');
      return;
    }
    dispatch(appSlice.actions.setSelectedStory({characters: to.map(i => i.id)}));
  }

  const disabledButton = !selectedStory.storyId || selectedStory.characters.length > 4 || selectedStory.characters.length < 3;
  return (
    <Container>
      <DropdownContainer>
          Story ID: <TextInput onChange={onStoryIdChange}/>
      </DropdownContainer>
      {selectedStory.storyId && (<Col>
        <Title>Cast</Title>
        <Picker 
          fromTitle='Available exclusive Characters'
          from={exclusive}
          toTitle='Story Default Cast'
          to={cast}
          onPickerUpdate={onPickerUpdate}
          filterFromBy={filterByValue}
          filterToBy={filterByValue}
        />
      </Col>)}
      <BtnsContainer>
        <Button variant='danger' onClick={onResetChanges}>Reset Story</Button>
        <HGap size={32} />
        <Button variant='success' onClick={onSaveChanges} disabled={disabledButton}>Save Cast</Button>
      </BtnsContainer>
    </Container>
  )
}

