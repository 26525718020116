import { PMToolEndpoints } from "@storyverseco/svs-types";
import { api } from "../../../api/PMToolApi";

interface PostmanEndpoint {
  body: Record<string, any>;
  description?: string;
  ignore?: boolean;
}

// const postmanConfig: Record<keyof typeof api, PostmanEndpoint> = {
//   auth: {
//     body: { },
//   },
//   getUser: {
//     body: {
//       walletAddress: '',
//     }
//   },
//   createUser: {
//     body: {
//       walletAddress: '',
//     }
//   },
//   updateUserAssets: {
//     body: {
//       walletAddress: '',
//       assets: {
//         characters: [''],
//         backgrounds: [''],
//         stages: [{
//           thumbnail: '',
//           stage: '',
//       }],
//       }
//     }
//   },
//   getExclusiveAssets: {
//     body: { }
//   },
//   assembleCharacter: {
//     body: { },
//     ignore: true, // does not call pmtool backend
//   },
//   getTemplates: {
//     body: { },
//     ignore: true, // deprecated
//   },
//   updateTemplates: {
//     body: { },
//     ignore: true, // deprecated
//   },
//   getTemplateProjects: {
//     body: { },
//     ignore: true, // deprecated
//   },
//   createTemplateProject: {
//     body: { },
//     ignore: true, // deprecated
//   },
//   promoteTemplateToTemplate: {
//     body: { },
//     ignore: true, // deprecated
//   },
//   getExclusiveAssets: {
//     body: { }
//   },
//   generateStory: {
//     body: { },
//     ignore: true, // no time to setup now
//   },
//   mintNFT: {
//     body: { },
//     ignore: true, // no time to setup now
//   },
//   getRevealSchedule: {
//     body: {
//       walletAddress: '',
//       storyId: '',
//     }
//   },
//   setRevealSchedule: {
//     body: {
//       walletAddress: '',
//       storyId: '',
//       revealSchedule: [{
//         timestamp: 0,
//         revealedTimelineId: '',
//         type: '',
//         revealed: true,
//       }]
//     }
//   },
// }


export const endpoints: string[] = [
  // PMToolEndpoints.TEMPLATES_GET,
  // PMToolEndpoints.TEMPLATES_UPDATE,
  PMToolEndpoints.ASSETS_EXCLUSIVE_GET,
  PMToolEndpoints.USER_GET,
  PMToolEndpoints.USER_UPDATE,
  PMToolEndpoints.USER_CREATE,
  // PMToolEndpoints.PROJECT_TEMPLATES_CREATE,
  // PMToolEndpoints.PROJECT_TEMPLATES_GET,
  // PMToolEndpoints.TEMPLATES_CREATE,
  // PMToolEndpoints.STORY_GENERATE,
  // '/nft/mint' as PMToolEndpoints,
  '/nft/revealschedule' as PMToolEndpoints,
  '/nft/revealschedule/update' as PMToolEndpoints,
  // PMToolEndpoints.STORY_CAST,
  // PMToolEndpoints.STORY_PUBLISH,
  // PMToolEndpoints.STORY_PUBLISH_PROD,
  '/svscognito/acl/permissions' as PMToolEndpoints,
  '/svscognito/acl/permissions/update' as PMToolEndpoints,
  '/svscognito/acl/groups' as PMToolEndpoints,
  '/svscognito/acl/groups/update' as PMToolEndpoints,
  '/svscognito/users' as PMToolEndpoints,
  '/svscognito/users/update' as PMToolEndpoints,
  '/auth' as PMToolEndpoints,
  '/nft/story/create' as PMToolEndpoints,
  '/sudo/file' as PMToolEndpoints,
  '/sudo/file/update' as PMToolEndpoints,
  '/nft/story/acl' as PMToolEndpoints,
  '/nft/story/acl/update' as PMToolEndpoints,
  '/nft/stories' as PMToolEndpoints,
  '/foo',
  '/config/saledata',
  '/config/saledata/update',
  '/nft/story/publish',
  '/media/upload/sign',
  '/writers',
  '/writers/update',
]