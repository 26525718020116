import { useMemo, useState } from "react";
import styled from "styled-components";
import { Col, HGap, Row, VGap } from "../../../../components/Layout";
import { TextInput } from "../../../../components/TextInput";
import { Button } from "react-bootstrap";
import { useToast } from "../../../../components/Toast";
import { useDispatch } from "react-redux";
import { appSlice } from "../../../state/appSlice";
import { api } from "../../../api/PMToolApi";
import { useSVSCognito } from "../../../hooks/useSVSCognito";
import { Picker } from "../../../../components/Picker";
import { PickerData, PickerItem } from "../../../../lib/types";
import { Main } from "../../../layout/Main";

const Container = styled(Col)`

`;

const InputsContainer = styled(Row)`

`;

const filterByValue = (item: PickerItem, filter: string) => (item.value as string).toLowerCase().includes(filter.toLowerCase());

export const EditStoryNFTACL = () => {

  const [walletAddress, setWalletAddress] = useState('');

  const [storyId, setStoryId] = useState('');

  const [aclFile, setAclFile] = useState<Record<string, any>>();

  const { success, error } = useToast();

  const dispatch = useDispatch()

  const { getUsers } = useSVSCognito();

  const assignedItems = useMemo(() => {
    if (!aclFile) {
      return [];
    }

    return Object.keys(aclFile).map(walletAddress => ({
      id: walletAddress,
      value: aclFile[walletAddress].entity,
    }))
  }, [aclFile]);

  const onSearch = async() => {
    dispatch(appSlice.actions.suspend());
    try {
      const acl = await api.getNFTStoryACL({walletAddress: walletAddress.toLocaleLowerCase(), storyId});
      setAclFile(acl);
      success(`Acl file retrieved.`);
    } catch (e: any) {
      setAclFile({});
      error(e.message);
    }
    dispatch(appSlice.actions.resume());
  }

  const onSave = async() => {
    dispatch(appSlice.actions.suspend());
    try {
      const acl = await api.updateNFTStoryACL({walletAddress: walletAddress.toLocaleLowerCase(), storyId, acl: aclFile});
      success(`Acl file updated.`);
    } catch (e: any) {
      error(e.message);
    }
    dispatch(appSlice.actions.resume());
  }

  const disablePicker = useMemo(() => !Boolean(aclFile), [aclFile]);

  const searchDisabled = !walletAddress || !storyId;

  const saveDisabled = searchDisabled || disablePicker;

  const onPickerUpdate = ({to, from}: PickerData) => {
    console.log(to, from);

    const newAcl = to.reduce((res, cur) => {
      return {
        ...res,
        [cur.id]: {
          entity: cur.value,
          read: true,
        }
      }
    }, {});

    setAclFile(newAcl);
  }

  const availableItems = getUsers().map(user => ({
    id: user.wallets[0],
    value: user.name,
  })).filter(item => !assignedItems.find(ai => ai.id === item.id));

  return (
    <Main
      title='Collectible Stories'
      subtitle='Change who can view a NFT story'
    >
      <InputsContainer clear>
        <TextInput 
          label='Author Wallet Address'
          value={walletAddress}
          onChange={setWalletAddress}
        />
        <HGap size={8} />
        <TextInput 
          label='Story ID'
          value={storyId}
          onChange={setStoryId}
        />
        <Button variant='info' onClick={onSearch} disabled={searchDisabled}>Search</Button>
      </InputsContainer>
      <VGap size={8} />
      <Picker 
        fromTitle='Available users'
        from={availableItems}
        toTitle='Assigned users'
        to={assignedItems}
        onPickerUpdate={onPickerUpdate}
        filterFromBy={filterByValue}
        filterToBy={filterByValue}
        disabled={disablePicker}
      />
      <br />
      <Button variant='success' onClick={onSave} disabled={saveDisabled}>Save ACL</Button>
    </Main>
  );
}