import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Col, HGap, Row, VGap } from "../../../../components/Layout";
import { TextInput } from "../../../../components/TextInput";
import { Button, Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";
import { useToast } from "../../../../components/Toast";
import { useDispatch } from "react-redux";
import { appSlice } from "../../../state/appSlice";
import { api } from "../../../api/PMToolApi";
import { Main } from "../../../layout/Main";
import { Dropdown as SVSDropdown} from "../../../../components/Dropdown";
import { getConfig } from "../../../../Config";
import { Accordion } from "../../../../components/Accordion";
import { ChoiceId, TimelineId } from "@storyverseco/svs-story-suite";
import { Title } from "../../../../components/Text";
import { time } from "console";

const InputsContainer = styled(Col)`

`;

enum WriteMapState {
  Idle,
  Loading,
  Create,
  Complete,
}

const defaultDropdown = ['NONE', 'NEW'];

export const AttributeMapping = () => {

  const dispatch = useDispatch();

  const [dropdownSelection, setDropdownSelection] = useState('NONE');

  const [attributeMappings, setAttributeMappings] = useState<{contractaddress: string; value: string}[]>([]);

  const [attrs, setAttrs] = useState<any>();

  const [saleData, setSaleData] = useState<any>();

  const [newContractAddress, setNewContractAddress] = useState('');

  const [writeState, setWriteState] = useState(WriteMapState.Idle);

  const [newWalletAddress, setNewWalletAddres] = useState('');

  const [newStoryId, setNewStoryId] = useState('');
  
  const { error, warning, success } = useToast();

  useEffect(() => {
    const fetchAttributeMappings = async() => {
      dispatch(appSlice.actions.suspend());
      const cfg = await getConfig();
      setSaleData(cfg.saleData);
      const data = await api.getAttributeMappings();
      setAttributeMappings(data);
      dispatch(appSlice.actions.resume());
    }
    fetchAttributeMappings();
  }, []);

  const mappings = useMemo(() => {
    if (!saleData || !attributeMappings) {
      return [];
    }

    // console.log('mappings', attributeMappings);

    return attributeMappings.map(({contractaddress, value}) => {
      const sale: any = Object.values(saleData).find((s: any) => s.tokenContractAddress === contractaddress);
      const data = {
        contractAddress: contractaddress,
        value,
        id: sale?.saleId,
        name: sale?.saleName ?? contractaddress,
      }
      return data;
    });
  }, [saleData, attributeMappings]);

  const dropdownList = useMemo(() => {
    if (!mappings) {
      return defaultDropdown;
    }

    return ['NONE', ...mappings.map(m => m.name), 'NEW'];
  }, [mappings]);

  const selectedMapping = mappings.find(m => m.name === dropdownSelection);

  const onDropdownChange = (value: string) => {
    setDropdownSelection(value);
    // console.log('onDropdownChange', {
    //   value,
    //   v: map?.value,
    //   mappings,
    // });
    // setAttrs(selectedMapping?.value);
    // // reset add form
    // setNewContractAddress('');
    // setWriteState(WriteMapState.Idle);
    // setNewWalletAddres('')
    // setNewStoryId('')
  }

  useEffect(() => {
    setAttrs(selectedMapping?.value);
    // reset add form
    setNewContractAddress('');
    setWriteState(WriteMapState.Idle);
    setNewWalletAddres('')
    setNewStoryId('')
  }, [selectedMapping])

  const isAdding = useMemo(() => dropdownSelection === 'NEW', [dropdownSelection]);

  const prefillStory = async(walletAddress: string, storyId: string, saleName: string) => {
    dispatch(appSlice.actions.suspend());
    try {
        const attrMapping = await fetchStoryAttrMapping({walletAddress, storyId});

        const entry =  {
          contractaddress: newContractAddress,
          value: JSON.stringify(attrMapping),
        }

        const newAttributeMappings = JSON.parse(JSON.stringify(attributeMappings));
        newAttributeMappings.push(entry);
        
        setAttributeMappings(newAttributeMappings);
        setTimeout(() => {
          setAttrs(entry.value);
          setDropdownSelection(saleName);
          success(`Story data found. Default mapping generated!`);
        });
    } catch (e) {
      error(`Failed to load story data.`);
    }
    dispatch(appSlice.actions.resume());
  }

  const onAddNewMapping = async() => {
    // Don't let them add if already exists
    const alreadyExists = attributeMappings.find(m => m.contractaddress === newContractAddress);

    if (writeState === WriteMapState.Loading || alreadyExists) {
      return;
    }

    setWriteState(WriteMapState.Loading);
    try {
      if (writeState === WriteMapState.Idle) {
        const sale: any = Object.values(saleData).find((s: any) => s.tokenContractAddress === newContractAddress);
        if (!sale) {
          warning(`Could not find contractAddress in 'saleData'. Map story manually!`);
          setWriteState(WriteMapState.Create);
          return;
        }
        await prefillStory(sale.nftWalletAddress, sale.nftStoryId, sale.saleName);
      } else {
        if (!newWalletAddress || !newStoryId) {
          return;
        }
        setWriteState(WriteMapState.Loading);
        await prefillStory(newWalletAddress, newStoryId, newContractAddress);
        setWriteState(WriteMapState.Complete);
      }
    } catch (e) {

    }
  }

  const formData = typeof attrs === 'string' ? JSON.parse(attrs) : attrs;

  const onSavePress = async(newValue: F) => {
    if (!selectedMapping) {
      return;
    }
    dispatch(appSlice.actions.suspend());
    const response = await api.updateAttributeMappings(selectedMapping.contractAddress, newValue);
    console.log(response);
    setAttributeMappings(response);
    dispatch(appSlice.actions.resume());
  }

  const fetchStoryForSelected = async() => {
    if (!selectedMapping) {
      return;
    }
    const sale: any = Object.values(saleData).find((s: any) => s.tokenContractAddress === selectedMapping.contractAddress);
    if (!sale) {
      return;
    }
    const attrMapping = await fetchStoryAttrMapping({walletAddress: sale.nftWalletAddress, storyId: sale.nftStoryId});

    const newAttributeMappings: typeof attributeMappings = JSON.parse(JSON.stringify(attributeMappings));

    const entry = newAttributeMappings.find(({contractaddress}) => contractaddress === selectedMapping.contractAddress)

    if (!entry) {
      console.error(`Error (fetchStoryForSelected): Expected to find entry in 'attributeMappings' for '${selectedMapping.contractAddress}'.`);
      return;
    }

    const currentLoadedValue: F = typeof entry.value === 'string' ? JSON.parse(entry.value) : entry.value;

    const hasDrifted = Object.keys(attrMapping).length > Object.keys(currentLoadedValue).length;

    if (hasDrifted) {
      newAttributeMappings.find(({contractaddress}) => contractaddress === selectedMapping.contractAddress)!.value = JSON.stringify(
        Object.keys(attrMapping).reduce((res, cur) => {
          const key = cur as TimelineId;
          let value = attrMapping[key];
          if (currentLoadedValue[key]) {
            value = currentLoadedValue[key];
          }
          return {
            ...res,
            [cur]: value,
          }
        }, {})
      )
    }
        
    setAttributeMappings(newAttributeMappings);
  }

  const disableFetchStory = defaultDropdown.includes(dropdownSelection) || selectedMapping?.name.startsWith('0x');

  const projectsSuggestions: Record<string, string> = Object.values(saleData || {})
    .filter((s: any) => s.saleType === 'collection' && s.tokenContractAddress)
    .reduce((res: Record<string, string>, cur: any) => {
      return {
        ...res,
        [cur.saleName]: cur.tokenContractAddress
      }
    }, {});

  const [disableFreeInput, setDisableFreeInput] = useState(true);

  const onSuggestionClick = (name: string) => () => {
    setDisableFreeInput(name !== 'OTHER');

    const suggestion = projectsSuggestions[name];

    if (suggestion) {
      setNewContractAddress(suggestion);
    } 
  }

  return (
    <Main
      title='Attribute mappings'
      subtitle='Map attributes for stories metadata'
    >
      <InputsContainer clear>
        <Col>
          <Title bold center>Select Project</Title>
          <Row>
            <SVSDropdown
              value={dropdownSelection}
              onChange={onDropdownChange}
              items={dropdownList}
              style={{fontSize: 24, paddingLeft: 12}}
            />
            <Button 
              disabled={disableFetchStory}
              onClick={fetchStoryForSelected}
            >
              Fetch Story
            </Button>
          </Row>
        </Col>
        {
          isAdding && (
            <Col>
              <hr />
              <VGap size={12} />
              <Title bold upper center>Add new mapping</Title>
              <Title center bold>Contract Address</Title>
              <InputGroup className="mb-3">
                <DropdownButton
                  variant="primary"
                  title="Dropdown"
                  id="input-group-dropdown-1"
                >
                    {
                      [...Object.keys(projectsSuggestions), 'OTHER'].map(key => <Dropdown.Item onClick={onSuggestionClick(key)}>{key}</Dropdown.Item>)
                    }
                </DropdownButton>
                <Form.Control disabled={disableFreeInput} value={newContractAddress} onChange={(evt) => setNewContractAddress(evt.target.value)} />
              </InputGroup>
              {
                writeState === WriteMapState.Create && (
                  <TextInput 
                    label='Wallet Address'
                    value={newWalletAddress}
                    onChange={setNewWalletAddres}
                  />
              )}
              {
                writeState === WriteMapState.Create && (
                  <TextInput 
                    label='Story ID'
                    value={newStoryId}
                    onChange={setNewStoryId}
                  />
              )}
              <VGap size={8} />
              <Button 
                disabled={writeState === WriteMapState.Loading} 
                onClick={onAddNewMapping}
              >
                {writeState !== WriteMapState.Create ? 'Find Story' : 'Search story'}
              </Button>
            </Col>
          )
        }
      </InputsContainer>
      <hr />
      <VGap size={12} />
      {
        attrs && <AttrMappingForm data={formData} onSave={onSavePress} />
      }
    </Main>
  );
}

type F = Record<TimelineId, Record<ChoiceId, string>>;

interface Props {
  data: F;
  onSave: (mapping: F) => void;
}

const AttrMappingForm = (props: Props) => {

  const [data, setData] = useState<F>(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const accordionItems = Object.keys(data).map((timelineId) => {
    const choices = data[timelineId as TimelineId];
    return {
      title: `Timeline '${timelineId}'`,
      content: (
        <Col>
          {
            Object.keys(choices).map((choiceId) => {
              const choice = choices[choiceId as ChoiceId];
              return (
                <TextInput 
                  key={`${timelineId}:${choiceId}`}
                  label={`Choice '${choiceId}'`}
                  value={choice}
                  onChange={(newChoice: string) => {
                    const newData = {
                      ...data,
                      [timelineId]: {
                        ...choices,
                        [choiceId]: newChoice,
                      }
                    }
                    setData(newData);
                  }}
                />
              );
            })
          }
        </Col>
      ),
    }
  });

  const onSave = () => {
    props.onSave(data);
  }

  return (
    <>
      <Accordion items={accordionItems} />
      <Button variant='success' onClick={onSave}>Save</Button>
    </>
    
  )

}

const fetchStoryAttrMapping = async(props: {walletAddress: string; storyId: string;}) => {
  const storyData = await api.getStory(props);

  const timelineIds = Object.keys(storyData.timelines);

  const timelineIdsWithChoices = timelineIds.filter(tid => Boolean(storyData.timelines[tid as TimelineId].mergeTimeline));

  const attrMapping: F = timelineIdsWithChoices.reduce((res, cur) => {
    const timeline = storyData.timelines[cur as TimelineId];
    const choiceStep = timeline.steps[timeline.lastStep].choices!;
    return {
      ...res,
      [cur]: Object.keys(choiceStep).reduce((choices, choiceId) => ({
        ...choices,
        [choiceId]: choiceStep[choiceId as ChoiceId].caption
      }), {})
    }
  }, {});

  return attrMapping;
}