import { IRevealSchedule } from '@storyverseco/svs-story-suite';
import { Optional } from '@storyverseco/svs-types';
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AdminState {
  userWalletAddress: string;
  templateAddress: string;
  templateName: string;
  revealSchedule?: IRevealSchedule[];
  storyAddress: string;
  storyId: string;
}

const initialState: AdminState = {
  userWalletAddress: '',
  templateAddress: '',
  templateName: '',
  storyAddress: '',
  storyId: '',
};

const setUserWalletAddress: CaseReducer<AdminState, PayloadAction<string>> = (
  state,
  { payload }
) => {
  state.userWalletAddress = payload;
};

const setTemplateAddress: CaseReducer<AdminState, PayloadAction<string>> = (
  state,
  { payload }
) => {
  state.templateAddress = payload;
};

const setTemplateName: CaseReducer<AdminState, PayloadAction<string>> = (
  state,
  { payload }
) => {
  state.templateName = payload;
};

const resetTemplateProjectInput: CaseReducer<AdminState> = (state) => {
  state.templateName = initialState.templateName;
  state.templateAddress = initialState.templateAddress;
};

const setRevealSchedule: CaseReducer<
  AdminState,
  PayloadAction<Optional<IRevealSchedule[]>>
> = (state, { payload }) => {
  state.revealSchedule = payload;
};

const setStoryAddress: CaseReducer<AdminState, PayloadAction<string>> = (
  state,
  { payload }
) => {
  state.storyAddress = payload;
};

const setStoryId: CaseReducer<AdminState, PayloadAction<string>> = (
  state,
  { payload }
) => {
  state.storyId = payload;
};

export const adminSlice = createSlice({
  name: 'admin',

  initialState,

  reducers: {
    setUserWalletAddress,
    setTemplateAddress,
    setTemplateName,
    resetTemplateProjectInput,
    setRevealSchedule,
    setStoryAddress,
    setStoryId,
  },
});
