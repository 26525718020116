import styled from "styled-components";
import { Main } from "../../layout/Main";
import { Col, HGap, Row } from "../../../components/Layout";
import { useMemo, useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { appSlice } from "../../state/appSlice";
import 'react-json-editor-ui/dist/react-json-editor-ui.cjs.development.css'
import { useToast } from "../../../components/Toast";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { Title } from "../../../components/Text";
import { colors } from "../../../lib/styles";
import { Routes, useNavigation } from "../../navigation/useNavigation";
import { getConfig } from "../../../Config";

const RaffleList = styled(Col)`
  overflow-y: auto;
  max-height: 78vh;
`;

const RaffleRow = styled(Row)`
  border-bottom: 1px solid ${colors.black};
  cursor: pointer;
`;

const Section = styled(Row)`
  padding: 8px;
`;

const Text = styled.p<{color: string}>`
  padding: 0;
  margin: 0;
  color: ${({color}) => color};
  margin-left: 4px;
  text-transform: uppercase;
`;



export const RafflePageList = () => {

  const dispatch = useDispatch();

  const { success, error } = useToast();

  const { navigateWithPathParams } = useNavigation();

  const [saleData, setSaleData] = useState<any>();

  const fetchSaleData = useCallback(async() => {
    dispatch(appSlice.actions.suspend());
    try {
      const data = (await getConfig({fresh: true})).saleData;
      setSaleData(data);
      success(`Sale data fetched!`);
    } catch(e: any) {
      error(e.message);
    }
    dispatch(appSlice.actions.resume());
  }, []);

  useEffect(() => {
    fetchSaleData();
  }, []);

  const raffles = useMemo(() => {
    return saleData ? Object.values(saleData).map((sale: any) => ({
      saleName: sale.saleName,
      saleId: sale.saleId,
      featured: sale.featured,
      open: sale.whitelist?.signUp ? new Date(sale.whitelist.signUp.startDate).getTime() < Date.now() && new Date(sale.whitelist.signUp.endDate).getTime() > Date.now() : false, 
    })) : [];
  }, [saleData]);

  return (
    <Main
      title='Raffle page list'
      subtitle='See all the raffle pages created'
    >
      <RaffleList clear>
        {
          raffles.map(r => (
            <RaffleRow key={r.saleId} centerV onClick={() => navigateWithPathParams(Routes.CONFIG_RAFFLE_PAGE_UPDATE, [r.saleId])}>
              <Section clear> 
                <Title bold>{r.saleName}</Title>
              </Section>
              <Section clear>
                <Title>{r.saleId}</Title>
              </Section>
              <Section clear>
                <b>Featured: </b>{' '} <Text color={r.featured ? colors.success : colors.danger}>{r.featured.toString()}</Text>
              </Section>
              <Section clear>
                <b>Open: </b>{' '} <Text color={r.open ? colors.success : colors.danger}>{r.open.toString()}</Text>
              </Section>
            </RaffleRow>
          ))
        }
      </RaffleList>
    </Main>
  );
}