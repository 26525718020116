import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../api/PMToolApi';
import { createThunk } from '../../../state/createThunk';
import { templatesSlice } from '../../templates/state/templatesSlice';
import { adminSlice } from './adminSlice';

export const createTemplateProject = createAsyncThunk('templates/project/create', createThunk(async(thunkAPI) => {
  await api.createTemplateProject();
  await thunkAPI.dispatch(adminSlice.actions.resetTemplateProjectInput());
  await thunkAPI.dispatch(templatesSlice.actions.shouldFetchProjects());
  return Promise.resolve();
}));

export const createUserThunk = createAsyncThunk('user/create', createThunk(() => api.createUser()));