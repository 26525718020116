import { Alert, Button, Spinner, Stack, Table } from 'react-bootstrap';
import { useTwitterAuth1DM } from '../../../hooks/useTwitterAuth1DM';
import { MouseEvent, useEffect, useState } from 'react';
import { Main } from '../../../layout/Main';

const authDuration = 10000; // 10 seconds in ms

const AuthedContainer = () => {
  const { auth1Creds, clearAuth1Creds } = useTwitterAuth1DM();
  const [showCreds, setShowCreds] = useState(false);

  return <div>
    <div>
      <Alert variant="info">Logged in as "{auth1Creds?.twitterUserName}" (ID: {auth1Creds?.twitterUserId}, Auth Version: {auth1Creds?.authVersion})</Alert>
      {showCreds && (
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td>Twitter user ID:</td>
              <td>{auth1Creds?.twitterUserId}</td>
            </tr>
            <tr>
              <td>Twitter username:</td>
              <td>{auth1Creds?.twitterUserName}</td>
            </tr>
            <tr>
              <td>Auth version:</td>
              <td>{auth1Creds?.authVersion}</td>
            </tr>
            <tr>
              <td>Access token:</td>
              <td>{auth1Creds?.accessToken}</td>
            </tr>
            <tr>
              <td>Access token secret:</td>
              <td>{auth1Creds?.accessTokenSecret}</td>
            </tr>
            <tr>
              <td>Usage types:</td>
              <td>{auth1Creds?.usageTypes?.length ? auth1Creds?.usageTypes?.join(', ') : (<em>n/a</em>)}</td>
            </tr>
          </tbody>
        </Table>
      )}
      <Stack direction="horizontal" gap={3}>
        <Button onClick={() => setShowCreds(!showCreds)} variant="warning">{showCreds ? 'Hide' : 'Show'} creds</Button>
        <Button onClick={clearAuth1Creds} variant="danger">Clear creds</Button>
      </Stack>
    </div>
  </div>
};

const NoAuthContainer = () => {
  const { authorizeUrl, authorize } = useTwitterAuth1DM();
  const [startAuthTime, setStartAuthTime] = useState(0);
  const startAuth = (e: MouseEvent) => {
    e.preventDefault();
    authorize();
    setStartAuthTime(Date.now());
  };

  const inProgress = Date.now() < startAuthTime + authDuration;

  useEffect(() => {
    if (!inProgress) {
      return;
    }

    setTimeout(() => {
      setStartAuthTime(0); // trigger a re-render to calculate inProgress to false
    }, startAuthTime + authDuration - Date.now());
  }, [startAuthTime, inProgress]);

  return <div>
    <Alert variant="info">Log in as the sender Twitter account.</Alert>
    <Button href={authorizeUrl} onClick={startAuth} disabled={inProgress}>
      {inProgress && (
        <>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          {' '}
        </>
      )}
      Authorize
    </Button>
  </div>
};

export const TwitterDMTool = () => {
  const { auth1Creds } = useTwitterAuth1DM();

  return (
    <Main
      title='Twitter'
      subtitle='Send dms to users'
    >
      {Boolean(auth1Creds) && <AuthedContainer />}
      {!Boolean(auth1Creds) && <NoAuthContainer />}
    </Main>
  );
}
