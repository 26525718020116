import { SignatureMessage, AssetsData, WalletAddress, ContractAddress, StoryIndex, Env as TEnv } from '@storyverseco/svs-types';
import { isStringArray } from './utils';

export type Optional<T> = T | undefined;

export type Nullable<T> = T | null;

export interface CharactersMetadata {
  skeletons: Record<string, string>;
  skins: Record<string, Record<string, string>>;
  animations: Record<string, Record<string, string>>;
}

export interface PickerItem {
  id: string;
  value: React.ReactNode;
}

export interface PickerData {
  to: PickerItem[];
  from: PickerItem[];
}

export type FilterFunction = (item: PickerItem, filter: string) => boolean;

export type Env = TEnv;

export interface BaseParams {
  auth: {
    signature: SignatureMessage;
    walletAddress?: WalletAddress;
  };
  env: Env;
}

export interface ApiParams extends BaseParams {
  walletAddress?: string;
  assets?: AssetsData;
  adminWalletAddress?: string;
  templateAddress?: string;
  templateName?: string;
  name?: string;
  storyId?: string;
  characters?: string[];
}

// @TODO: Put this in svs-lib
export interface CharData {
  name: string;
  metadataUrl: string;
}

export interface ExclusiveAssets {
  bgs: string[];
  chars: CharData[];
}

export interface BaseThunkParams {
  onSuccess: () => Promise<void>;
  onFailure: () => Promise<void>;
}

export type ThunkParams<T> = BaseThunkParams & T;

export type SelectedStory = {
  storyId: string;
  characters: string[];
};

export interface TwtAuth1Creds {
  accessToken: string;
  accessTokenSecret: string;
  twitterUserName: string;
  twitterUserId: string;
  authVersion: string;
  usageTypes?: string[];
}

export const isTwtAuth1Creds = (obj: any): obj is TwtAuth1Creds =>
  obj &&
  typeof obj === 'object' &&
  typeof obj.accessToken === 'string' &&
  typeof obj.accessTokenSecret === 'string' &&
  typeof obj.twitterUserName === 'string' &&
  typeof obj.twitterUserId === 'string' &&
  typeof obj.authVersion === 'string' &&
  (typeof obj.usageTypes === 'undefined' ||
    obj.usageTypes === null ||
    isStringArray(obj.usageTypes));

export interface TwtAuthData {
  inittedFromQuery: boolean;
  auth1Creds: TwtAuth1Creds | null;
}

// @TODO: This should belong in svs-types
export type NFTStoryKey = `${WalletAddress}/${number}`;

export interface NFTStoryMetadata {
  walletAddress: string;
  storyId: number;
  createdAt: number;
  templateId: string;
  storyName: string;
}

export type NFTStoriesMetadata = Record<NFTStoryKey, NFTStoryMetadata>;

export enum ConfigTarget {
  SALE_DATA = 'saleData',
}

export interface LoadingConfig {
  title: string;
  author: {
    pic: string;
    name: string;
    desc: string;
  };
  cast: string[];
}

export type LoadingConfigs = Record<ContractAddress, Record<StoryIndex, LoadingConfig>>;