import styled from 'styled-components';
import { Accordion } from '../../../components/Accordion';
import { Col, VGap } from '../../../components/Layout';
import { Page } from "../../../components/Page";
import { Title } from '../../../components/Text';
import { Groups } from './components/Groups';
import { Permissions } from './components/Permissions';
import { Users } from './components/Users';
import { FileEditor } from './components/FileEditor';
import { useACL, useSVSCognito } from '../../hooks/useSVSCognito';

const Content = styled(Col)`
  width: 1200px;
`;

const items = [
  {
    title: 'Users',
    content: (
      <Col>
        <Title>Manage SVS Cognito Users</Title>
        <Users />
      </Col>
    )
  },      
  {
    title: 'Groups',
    content: (
      <Col>
        <Title>Groups</Title>
        <Groups />
      </Col>
    )
  },  
  {
    title: 'Permissions',
    content: (
      <Col>
        <Title>Permissions</Title>
        <Permissions />
      </Col>
    )
  },
  {
    title: 'File editor',
    content: (
      <Col>
        <Title>Edit files</Title>
        <FileEditor />
      </Col>
    )
  }                   
];

/**
 * @deprecated - I dont think this is in use any longer
 */
export const SuperUserPage = () => {

  // const { cognito } = useSVSCognito();
  // const { acl } = useACL({});

  // if (!cognito.isInit || !acl.isInit) {
  //   return null;
  // }

  // console.log({
  //   cognito,
  //   acl,
  // })
  return null;

  // return (
  //   <Page center>
  //     <Title bold upper fontSize={40}>Super User Page</Title>
  //     <Title fontSize={18}>Root users secret page.</Title>
  //     <Content>
  //       <VGap size={32} />
  //       <Accordion items={items} />
  //     </Content>
  //   </Page>
  // );
}