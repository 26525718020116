const toMs: Record<string, number> = {
  day: 86400000,
  hour: 3600000,
  minute: 60000,
  second: 1000,
};

const getWithPlural = (time: number, singular: string) =>
  `${time} ${singular}${time !== 1 ? 's' : ''}`;

export const getTimeUntilReveal = (revealTime: number) => {

  if (!revealTime) {
    return undefined;
  }

  let timeDiff = revealTime - Date.now();

  const timeUntil = Object.keys(toMs).reduce((res, cur) => {
    const miliseconds = toMs[cur];
    const value = Math.floor(timeDiff / miliseconds);
    timeDiff -= value * miliseconds;
    return {
      ...res,
      [cur]: value,
    };
  }, {} as typeof toMs);

  const time = `${getWithPlural(timeUntil.day, 'Day')}   ${getWithPlural(
    timeUntil.hour,
    'Hour',
  )}  ${getWithPlural(timeUntil.minute, 'Minute')}  ${getWithPlural(
    timeUntil.second,
    'Second',
  )}`;

  return time;
};