interface FormData {
  'saleId': string;
  'saleHeaderTitle': string;
  'saleHeaderSubTitle': string;
  'saleMedia.vimeoUrl'  : string;
  'saleName'  : string;
  'saleDescription'  : string;
  'starInfo'  : string;
  'followSocials': string;
  'writer.name': string;
  'writer.bio'  : string;
  bannerUrl: string;
  avatarUrl: string;
  startDate: string;
  endDate: string;
  featured: boolean;
}

const getDescription = (formData: FormData) => {
  return `<p>${formData.saleDescription}</p><h3>The Writer</h3><p>${formData["writer.bio"]}</p><h3>The Star</h3><p>${formData.starInfo}</p>`;
}

export const getFormToSaleData = (formData: FormData) => {
  return {
    [formData.saleId]: {
      "saleId": formData.saleId,
      "saleType": "collection",
      "featured": formData.featured,
      "tokenType": formData.saleId,
      "tokenNameSingular": formData.saleName,
      "tokenNamePlural": formData.saleName,
      "tokenShortNameSingular": formData.saleName,
      "tokenShortNamePlural": formData.saleName,
      "mbaasAddressLabel": "",
      "saleName": formData.saleName,
      "saleDescription": getDescription(formData),
      "saleHeaderTitle": formData.saleHeaderTitle,
      "saleHeaderSubTitle": formData.saleHeaderSubTitle,
      "saleMedia": {
        "vimeoUrl": formData['saleMedia.vimeoUrl'],
        "aspectRatio": "656/461",
        "twitterButtonTitle": "twitterButtonTitle",
        "twitterCopy": {
          "text": "twitterCopy.text",
          "title": "twitterCopy.title"
        }
      },
      "saleWeight": 2,
      "followSocials": formData.followSocials?.split(',').map(hashtag => ({
        "type": "twitter",
        "userId": hashtag.trim(),
        "required": true
      })),
      "marketingCampaign": formData.saleId,
      "mintedContractAddress": "",
      "mintedOpenseaLink": "",
      "pfpPlaceholderUrl": "",
      "crossLinks": [
      ],
      "mintCopy": {
        "upcoming": {
          "header": {
            "title": "mintCopy.upcoming.header.title",
            "subTitle": "mintCopy.upcoming.header.subTitle"
          },
          "contentTitle": "mintCopy.upcoming.contentTitle",
          "contentSubTitle": "mintCopy.upcoming.contentSubTitle"
        },
        "open": {
          "shareStepBox": {
            "title": "open.shareStepBox.title",
            "subTitle": "open.shareStepBox.subTitle",
            "btnLabel": "open.shareStepBox.btnLabel"
          },
          "public": {
            "header": {
              "title": "public.header.title",
              "subTitle": "public.header.subTitle"
            }
          },
          "winners": {
            "header": {
              "title": "winners.header.title",
              "subTitle": "winners.header.subTitle"
            },
            "stepBox": {
              "title": "winners.stepBox.title",
              "subTitle": "winners.stepBox.subTitle",
              "btnLabel": "winners.stepBox.btnLabel"
            }
          },
          "mintBox": {
            "title": formData.saleName
          }
        },
        "closed": {
          "header": {
            "title": "closed.header.title",
            "subTitle": "closed.header.subTitle"
          },
          "contentTitle": "closed.contentTitle",
          "contentSubTitle": "closed.contentSubTitle",
          "stepBox": {
            "title": "closed.stepBox.title",
            "subTitle": "closed.stepBox.subTitle",
            "btnLabel": "closed.stepBox.btnLabel"
          }
        }
      },
      "tokenContractAddress": "",
      "nftWalletAddress": "",
      "nftStoryId": "",
      "maxTokensPerUser": 500,
      "sendgridId": "",
      "defaultViewerUrl": "",
      "whitelist": {
        "signUp": {
          "startDate": formData.startDate,
          "endDate": formData.endDate,
        }
      },
      "listing": {
        "banner": formData.bannerUrl,
        "url": `/raffle/${formData.saleName?.replace(/ /g, '').toLocaleLowerCase()}`
      },
      "authorInfo": {
        "name": formData["writer.name"],
        "avatar": formData.avatarUrl,
      }
    },
  }
}

const _SaleForType = Object.values(getFormToSaleData('' as any))[0];

type SaleType = typeof _SaleForType;


/**
 * 
 * @param description 
 * @returns [description, writerBio, starInfo]
 */
const getDescriptionParts = (description: string = '') => {
  console.log('getDescriptionParts', {description});
  return description.split('<h3>').map(b => 
    b.substring(b.indexOf('3>')+1)
    .replace('<p>', '')
    .replace('</p>', '')
    .replace('>', '')
  );
}

export const getSaleToFormData = (sale: SaleType): FormData => {
  const [description, writerBio, starInfo] = getDescriptionParts(sale.saleDescription);

  const followSocials = sale.followSocials.map(f => f.userId).join(',');

  return {
    'saleId': sale.saleId,
    'saleHeaderTitle': sale.saleHeaderTitle,
    'saleHeaderSubTitle': sale.saleHeaderSubTitle,
    'saleMedia.vimeoUrl': sale.saleMedia.vimeoUrl,
    'saleName': sale.saleName,
    'saleDescription': description ?? '',
    'starInfo': starInfo ?? '',
    'followSocials': followSocials,
    'writer.name': sale.authorInfo?.name || '',
    'writer.bio': writerBio ?? '',
    'bannerUrl': sale.listing?.banner || '',
    'avatarUrl': sale.authorInfo?.avatar || '',
    'startDate': sale.whitelist.signUp.startDate,
    'endDate': sale.whitelist.signUp.endDate,
    'featured': sale.featured,
  }
}
