import { RootState } from "../../../state";

export const getTemplateAddress = ({ admin }: RootState) => admin.templateAddress;

export const getUserWalletAddress = ({ admin }: RootState) => admin.userWalletAddress;

export const getTemplateName = ({ admin }: RootState) => admin.templateName;

export const getStoryAddress = ({ admin }: RootState) => admin.storyAddress;

export const getStoryId = ({ admin }: RootState) => admin.storyId;

export const getRevealSchedule = ({ admin }: RootState) => admin.revealSchedule;