import { Col } from '../../../../../components/Layout';
import { IRevealSchedule } from '@storyverseco/svs-story-suite';
import { getTimeUntilReveal } from '../../../../../lib/time';

interface Props {
  schedule: IRevealSchedule;
}

export const Schedule = ({ schedule }: Props) => {
  return (
    <Col>
      <p>Timeline: {schedule.revealedTimelineId}</p>
      <p>Date: {new Date(schedule.timestamp).toLocaleString()}</p>
      <p>Reveal: {getTimeUntilReveal(schedule.timestamp)}</p>
    </Col>
  );
};
