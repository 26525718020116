import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Col, HGap, Row, VGap } from "../../../../components/Layout";
import { TextInput } from "../../../../components/TextInput";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useToast } from "../../../../components/Toast";
import { api } from "../../../api/PMToolApi";
import { Dropdown } from "../../../../components/Dropdown";
import { useSVSCognito } from "../../../hooks/useSVSCognito";
import { Main } from "../../../layout/Main";
import { assets } from "../../../../assets";
import { Title } from "../../../../components/Text";
import { getConfig } from "../../../../Config";
import { Modal } from "../../../../components/Modal";
import { useDispatch } from "react-redux";
import { appSlice } from "../../../state/appSlice";

/*
  export interface NFTStoryMetadata {
  walletAddress: string;
  storyId: number;
  createdAt: number;
  templateId: string;
  storyName: string;
}
*/

const Container = styled(Col)`

`;

const RowContainer = styled(Row)<{
  first: boolean;
}>`
  margin-bottom: 8px;
  max-height: 80px;
  min-height: ${({first}) => first ? 100 : 80}px;
`;

const InputsContainer = styled(Row)`
  margin-bottom: 12px;
`;

const IconsContainer = styled(Row)`
`;

const Icon = styled.img`
  cursor: pointer;
  display: flex;
  width: 40px;
  height: 40px;
`;

const ModalContent = styled(Col)``;



const getRows = (data: any[], onCreateTemplate: (creatorUrl: string) => void) => {
  return data.map(({storyName, walletAddress, storyId, createdAt}, i) => {
    const showLabel = i === 0 || undefined;
    return [
      <RowContainer key={walletAddress+i} first={i === 0}>
        <Row flex={.6}>
          <TextInput 
            disabled
            label={showLabel && 'Story Name'}
            value={storyName}
            onChange={() => {}}
          />
        </Row>
        <HGap size={8} />
        <TextInput 
          disabled
          label={showLabel && 'Author Wallet Address'}
          value={walletAddress}
          onChange={() => {}}
        />
        <HGap size={8} />
        <Row flex={.3}>
          <TextInput 
            disabled
            label={showLabel && 'Story Id'}
            value={storyId}
            onChange={() => {}}
          />
        </Row>
        <HGap size={8} />
        <Row flex={.6}>
          <TextInput 
            disabled
            label={showLabel && 'Created At'}
            value={new Date(createdAt).toLocaleString()}
            onChange={() => {}}
          />
        </Row>
        <HGap size={8} />
        <Col centerH flex={.5}>
          {showLabel && <Title bold>Story links</Title>}
          <IconsContainer center clear>
            <OverlayTrigger overlay={<Tooltip id={'viewer'}>Story Viewer</Tooltip>}>
              <Icon src={assets.view} onClick={async() => {
                const config = await getConfig();
                const url = `${config.globals.urls.viewer}/${walletAddress}/${storyId}/n`;
                window.open(url, '_blank');
              }} />
            </OverlayTrigger>
            <HGap size={12} line />
            <OverlayTrigger overlay={<Tooltip id={'creator'}>Story Creator</Tooltip>}>
              <Icon src={assets.edit} onClick={async() => {
                const config = await getConfig();
                const url = `${config.globals.urls.creator}/nft/${walletAddress}/${storyId}`;
                window.open(url, '_blank');
              }} />
            </OverlayTrigger>
          </IconsContainer>
        </Col>
        <Col clear>
          {showLabel && <Title bold>Action</Title>}
          <OverlayTrigger overlay={<Tooltip id={'creator'}>Create template story</Tooltip>}>
            <Icon src={assets.transfer} onClick={async() => {
              const config = await getConfig();
              const url = `${config.globals.urls.creator}/nft/${walletAddress}/${storyId}`;
              console.log(url);
              onCreateTemplate(url);
            }} />
          </OverlayTrigger>
        </Col>
      </RowContainer>,
      <hr />
    ]
  })
}

const defaultEmptyUser = {
  name: '',
  walletAddress: '',
}

export const ViewStories = () => {

  const dispatch = useDispatch();

  const [stories, setStories] = useState<any[]>();

  const [search, setSearch] = useState('');

  const [filter, setFilter] = useState('');
  
  const [users, setUsers] = useState([defaultEmptyUser]);

  const [showModal, setShowModal] = useState(false);

  const [templateId, setTemplateId] = useState('');

  const [templateUrl, setTemplateUrl] = useState('');

  const [creatorUrlForTemplate, setCreatorUrlForTemplate] = useState<string>();

  const { success, error } = useToast();

  const { getUser } = useSVSCognito();

  useEffect(() => {
    const fetchStories = async() => {
      try {
        const nftStories = await api.getNFTStories();
        console.log({nftStories});
        setStories(Object.values(nftStories));
        success(`NFT stories data fetched!`);
      } catch(e) {
        error(`Error: Failed to fetch NFT stories.`);
      }
    }

    if (!stories) {
      fetchStories();
    }
  }, []);

  useEffect(() => {
    if (stories) {
      const newUsers = stories.map(s => {
        const user = getUser({walletAddress: s.walletAddress});
        if (!user) {
          return undefined;
        }
        return {
          name: user.name,
          walletAddress: s.walletAddress,
        }
      }).filter(Boolean) as typeof users;
      setUsers([defaultEmptyUser, ...new Set(newUsers)]);
    }
  }, [stories]);

  const defStories = stories || [];

  const filteredStories = useMemo(() => {
    if (filter === '' || users.length === 1) {
      return defStories;
    }

    const user = users.find(u => u.name === filter);
    
    return defStories.filter(s => s.walletAddress === user?.walletAddress);
  }, [filter, defStories, users]);

  const finalStories = useMemo(() => {
    return filteredStories.filter(s => s.storyName.toLowerCase().includes(search.toLowerCase()));
  }, [filteredStories, search]);

  const closeModal = useCallback(() => {
    setShowModal(false);
    setTemplateUrl('');
    setTemplateId('');
  }, []);

  const onCreateTemplateStory = async() => {
    if (!creatorUrlForTemplate || !templateId) {
      return;
    }
    dispatch(appSlice.actions.suspend());
    try {
      const templateUrl = await api.nftStoryToTemplate({
        nftStoryCreatorUrl: creatorUrlForTemplate,
        storyId: templateId,
      });
      setTemplateUrl(templateUrl);
      success(`Template story created successfully!`);
    } catch(e:any) {
      error(e.message);
    }

    dispatch(appSlice.actions.resume());
  }

  const onCreateTemplateStoryClick = (url: string) => {
    console.log('????');
    setCreatorUrlForTemplate(url);
    setShowModal(true);
  }
  
  const rows = getRows(finalStories, onCreateTemplateStoryClick);

  return (
    <Main
      title='Collectible Stories'
      subtitle='See all collectible stories created'
    >
      <InputsContainer clear>
        <Row></Row>
        <Row>
          <Dropdown 
            items={users.map(u => u.name)}
            title='Filter'
            value={filter}
            onChange={setFilter}
          />
          <HGap size={8} />
          <TextInput 
            label='Search'
            value={search}
            onChange={setSearch}
          />
        </Row>
      </InputsContainer>
      {rows}
      <Modal
        title={'Story ID Input'}
        show={showModal}
        onClose={closeModal}
        submitBtn={templateUrl ? undefined : {
          onPress: onCreateTemplateStory,
          text: 'Create Template Story',
          variant: 'primary',
        }}
        cancelBtn={{
          onPress: closeModal,
          text: 'Close',
          variant: 'info',
        }}
      >
        <ModalContent>
          <TextInput value={templateUrl || templateId} onChange={setTemplateId} disabled={Boolean(templateUrl)} />
        </ModalContent>
      </Modal>
    </Main>
  );
}