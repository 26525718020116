import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, HGap, Row, VGap } from '../../../../../components/Layout';
import { colors } from '../../../../../lib/styles';
import { TextInput } from '../../../../../components/TextInput';
import { IRevealSchedule, TimelineId } from '@storyverseco/svs-story-suite';
import DateTimePicker from 'react-datetime-picker';
import './Sample.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { Title } from '../../../../../components/Text';

const Container = styled(Col)`
  width: 100%;
  min-height: 100%;
  border-radius: 8px;
  border: 1px solid ${colors.grey};
  position: relative;
`;

const TimelineIdContainer = styled(Row)`
  width: 100%;
  padding: 20px;
`;

const BtnsContainer = styled(Row)`
  align-items: center;
  justify-content: space-evenly;
  padding: 12px 0px;
`;

const Btn = styled(Button)`
  font-weight: bold;
  color: white;
  text-transform: uppercase;
`;

const getDefaultDate = () => {
  var d = new Date();
  d.setHours(0, 0, 0, 0);
  return d;
};

const MIN_MS = 60000;

interface Props {
  schedule?: IRevealSchedule;
  showBtns?: boolean;
  onSubmit: (timelineId: TimelineId, timestamp: number) => void;
  onClear: () => void;
  onDelete: (timelineId: TimelineId) => void;
}

export const NewSchedule = ({ schedule, onSubmit, onClear, onDelete, showBtns = true }: Props) => {
  const [timelineId, setTimelineId] = useState('');

  const [timestamp, setTimestamp] = useState(getDefaultDate());

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (schedule) {
      setTimelineId(schedule.revealedTimelineId);
      setTimestamp(new Date(schedule.timestamp));
      setIsEdit(true);
    } else {
      setTimelineId('');
      setTimestamp(getDefaultDate());
      setIsEdit(false);
    }
  }, [schedule]);

  const onTimestampChange = (value: Date | null) => {
    if (value) {
      setTimestamp(value);
    } else {
      setTimestamp(getDefaultDate());
    }
  };

  const onClick = () => {
    const timestampMs = timestamp.getTime();
    onSubmit(timelineId as TimelineId, timestampMs);
  };

  const onDeleteClick = () => {
    onDelete(timelineId as TimelineId);
  }

  const disableSubmit = () => {
    const regexMatch = timelineId.match(/^t[0-9]+/);
    if (!regexMatch) {
      return true;
    }

    // Check if it's at least a minute in the future
    if (timestamp.getTime() - Date.now() < MIN_MS) {
      return true;
    }

    return false;
  };
  

  return (
    <Container>
      <TimelineIdContainer clear>
        <TextInput
          label='Timeline Id'
          value={timelineId}
          onChange={setTimelineId}
        />
      </TimelineIdContainer>
      <div className='Sample'>
        <Title center bold>
          Schedule
        </Title>
        <div className='Sample__container'>
          <main className='Sample__container__content'>
            <DateTimePicker
              amPmAriaLabel='Select AM/PM'
              calendarAriaLabel='Toggle calendar'
              clearAriaLabel='Clear value'
              dayAriaLabel='Day'
              hourAriaLabel='Hour'
              maxDetail='second'
              minuteAriaLabel='Minute'
              monthAriaLabel='Month'
              nativeInputAriaLabel='Date and time'
              secondAriaLabel='Second'
              yearAriaLabel='Year'
              onChange={onTimestampChange}
              value={timestamp}
            />
          </main>
        </div>
      </div>
      {
        showBtns && (
          <BtnsContainer clear>
            <Btn onClick={onDeleteClick} variant='danger'>
              Delete
            </Btn>
            <Btn onClick={onClear} variant='warning'>
              Clear form
            </Btn>
            <Btn disabled={disableSubmit()} onClick={onClick} variant='success'>
              Save
            </Btn>
          </BtnsContainer>
        )
      }
    </Container>
  );
};
