import { nftProjects } from '@storyverseco/svs-consts';

// Whenever we allow more projects than not, make it into blacklist instead
const whitelist = [
  '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d', // apes
  '0x60e4d786628fea6478f785a6d7e704777c86a7c6', // mutantat apes
  '0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb', // cryptopunks
  '0xbd3531da5cf5857e7cfaa92426877b022e612cf8', //penguins
  '0x8a90cab2b38dba80c64b7734e58ee1db38b8992e', // doodles
];

export const assemblerProjects: Record<string, string> = nftProjects.reduce(
  (res, cur) => {
    const address = cur.address.toLocaleLowerCase();
    if (whitelist.includes(address)) {
      return {
        ...res,
        [cur.name]: address,
      };
    }
    return res;
  },
  {}
);

export const projectNames = Object.keys(assemblerProjects);

export const dropdownDefaultValue = projectNames[0];
